import React, { useState,useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Select from "react-select";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

function Edit_Bill() {
    const ID = Cookies.get("user_id");
    const navigate = useNavigate();
    const { billId } = useParams();

    
    function checkTax(PlaceOfSupply) {
        if (PlaceOfSupply == "State") {
          document.querySelectorAll(".tax_ref").forEach(function (ele) {
            ele.style.display = "none";
          });
          document.querySelectorAll(".tax-gst").forEach(function (ele) {
            ele.style.display = "block";
          });
          document.getElementById("cgst_val").style.display = "flex";
          document.getElementById("sgst_val").style.display = "flex";
          document.getElementById("igst_val").style.display = "none";
        } else {
          document.querySelectorAll(".tax_ref").forEach(function (ele) {
            ele.style.display = "none";
          });
          document.querySelectorAll(".tax-igst").forEach(function (ele) {
            ele.style.display = "block";
          });
          document.getElementById("cgst_val").style.display = "none";
          document.getElementById("sgst_val").style.display = "none";
          document.getElementById("igst_val").style.display = "flex";
        }
      }
    
      const fetchBillDetails = () => {
        axios
          .get(`${config.base_url}/fetch_PurchaseBillDetails/${billId}/`)
          .then((res) => {
            if (res.data.status) {
              var bill = res.data.bill;
              var itms = res.data.items;

              if (bill.party != "" && bill.party != null) {
                setParty(true);
              }

              var c = {
                value: bill.party,
                label: res.data.otherDetails.PartyName,
              };
              setPartyValue(c);

              setParty(bill.party);
              setPtyPhone(bill.Party_phone);
              setPtyBillingAddress(bill.Party_address);
    
              setDate(bill.billdate);
              setBillNo(bill.billno);
              setPlaceOfSupply(bill.supplyplace);

              setSubTotal(bill.subtotal);
              setIgst(bill.igst);
              setCgst(bill.cgst);
              setSgst(bill.sgst);
              setTaxAmount(bill.taxamount);
              setAdjustment(bill.adjust);
              setGrandTotal(bill.grandtotal);
              
              setSalesItems([]);
              const blItems = itms.map((i) => {
                if (i.item_type == "Goods") {
                  var hsn = i.hsn;
                } else {
                  var hsn = i.sac;
                }
                return {
                  id: 1,
                  item: i.itemId,
                  hsn: hsn,
                  quantity: i.quantity,
                  available: i.avl,
                  price: i.Purchase_price,
                  taxGst: i.item_gst,
                  taxIgst: i.item_igst,
                  discount: i.discount,
                  total: i.total,
                  taxAmount: "",
                };
              });
    
              setSalesItems(blItems);
              refreshIndexes(blItems);
    
              // if(bill.payment_method != "null"){
              //   paymentMethodChange(bill.payment_method);
              // }
              // checkTax(res.data.otherDetails.State, bill.vendor_source_of_supply);
              // checkPL(bill.price_list_applied);
              // applyPriceList(sales.price_list)
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      };
    
      useEffect(() => {
        fetchBillDetails();
      }, []);


      var currentDate = new Date();
      var formattedDate = currentDate.toISOString().slice(0, 10);
    
      const [items, setItems] = useState([]);
      const [parties, setParties] = useState([]);
    
      const [party, setParty] = useState("");
      const [ptyphone, setPtyPhone] = useState("");
      const [ptyBillingAddress, setPtyBillingAddress] = useState("");
      const [billNo, setBillNo] = useState("");
      const [date, setDate] = useState(formattedDate);
      const [PlaceOfSupply, setPlaceOfSupply] = useState("State");
    
      const [subTotal, setSubTotal] = useState(0.0);
      const [igst, setIgst] = useState(0.0);
      const [cgst, setCgst] = useState(0.0);
      const [sgst, setSgst] = useState(0.0);
      const [taxAmount, setTaxAmount] = useState(0.0);
      const [adjustment, setAdjustment] = useState(0.0);
      const [grandTotal, setGrandTotal] = useState(0.0);

      const [partyValue, setPartyValue] = useState({});
    
      const fetchBillData = () => {
        axios
          .get(`${config.base_url}/fetch_purchasebill_data/${ID}/`)
          .then((res) => {
            console.log("RL Data==", res);
            if (res.data.status) {
              let itms = res.data.items;
              let pty = res.data.parties;
    
              setItems([]);
              const newOptions = itms.map((item) => ({
                label: item.item_name,
                value: item.id,
              }));
              setItems(newOptions);
    
              setParties([]);
              console.log(setParties)
              const newPtyOptions = pty.map((item) => ({
                label: item.party_name,
                value: item.id,
              }));
              setParties(newPtyOptions);
            }
          })
          .catch((err) => {
          console.log(err);
          });
      };
    
      useEffect(() => {
        fetchBillData();
      }, []);
    

      const handlePartyChange = (value) => {
        setParty(value);
        getPartyData(value);
      };
    
      function getPartyData(party) {
        var cst = {
          Id: ID,
          p_id: party,
        };
    
        if (party != "") {
          axios
            .get(`${config.base_url}/get_billparty_data/`, { params: cst })
            .then((res) => {
              if (res.data.status) {
                setPtyPhone("");
                setPtyBillingAddress("");
                var pty = res.data.partyDetails;
                setPtyPhone(pty.phone);
                setPtyBillingAddress(pty.address);
              }
            })
            .catch((err) => {
              console.log("ERROR", err);
            });
        } else {
            setPtyPhone("");
            setPtyBillingAddress("");
        }
      }
      
    
      const [salesItems, setSalesItems] = useState([]);
    
      const addNewRow = () => {
        var newItem = {
          id: "",
          item: "",
          hsn: "",
          quantity: "",
          price: "",
          taxGst: "",
          taxIgst: "",
          discount: "",
          total: "",
          taxAmount: "",
        };
        setSalesItems((prevItems) => {
          const updatedItems = [...prevItems, newItem];
    
          return updatedItems.map((item, index) => ({
            ...item,
            id: index + 1,
          }));
        });
      };
    
      const removeRow = (id) => {
        setSalesItems((prevItems) => {
          const updatedItems = prevItems.filter((item) => item.id !== id);
    
          calc3(updatedItems);
          return updatedItems.map((item, index) => ({
            ...item,
            id: index + 1,
          }));
        });
      };
    
      const handleSalesItemsInputChange = (id, field, value) => {
        setSalesItems((prevItems) =>
          prevItems.map((item) =>
            item.id === id ? { ...item, [field]: value } : item
          )
        );
      };
    
      const handleItemChange = (value, id) => {
        var exists = itemExists(value);
        if (!exists) {
          if (PlaceOfSupply != "") {
            handleSalesItemsInputChange(id, "item", value);
            getItemData(value, id);
          } else {
            alert("Select Place of Supply.!");
          }
        } else {
          alert("Item already exists, choose another or change quantity.!");
        }
      };
    
    
      function getItemData(item, id) {
        var exists = itemExists(item);
        var plc = PlaceOfSupply;
    
        if (!exists) {
          if (plc != "") {
            var itm = {
              Id: ID,
              item: item,
            };
    
            axios
              .get(`${config.base_url}/get_billtable_item_data/`, { params: itm })
              .then((res) => {
                if (res.data.status) {
                  var itemData = res.data.itemData;
    
                  setSalesItems((prevItems) =>
                    prevItems.map((item) =>
                      item.id === id
                        ? {
                            ...item,
                            price: itemData.purchase_rate,
                            taxGst: itemData.gst,
                            taxIgst: itemData.igst,
                            hsn: itemData.hsnSac,
                            available: itemData.avl,
                          }
                        : item
                    )
                  );
                }
              })
              .catch((err) => {
                console.log("ERROR", err);
              });
          } else {
            alert("Select State of Supply.!");
          }
        } else {
          alert("Item already exists, choose another or change quantity.!");
        }
      }
    
      useEffect(() => {
        document.getElementById("igst_val").style.display = "none";
      }, []);
    
    
      function handlePlaceOfSupply(val) {
        setPlaceOfSupply(val);
        refreshTax(val);
      }
    
      const itemExists = (itemToCheck) => {
        for (const item of salesItems) {
          if (item.item === itemToCheck) {
            return true;
          }
        }
        return false;
      };
    
    
      function refreshTax(PlaceOfSupply) {
        if (PlaceOfSupply == "State") {
          document.querySelectorAll(".tax_ref").forEach(function (ele) {
            ele.style.display = "none";
          });
          document.querySelectorAll(".tax-gst").forEach(function (ele) {
            ele.style.display = "block";
          });
          document.getElementById("cgst_val").style.display = "flex";
          document.getElementById("sgst_val").style.display = "flex";
          document.getElementById("igst_val").style.display = "none";
        } else {
          document.querySelectorAll(".tax_ref").forEach(function (ele) {
            ele.style.display = "none";
          });
          document.querySelectorAll(".tax-igst").forEach(function (ele) {
            ele.style.display = "block";
          });
          document.getElementById("cgst_val").style.display = "none";
          document.getElementById("sgst_val").style.display = "none";
          document.getElementById("igst_val").style.display = "flex";
        }
        calc2(PlaceOfSupply);
      }
    
      function refreshTax2() {
        if (PlaceOfSupply == "State") {
          document.querySelectorAll(".tax_ref").forEach(function (ele) {
            ele.style.display = "none";
          });
          document.querySelectorAll(".tax-gst").forEach(function (ele) {
            ele.style.display = "block";
          });
          document.getElementById("cgst_val").style.display = "flex";
          document.getElementById("sgst_val").style.display = "flex";
          document.getElementById("igst_val").style.display = "none";
        } else {
          document.querySelectorAll(".tax_ref").forEach(function (ele) {
            ele.style.display = "none";
          });
          document.querySelectorAll(".tax-igst").forEach(function (ele) {
            ele.style.display = "block";
          });
          document.getElementById("cgst_val").style.display = "none";
          document.getElementById("sgst_val").style.display = "none";
          document.getElementById("igst_val").style.display = "flex";
        }
      }
    
      function refreshValues() {
        refreshTax2();
        calc();
      }
    
      function extractTaxRate(label) {
        if (label) {
          var match = label.match(/\[(\d+)%\]/);
          if (match && match[1]) {
            return parseFloat(match[1]);
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
    
    
      const calc3 = (salesItems) => {
        const updatedItems = salesItems.map((item) => {
          let qty = parseInt(item.quantity || 0);
          let price = parseFloat(item.price || 0);
          let dis = parseFloat(item.discount || 0);
    
          let tax =
            PlaceOfSupply === "State"
              ? extractTaxRate(item.taxGst || 0)
              : extractTaxRate(item.taxIgst || 0);
    
          let total = parseFloat(qty) * parseFloat(price) - parseFloat(dis);
          let taxAmt = (qty * price - dis) * (tax / 100);
    
          return {
            ...item,
            total: total.toFixed(2),
            taxAmount: taxAmt.toFixed(2),
          };
        });
    
        setSalesItems(updatedItems);
    
        calc_total(updatedItems);
      };
    
      function calc2(PlaceOfSupply) {
        const updatedItems = salesItems.map((item) => {
          var qty = parseInt(item.quantity || 0);
          var price = parseFloat(item.price || 0);
          var dis = parseFloat(item.discount || 0);
    
          if (PlaceOfSupply == "State") {
            var tax = extractTaxRate(item.taxGst || 0);
          } else {
            var tax = extractTaxRate(item.taxIgst || 0);
          }
          let total = parseFloat(qty) * parseFloat(price) - parseFloat(dis);
          let taxAmt = (qty * price - dis) * (tax / 100);
          return {
            ...item,
            total: total.toFixed(2),
            taxAmount: taxAmt.toFixed(2),
          };
        });
    
        setSalesItems(updatedItems);
        refreshIndexes(updatedItems);
        calc_total2(updatedItems, PlaceOfSupply);
      }
    
    
      const calc = () => {
        const updatedItems = salesItems.map((item) => {
          var qty = parseInt(item.quantity || 0);
          var price = parseFloat(item.price || 0);
          var dis = parseFloat(item.discount || 0);
    
          if (PlaceOfSupply == "State") {
            var tax = extractTaxRate(item.taxGst || 0);
          } else {
            var tax = extractTaxRate(item.taxIgst || 0);
          }
          let total = parseFloat(qty) * parseFloat(price) - parseFloat(dis);
          let taxAmt = (qty * price - dis) * (tax / 100);
          return {
            ...item,
            total: total.toFixed(2),
            taxAmount: taxAmt.toFixed(2),
          };
        });
    
        setSalesItems(updatedItems);
        refreshIndexes(updatedItems);
        calc_total(updatedItems);
      };
    
    
      function calc_total(salesItems) {
        var total = 0;
        var taxamount = 0;
        salesItems.map((item) => {
          total += parseFloat(item.total || 0);
        });
        salesItems.map((item) => {
          taxamount += parseFloat(item.taxAmount || 0);
        });
        setSubTotal(total.toFixed(2));
        setTaxAmount(taxamount.toFixed(2));
    
        var adj_val = parseFloat(adjustment || 0);
        var gtot = taxamount + total + adj_val;
    
        setGrandTotal(gtot.toFixed(2));
    
        splitTax(taxamount, PlaceOfSupply);
      }
    
      function splitTax(taxamount, PlaceOfSupply) {
        var d = 0;
        if (PlaceOfSupply == "State") {
          var gst = taxamount / 2;
          setCgst(parseFloat(gst.toFixed(2)));
          setSgst(parseFloat(gst.toFixed(2)));
          setIgst(parseFloat(d.toFixed(2)));
        } else {
          setIgst(taxamount.toFixed(2));
          setCgst(d.toFixed(2));
          setSgst(d.toFixed(2));
        }
      }
    
      function calc_total2(salesItems, PlaceOfSupply) {
        var total = 0;
        var taxamount = 0;
        salesItems.map((item) => {
          total += parseFloat(item.total || 0);
        });
        salesItems.map((item) => {
          taxamount += parseFloat(item.taxAmount || 0);
        });
        setSubTotal(total.toFixed(2));
        setTaxAmount(taxamount.toFixed(2));
    
        var adj_val = parseFloat(adjustment || 0);
        var gtot = taxamount + total + adj_val;
    
        setGrandTotal(gtot.toFixed(2));
        splitTax2(taxamount, PlaceOfSupply);
      }
    
      function splitTax2(taxamount, PlaceOfSupply) {
        var d = 0;
        if (PlaceOfSupply == "State") {
          var gst = taxamount / 2;
          setCgst(parseFloat(gst.toFixed(2)));
          setSgst(parseFloat(gst.toFixed(2)));
          setIgst(parseFloat(d.toFixed(2)));
        } else {
          setIgst(taxamount.toFixed(2));
          setCgst(d.toFixed(2));
          setSgst(d.toFixed(2));
        }
      }
    
      function handleAdjustment(val) {
        setAdjustment(val);
        updateGrandTotalAdj(val);
      }
    
      function updateGrandTotalAdj(val) {
        var subtot = subTotal;
        var tax = taxAmount;
        var adj = val;
        var gtot = (
          parseFloat(subtot || 0) +
          parseFloat(tax || 0) +
          parseFloat(adj || 0)
        ).toFixed(2);
        setGrandTotal(gtot);
      }
    
      function refreshIndexes(items) {
        const itms = items.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
    
        setSalesItems(itms);
      }


      function checkForZero(val) {
        return val !== "" ? val : 0.0;
      }
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append("Id", ID);
        formData.append("billId", billId);
        if (party) {
          formData.append("party", party);
        } else {
          formData.append("party", ''); 
        }
        formData.append("Party_phone", ptyphone);
        formData.append("Party_address", ptyBillingAddress);
        formData.append("supplyplace", PlaceOfSupply);
        formData.append("billno", billNo);
        formData.append("billdate", date);
        formData.append("subtotal", checkForZero(subTotal));
        formData.append("igst", checkForZero(igst));
        formData.append("cgst", checkForZero(cgst));
        formData.append("sgst", checkForZero(sgst));
        formData.append("taxamount", checkForZero(taxAmount));
        formData.append("adjust", checkForZero(adjustment));
        formData.append("grandtotal", checkForZero(grandTotal));
        formData.append("salesItems", JSON.stringify(salesItems));
    
        axios
          .put(`${config.base_url}/update_purchasebill/`, formData)
          .then((res) => {
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Bill Updated",
              });
              navigate("/bill");
            }
            if (!res.data.status && res.data.message != "") {
              Swal.fire({
                icon: "error",
                title: `${res.data.message}`,
              });
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      };


      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      
     
      
  //  Unit and Item
  const [units, setUnits] = useState([]);

  const fetchItemUnits = () => {
    axios
      .get(`${config.base_url}/bill_get_company_item_units/${ID}/`)
      .then((res) => {
        console.log("UNITS==", res);
        if (res.data.status) {
          let unt = res.data.units;
          setUnits([]);
          unt.map((i) => {
            let obj = {
              name: i.unit_name,
            };
            setUnits((prevState) => [...prevState, obj]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchItemUnits();
  }, []);


  const [newUnit, setNewUnit] = useState("");
  function handleUnitModalSubmit(e) {
    e.preventDefault();
    var name = newUnit;
    if (name != "") {
      var u = {
        Id: ID,
        unit_name: newUnit,
      };
      axios
        .post(`${config.base_url}/bill_create_new_unit/`, u)
        .then((res) => {
          console.log("UNIT RES=", res);
          if (res.data.status) {
            Toast.fire({
              icon: "success",
              title: "Unit Created",
            });
            fetchItemUnits();
            setUnit(u.name);
            setNewUnit("");
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    } else {
      alert("Invalid");
    }
  }

  function validateHSN() {
    var hsnField = document.getElementById("hsnField");
    var errorText = document.getElementById("hsnError");
    var hsnValue = hsnField.value;

    if (hsnValue.length < 6) {
      errorText.innerText = "HSN must contain at least 6 digits";
      hsnField.setCustomValidity("HSN must contain at least 6 digits");
      hsnField.style.borderColor = "red";
    } else {
      errorText.innerText = "";
      hsnField.setCustomValidity("");
      hsnField.style.borderColor = "";
    }
  }

  function validateSAC() {
    var sacField = document.getElementById("sacField");
    var errorText = document.getElementById("sacError");
    var sacValue = sacField.value;

    if (sacValue.length < 6) {
      errorText.innerText = "SAC must contain at least 6 digits";
      sacField.setCustomValidity("SAC must contain at least 6 digits");
      sacField.style.borderColor = "red";
    } else {
      errorText.innerText = "";
      sacField.setCustomValidity("");
      sacField.style.borderColor = "";
    }
  }

  function showdiv() {
    document.getElementById("taxableDiv").style.display = "flex";
  }

  function hidediv() {
    document.getElementById("taxableDiv").style.display = "none";
  }

  function itemTypeChange() {
    var value = document.getElementById("itemType").value;
    var sacField = document.getElementById("sacField");
    var hsnField = document.getElementById("hsnField");
    var hsnDiv = document.getElementById("hsnDiv");
    var sacDiv = document.getElementById("sacDiv");
    var sacError = document.getElementById("sacError");
    var hsnError = document.getElementById("hsnError");
    if (value === "Goods") {
      sacField.value = "";
      hsnField.required = true;
      sacField.required = false;
      hsnDiv.style.display = "block";
      sacDiv.style.display = "none";
      sacError.textContent = "";
      sacField.style.borderColor = "white";
    } else {
      hsnField.value = "";
      hsnField.required = false;
      sacField.required = true;
      sacDiv.style.display = "block";
      hsnDiv.style.display = "none";
      hsnError.textContent = "";
      hsnField.style.borderColor = "white";
    }
  }

  function fetchItems() {
    axios
      .get(`${config.base_url}/fetch_purchasebill_data/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          let items = res.data.items;
          setItems([]);
          const newOptions = items.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          setItems(newOptions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  useEffect(() => {
    fetchBillData();
  }, []);


  const [priceTempVisible, setPriceTempVisible] = useState(true);
  const toggleSection1 = () => {
    setPriceTempVisible((prev) => !prev);
  };

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [unit, setUnit] = useState("");
  const [hsn, setHsn] = useState("");
  const [sac, setSac] = useState("");
  const [taxRef, setTaxRef] = useState("");
  const [interStateTax, setInterStateTax] = useState("");
  const [intraStateTax, setIntraStateTax] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [salesPrice, setSalesPrice] = useState(0);

  const [openigstock, setOpeningStock] = useState(0);
  const [currentstock, setCurrentStock] = useState(0);
  const [stockhand, setStockHand] = useState(0);
  const [stockUnitRate, setStockUnitRate] = useState(0);
  const [minStock, setMinStock] = useState(0);

  const handleItemModalSubmit = (e) => {
    e.preventDefault();

    var dt = {
      Id: ID,
      item_name: name,
      item_type: type,
      item_unit: unit,
      item_hsn: hsn,
      item_sac: sac,
      item_taxable: taxRef,
      item_gst: intraStateTax,
      item_igst: interStateTax,
      item_sale_price: salesPrice,
      item_purchase_price: purchasePrice,

      item_opening_stock:openigstock,
      item_current_stock:currentstock,
      item_stock_in_hand:stockhand,
      item_at_price:stockUnitRate,
      item_min_stock_maintain:minStock  
    }

    axios
      .post(`${config.base_url}/bill_create_new_item/`, dt)
      .then((res) => {
        console.log("ITM RES=", res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Item Created",
          });
          fetchItems();
        }
        if (!res.data.status && res.data.message != "") {
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  
   


  
  // Party
  const [gstTempVisible, setGstTempVisible] = useState(true);

  const toggleSection = () => {
    setGstTempVisible((prev) => !prev);
  };

  function checkgst(val) {
    var gstinput = val;
    var gstregexp =
      "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}";

    if (gstinput.length === 15) {
      if (gstinput.match(gstregexp)) {
        document.getElementById("warngst").innerHTML = "";
        // checkCustomerGSTIN(val);
      } else {
        document.getElementById("warngst").innerHTML =
          "Please provide a valid GST Number";
        alert("Please provide a valid GST Number");
      }
    } else {
      document.getElementById("warngst").innerHTML =
        "Please provide a valid GST Number";
      alert("Please provide a valid GST Number");
    }
  }


  function checkphone(val) {
    var phoneinput = val;
    var phoneregexp = /^\d{10}$/;
    if (val != "") {
      if (phoneinput.match(phoneregexp)) {
        document.getElementById("warnphone").innerHTML = "";
        // checkCustomerPhone(val);
      } else {
        document.getElementById("warnphone").innerHTML =
          "Please provide a valid Phone Number";
        alert("Please provide a valid Phone Number");
      }
    }
  }


  const [partyName, setPartyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gstType, setGstType] = useState("");
  const [gstin, setGstin] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [state, setState] = useState("");
  const [partyemail, setPartyEmail] = useState("");
  const [partyopeningBalance, setPartyOpeningBalance] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  const [topay, setToPay] = useState("");
  const [torecieve, setToRecieve] = useState("");

  const handleNewPartyModalSubmit = (e) => {
    e.preventDefault();

    var dt = {
      Id: ID,
      party_name:partyName,
      phone_number:phoneNumber,
      gst_type: gstType,
      gstin: gstin,
      billing_address:billingAddress,
      state:state,
      email:partyemail,
      opening_balance:partyopeningBalance,
      credit_limit:creditLimit,
      to_pay:topay,
      to_recieve:torecieve,

    };

    axios
      .post(`${config.base_url}/bill_new_party/`, dt)
      .then((res) => {
        console.log("CUST RES=", res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Party Created",
          });
          fetchBillData();
        }
        if (!res.data.status && res.data.message != "") {
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };


  return (
    <>
      <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
      <Sidebar />
      <div className="main-panel px-3 py-2" style={{ background: "rgb(130, 144, 199)" }}> 
        <div className="content-wrapper">
          <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>


          <style>
        {`
          .purchase-div {
            background: linear-gradient(
                #3d4465 0%,
                #3d4465 80px,
                white 80px,
                white 100%
            );
            }
    

        .button-hover {
        background-color: white;
        color: #3d4465;
        }
        .button-hover:hover {
            background-color: #4d5fbc;
            color: white;
        }
     

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        input:checked + .slider {
            background-color: #2196f3;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #2196f3;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }
        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }
        #customer_div {
            margin-top: 2%;
            margin-left: 0.1px;
            margin-right: 0.51px;
        }
        @media (min-width: 600px) and (max-width: 1000px) {
            #customer_div {
            margin-top: 15%;
            }
        }

        @media (min-width: 300px) and (max-width: 800px) {
            #customer_div {
            margin-top: 15%;
            }
        }
    
        `}
      </style>



      <div className="col-md-12 p-0 m-0" style={{ width: "100%", position: "relative" }}>
      <div className="page-content">
        <div className="card radius-15">
          <div className="card-body purchase-div">
            <div className="row">
              <div className="col" style={{ height: "fit-content", width: "100%" }}>
                <h3 className="mb-3 text-white" style={{ whiteSpace: "nowrap" }}>EDIT PURCHASE BILL</h3>
                <br></br>
              </div>
              <div className="col-md-6" id="cg"></div>
              <div className="col-md" style={{ width: "fit-content" }} id="backbutton">
                <a
                  href="javascript:window.history.back();"
                  style={{ textAlign: "right" }}
                  className="btn button-hover"
                >
                  <i className="fa fa-arrow-left"></i> Back
                </a>
              </div>
            </div>

            <div id="customer_div">
            <form
                className="needs-validation"
                onSubmit={handleSubmit}
                id="salesForm"
              >
            <div className="row"
                style={{ display: party ? "flex" : "none" }}
            >
                <div className="col-md-4 custom-dropdown">
                <label1 className="col-form-label" style={{ marginBottom: "1vh", marginLeft: "2vh"  }}>Party</label1>
                <div className="d-flex">
                    <Select
                        options={parties}
                        name="party"
                        className="w-100"
                        id="party"
                        value={parties.find((option) => option.value === party) || null}
                        onChange={(selectedOption) =>
                            handlePartyChange(
                            selectedOption ? selectedOption.value : ""
                          )
                        }
                        isClearable
                        isSearchable
                      />
                    &nbsp;&nbsp;&nbsp;
                    <button
                    id="modal_clclosing"
                    type="button"
                    className="btn"
                    style={{
                        backgroundColor: "rgb(61, 68, 101)",
                        width: "fit-content",
                        height: "35px",
                    }}
                    data-toggle="modal"
                    data-target="#customercreation"   
                    >
                    <span className="fa fa-plus"></span>
                    </button>
                </div>
                <div className="mt-1 d-flex">
                    &nbsp;
                    <span id="balancediv" style={{ display: "none", color: "black" }}>
                    Available Balance: <span id="balval"></span>
                    </span>
                </div>
                </div>

                <div className="col-md-4">
                <div id="phonediv">
                    <label1 className="col-form-label" style={{ marginBottom: "1vh", marginLeft: "2vh"  }}>Phone No.</label1>
                    <input
                    className="form-control border-secondary"
                    name="phno"
                    id="ptyphone"
                    value={ptyphone}
                    style={{
                        color: "black",
                        backgroundColor: "white",
                    }}
                    readOnly
                    />
                </div>
                </div>

                <div className="col-md-4" id="addressdiv">
                <label1 className="col-form-label" style={{ marginBottom: "1vh", marginLeft: "2vh"  }}>Billing Address</label1>
                <textarea
                    className="form-control border-secondary mb-3"
                    name="Billing_Address"
                    id="ptyBillingAddress"
                    value={ptyBillingAddress}
                    rows="3"
                    style={{
                    color: "black",
                    backgroundColor: "white",
                    }}
                    readOnly
                ></textarea>
                </div>
            </div>
            


            <div className="row"
                style={{ display: party ? "none" : "flex" }}
            >
              <div className="col-md-4 custom-dropdown">
                <label1 className="col-form-label" style={{ marginBottom: "1vh", marginLeft: "2vh"  }}>Party</label1>
                <div className="d-flex align-items-center">
                    <Select
                        options={parties}
                        name="party"
                        className="w-100"
                        id="party"
                        value={parties.find((option) => option.value === party) || null}
                        onChange={(selectedOption) =>
                            handlePartyChange(
                            selectedOption ? selectedOption.value : ""
                          )
                        }
                        isClearable
                        isSearchable
                      />
                    
                    &nbsp;&nbsp;&nbsp;
                    <button
                    id="modal_clclosing"
                    type="button"
                    className="btn"
                    style={{
                        backgroundColor: "rgb(61, 68, 101)",
                        width: "fit-content",
                        height: "35px",
                    }}
                    data-toggle="modal"
                    data-target="#customercreation"   
                    >
                    <span className="fa fa-plus"></span>
                    </button>
                </div>
                <div className="mt-1 d-flex">
                    &nbsp;
                    <span id="balancediv" style={{ display: "none", color: "black" }}>
                    Available Balance: <span id="balval"></span>
                    </span>
                </div>
                </div>
            </div>


            <div className="row">
            <div id="billdiv" className="col-md-4">
                <label1 className="col-form-label"  style={{ marginBottom: "1vh", marginLeft: "2vh"  }}>Bill No.</label1>
                <input
                type="text"
                className="form-control border-secondary"
                name="bill_no"
                id="bill_no"
                value={billNo}
                onChange={(e) => setBillNo(e.target.value)}
                style={{ color: 'black', backgroundColor: 'white' }}
                readOnly
                />
            </div>
            <div className="col-md-4">
                <label1 className="col-form-label" style={{ marginBottom: "1vh", marginLeft: "2vh"  }}>Bill Date</label1>
                <input
                type="date"
                className="form-control border-secondary"
                name="billdate"
                id="billdate"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{ color: 'black', backgroundColor: 'white' }}
                required
                />
            </div>
            <div className="col-md-4">
                <label1 className="col-form-label" style={{ marginBottom: "1vh", marginLeft: "2vh"  }}>Place of Supply</label1>
                <select
                  className="form-control border-secondary"
                  name="PlaceOfSupply"
                  id="place_of_supply"
                  value={PlaceOfSupply}
                  onChange={(e) =>
                    handlePlaceOfSupply(e.target.value)
                  }
                >
                  <option value="State" selected>
                    State
                  </option>
                  <option value="Other State">Other State</option>
                </select>
            </div>
            </div>
            <br></br>


            <div className="table1-responsive">
            <table className="table table-bordered table-hover mt-3" id="tab_logic">
                <thead>
                <tr style={{ color: "white", backgroundColor: "rgb(61, 68, 101)" }}>
                    <th className="text-center">#</th>
                    <th className="text-center">PRODUCT</th>
                    <th className="text-center">HSN/SAC</th>
                    <th className="text-center">QTY</th>
                    <th className="text-center">PRICE</th>
                    <th className="text-center">TAX (%)</th>
                    <th className="text-center">DISCOUNT</th>
                    <th className="text-center">TOTAL</th>
                    <th className="text-center">FIELD</th>
                </tr>
                </thead>
                <tbody id="items-table-body">
                {salesItems.map((row) => {
                  const selectedOptionI = items.find(
                    (option) => option.value === row.item
                  );
                  return (
                    <tr key={row.id} id={`tab_row${row.id}`}>
                    <td className="nnum" style={{ textAlign: "center", color: "black" }}>
                       {row.id}
                    </td>
                    <td style={{ width: "20%" }}>
                        <div className="d-flex">
                        <Select
                          options={items}
                          name="item"
                          className="w-100"
                          id={`item${row.id}`}
                          required
                          value={selectedOptionI || null}
                          onChange={(selectedOption) =>
                            handleItemChange(
                              selectedOption
                                ? selectedOption.value
                                : "",
                              row.id
                            )
                          }
                          onBlur={refreshValues}
                          isClearable
                          isSearchable
                        />
                        &nbsp;&nbsp;&nbsp;
                        <button
                        type="button"
                        className="btn"
                        style={{
                            backgroundColor: "rgb(61, 68, 101)",
                            width: "fit-content",
                            height: "35px",
                        }}
                        data-toggle="modal"
                        data-target="#newitem"    
                        >
                        <span className="fa fa-plus"></span>
                        </button>
                        </div>
                    </td>
                    <td>
                        <input
                        type="text"
                        name="hsn[]"
                        value={row.hsn}
                        id={`hsn${row.id}`}
                        placeholder="HSN/SAC Code"
                        className="form-control HSNCODE"
                        style={{ color: "black", backgroundColor: "white" }}
                        readOnly
                        />
                    </td>
                    <td>
                        <input
                        type="number"
                        name="qty[]"
                        id={`qty${row.id}`}
                        value={row.quantity}
                        onChange={(e) =>
                          handleSalesItemsInputChange(
                            row.id,
                            "quantity",
                            e.target.value
                          )
                        }
                        onBlur={refreshValues}
                        className="form-control qty"
                        min="1"
                        required
                        />
                    </td>

                    <td>
                        <input
                        type="number"
                        name="price"
                        id={`price${row.id}`}
                        className="form-control price"
                        step="0.00"
                        min="0"
                        style={{ color: "black", backgroundColor: "white" }}
                        value={row.price}
                        readOnly
                        />
                    </td>
                    <td>
                    <input
                      type="text"
                      name="taxgst[]"
                      className="form-control tax tax_ref tax-gst"
                      placeholder="0 %"
                      id={`taxgst${row.id}`}
                      style={{
                        display: "block",
                      }}
                      value={row.taxGst}
                      readOnly
                    />
                    <input
                      type="text"
                      name="taxigst[]"
                      className="form-control tax tax_ref tax-igst"
                      placeholder="0 %"
                      id={`taxigst${row.id}`}
                      style={{
                        display: "none",
                      }}
                      value={row.taxIgst}
                      readOnly
                    />
                    </td>
                    <td>
                        <input
                        type="number"
                        name="discount"
                        placeholder="Enter Discount"
                        id={`disc${row.id}`}
                        value={row.discount}
                        onChange={(e) =>
                          handleSalesItemsInputChange(
                            row.id,
                            "discount",
                            e.target.value
                          )
                        }
                        onBlur={refreshValues}
                        step={0}
                        min={0}
                        />
                    </td>
                    <td>
                      <input
                      type="number"
                      name="total[]"
                      id={`total${row.id}`}
                      className="form-control total"
                      value={row.total}
                      step="any"
                      readOnly
                    />
                    <input
                      type="number"
                      step="any"
                      id={`taxamount${row.id}`}
                      className="form-control taxamount"
                      value={row.taxAmount}
                      readOnly
                      hidden
                    />
                    </td>
                    <td style={{ textAlign: "center" }}>
                        <button
                        type="button"
                        className="btn delete-row"
                        style={{ color: "#ff0000" }}
                        id={`${row.id}`}
                        onClick={() => removeRow(row.id)}
                        >
                        X
                        </button>
                    </td>
                    </tr>
                   );
                })}
                </tbody>
            </table>
            <button
                type="button"
                className="btn btn-primary"
                style={{ backgroundColor: "rgb(61, 68, 101)", color: "white" }}
                id="add_row_btn"
                onClick={addNewRow}
            >
                <span className="fa fa-plus" style={{ color: "#161515" }}></span>
            </button>
            </div>


            <div className="clearfix" style={{ marginTop: '10px' }}>
            <div className="row" style={{ marginLeft: '2vh' }}>
                <div
                className="col-md-7 table-responsive mt-3"
                id="tab_logic_total"
                style={{
                    color: 'black',
                    border: '1px solid rgba(61, 68, 101)',
                    marginLeft: '-2vh',
                    backgroundColor: 'rgba(61, 68, 101)',
                }}
                >
                <div className="p-3">
                    <div className="row container-fluid p-2 m-0">
                    <div className="col-sm-4 mt-2">
                        <label className="text-center" style={{ color: 'white' }}>
                        Sub Total
                        </label>
                    </div>
                    <div className="col-sm-1 mt-2">:</div>
                    <div className="col-sm-7">
                        <input
                        type="number"
                        placeholder={0.0}
                        value={subTotal}
                        name="subtotal"
                        id="sub_total"
                        readOnly
                        style={{ color: 'black', backgroundColor: 'white' }}
                        className="form-control border-secondary"
                        />
                    </div>
                    <div className="col-sm-4 m-0 p-0"></div>
                    </div>

                    <div className="row container-fluid p-2 m-0 amount_summary" id="igst_val"
                    style={{
                      display:
                        PlaceOfSupply == "State" ? "none" : "flex",
                    }}>
                    <div className="col-sm-4 mt-2">
                        <label htmlFor="a" style={{ color: 'white' }} className="text-center">
                        IGST
                        </label>
                    </div>
                    <div className="col-sm-1 mt-2">:</div>
                    <div className="col-sm-7">
                        <input
                        type="number"
                        placeholder={0.0}
                        value={igst}
                        name="igst_tax"
                        id="igst_tax"
                        readOnly
                        style={{ color: 'black', backgroundColor: 'white' }}
                        className="form-control border-secondary"
                        />
                    </div>
                    <div className="col-sm-4 m-0 p-0"></div>
                    </div>

                    <div className="row container-fluid p-2 m-0 amount_summary" id="cgst_val"
                    style={{
                      display:
                        PlaceOfSupply == "State" ? "flex" : "none",
                    }}>
                    <div className="col-sm-4 mt-2">
                        <label htmlFor="a" style={{ color: 'white' }} className="text-center">
                        CGST
                        </label>
                    </div>
                    <div className="col-sm-1 mt-2">:</div>
                    <div className="col-sm-7">
                        <input
                        type="number"
                        placeholder={0.0}
                        value={cgst}
                        name="cgst_tax"
                        id="cgst_tax"
                        readOnly
                        style={{ color: 'black', backgroundColor: 'white' }}
                        className="form-control border-secondary"
                        />
                    </div>
                    <div className="col-sm-4 m-0 p-0"></div>
                    </div>

                    <div className="row container-fluid p-2 m-0 amount_summary" id="sgst_val"
                    style={{
                      display:
                        PlaceOfSupply == "State" ? "flex" : "none",
                    }}>
                    <div className="col-sm-4 mt-2">
                        <label htmlFor="a" style={{ color: 'white' }} className="text-center">
                        SGST
                        </label>
                    </div>
                    <div className="col-sm-1 mt-2">:</div>
                    <div className="col-sm-7">
                        <input
                        type="number"
                        placeholder={0.0}
                        value={sgst}
                        name="sgst_tax"
                        id="sgst_tax"
                        readOnly
                        style={{ color: 'black', backgroundColor: 'white' }}
                        className="form-control border-secondary"
                        />
                    </div>
                    <div className="col-sm-4 m-0 p-0"></div>
                    </div>

                    <div className="row container-fluid p-2 m-0 amount_summary">
                    <div className="col-sm-4 mt-2">
                        <label htmlFor="a" style={{ color: 'white' }} className="text-center">
                        Tax Amount
                        </label>
                    </div>
                    <div className="col-sm-1 mt-2">:</div>
                    <div className="col-sm-7">
                        <input
                        type="number"
                        placeholder={0.0}
                        value={taxAmount}
                        name="tax"
                        id="tax"
                        readOnly
                        style={{ color: 'black', backgroundColor: 'white' }}
                        className="form-control border-secondary"
                        />
                    </div>
                    <div className="col-sm-4 m-0 p-0"></div>
                    </div>

                    <div className="row container-fluid p-2 m-0 amount_summary">
                    <div className="col-sm-4 mt-2">
                        <label htmlFor="a" style={{ color: 'white' }} className="text-center">
                        Adjustment
                        </label>
                    </div>
                    <div className="col-sm-1 mt-2">:</div>
                    <div className="col-sm-7">
                        <input
                        type="number"
                        
                        value={adjustment}
                        onChange={(e) =>
                          handleAdjustment(e.target.value)
                        }
                        step="any"
                        name="adjustment"
                        id="adjustment"
                        className="form-control border-secondary"
                        style={{ color: 'black', backgroundColor: 'white' }}
                        />
                    </div>
                    <div className="col-sm-4 m-0 p-0"></div>
                    </div>

                    <div className="row container-fluid p-2 m-0 amount_summary">
                    <div className="col-sm-4 mt-2">
                        <label htmlFor="a" style={{ color: 'white' }} className="text-center">
                        Grand Total
                        </label>
                    </div>
                    <div className="col-sm-1 mt-2">:</div>
                    <div className="col-sm-7">
                        <input
                        type="number"
                        placeholder={0.0}
                        value={grandTotal}
                        name="grand_total"
                        id="grand_total"
                        readOnly
                        style={{ color: 'black', backgroundColor: 'white' }}
                        className="form-control border-secondary"
                        />
                    </div>
                    <div className="col-sm-4 m-0 p-0"></div>
                    </div>
                </div>
                </div>
            </div>

            <div className="row">
            <div className="col-md-1 mt-4"></div>
            <div className="col-md-5 mt-3 d-flex" style={{ marginLeft: '5vh' }}>
                <button
                type="submit"
                className="btn"
                style={{ backgroundColor: 'rgb(61, 68, 101)', color: '#fff',marginLeft: '3vh' }}
                name="Save"
                // onClick={() => setAction("save")}
                >
                SAVE
                </button>
            </div>
            </div>
            </div>


          </form>


       
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          </div>
        </div>
      </div>
    </div> 
      

    {/* Party modal */}
    <div className="modal fade" id="customercreation">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header" style={{ backgroundColor: "rgba(61, 68, 101)" }}>
            <h3 className="m-3 text" style={{ color: "white" }}>ADD PARTY</h3>
            <button type="button" className="close" data-dismiss="modal" id="fileModalDismiss" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ backgroundColor: "rgba(61, 68, 101)" }}>
            <form className="needs-validation px-1"
                  onSubmit={handleSubmit}
                  validate autoComplete="off">
              <div className="bg-light bs p-4 rounded mb-5">
                <div id="main_form_div">
                  <div className="row" style={{ marginLeft: "-1vh" }}>
                    <div className="col-md-4">
                      <label1 className="col-form-label1" htmlFor="partyname" style={{ color: "black" }}>
                        Party Name
                      </label1>
                      <input
                        type="text"
                        className="form-control border-secondary"
                        id="partyname"
                        name="partyname"
                        value={partyName}
                        onChange={(e) => setPartyName(e.target.value)}
                        required
                      />
                      <div className="text-danger m-2" id="warnPartyName"></div>
                    </div>
                    <div className="col-md-4">
                      <label1 className="col-form-label1" style={{ color: "black" }}>
                        GSTIN
                      </label1>
                      <input
                        type="text"
                        className="form-control border-secondary"
                        id="gstin"
                        name="gstin"
                        value={gstin}
                        onChange={(e) => setGstin(e.target.value)}
                        onBlur={(e) => checkgst(e.target.value)}
                        placeholder="29APPCK7465F1Z1"
                        required
                      />
                      <div className="text-danger m-2" id="warngst"></div>
                    </div>
                    <div className="col-md-4">
                      <label1 className="col-form-label1" htmlFor="partyphno" style={{ color: "black" }}>
                        Mobile
                      </label1>
                      <input
                        type="number"
                        className="form-control border-secondary"
                        id="partyphno"
                        name="partyphno"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        onBlur={(e) => checkphone(e.target.value)}
                      />
                      <div className="text-danger m-2" id="warnphone"></div>
                    </div>
                  </div>
                  <p></p>
                  <hr className="p-0 m-0" />
                  <div style={{ backgroundColor: "rgba(130, 144, 199)" }} className="p-3 pb-0 mb-0">
                    <div className="row pb-0 mb-0">
                      <div
                        id="gsthead"
                        className="col text-center pb-2"
                        onClick={() => setGstTempVisible(true)}
                        style={{ cursor: "pointer", color: gstTempVisible ? "white" : "black" }}
                      >
                        <span className="fw-bolder fs-4">GST & ADDRESS</span>
                      </div>
                      <div
                        id="credithead"
                        className="col text-center pb-2"
                        onClick={() => setGstTempVisible(false)}
                        style={{ cursor: "pointer", color: !gstTempVisible ? "white" : "black" }}
                      >
                        <span className="fw-bolder fs-4">CREDIT & BALANCES</span>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  {gstTempVisible ? (
                    <div id="gsttemp">
                      {/* GST & ADDRESS Section */}
                      <div className="row">
                      <div className="col-md-6">
                      <label1 className="col-form-label1 mt-4" style={{ color: "black" }}>
                          GST Type
                        </label1>
                        <select
                          className="form-control border-secondary"
                          id="modalgsttype"
                          name="modalgsttype"
                          value={gstType}
                          onChange={(e) => setGstType(e.target.value)}
                        >
                          <option value="" hidden>Select</option>
                          <option value="Registered Party">Registered Party</option>
                          <option value="Unregistered or Consumer">Unregistered or Consumer</option>
                          <option value="Registered Business or Composition">Registered Business or Composition</option>
                        </select>
                        <div className="text-danger m-2" id="gstType" />
                        <br></br>
                        <label1 className="col-form-label mt-4" htmlFor="supplyState" style={{ color: "black" }}>
                          Supply State
                        </label1>
                        <select
                          className="form-control border-secondary"
                          id="supplyState"
                          name="supplyState"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          required
                        >
                          <option value="" selected hidden>
                            Select
                          </option>
                          <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">Himachal Pradesh</option>
                          <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Ladakh">Ladakh</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Pondicherry">Pondicherry</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                          <option value="Other Territory">Other Territory</option>
                        </select>
                      </div>
                      {/* Billing Address */}
                      <div className="col-md-6">
                        <label1 className="col-form-label1 mt-4" style={{ color: "black" }}>
                          Billing Address
                        </label1>
                        <textarea
                          className="form-control border-secondary"
                          id="baddress"
                          name="baddress"
                          rows="6"
                          value={billingAddress}
                          onChange={(e) => setBillingAddress(e.target.value)}
                        ></textarea>
                      </div>
                    </div>

                      {/* Email */}
                      <div className="row">
                        <div className="col-md-6">
                          <label1 className="col-form-label1 mt-4" htmlFor="partyemail" style={{ color: "black" }}>
                            Email
                          </label1>
                          <input
                            type="email"
                            className="form-control border-secondary"
                            id="partyemail"
                            name="partyemail"
                            value={partyemail}
                           onChange={(e) => setPartyEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <button
                        className="btn mt-5"
                        style={{ backgroundColor: "rgb(61, 68, 101)", color: "white" }}
                        type="button"
                        onClick={toggleSection}
                      >
                        NEXT
                      </button>
                    </div>
                  ) : (
                    <div id="credittemp">
                    <div className="row">
              <div className="col-sm-12 col-md-1"></div>
              <div className="col-sm-12 col-md-10 pb-4">
                <div className="row mt-4">
                  <div className="col-md-6">
                  <div className="d-flex align-items-center">
              <label1
                className="col-form-label"
                style={{ color: 'black' }}
                htmlFor="openbalance"
              >
                Opening&nbsp;Balance&nbsp;&nbsp;-
              </label1>

              <label1 className="col-form-label" style={{ color: 'black' }}>
              &nbsp;&nbsp;To&nbsp;Pay
              </label1>
              <input
                type="radio"
                className="radio-input border-secondary"
                id="toPayRadio"
                name="paymentType"
                value="To Pay"
                onChange={(e) => setToPay(e.target.value)}
                required
              />

              <label1 className="col-form-label" style={{ color: 'black' }}>
                To&nbsp;Receive
              </label1>
              <input
                type="radio"
                className="radio-input border-secondary"
                id="toReceiveRadio"
                name="paymentType"
                value="To Receive"
                onChange={(e) => setToRecieve(e.target.value)}
                required
              />
            </div>

            <input
              type="number"
              className="form-control border-secondary"
              id="openbalance"
              name="openbalance"
              style={{ color: 'black', backgroundColor: 'white' }}
              value={partyopeningBalance}
              onChange={(e) => setPartyOpeningBalance(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <label1
              className="col-form-label"
              style={{ color: 'black' }}
              htmlFor="openbalance"
            >
              Credit Limit
            </label1>
            <input
              type="number"
              className="form-control border-secondary"
              id="crd_lmt"
              name="crd_lmt"
              style={{ color: 'black', backgroundColor: 'white' }}
              value={creditLimit}
              onChange={(e) => setCreditLimit(e.target.value)}
            />
          </div>
        </div>
        
        <br></br>
        <div className="row">
          <div className="col-md-6">
            <label1
              className="col-form-label mt-4"
              style={{ color: 'black' }}
            >
              Date
            </label1>
            <input
              type="date"
              className="form-control border-secondary"
              id="partydate"
              name="partydate"
              style={{ color: 'black', backgroundColor: 'white' }}
              defaultValue={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>

        <div className="text-start mt-5">
          <button
            id="creditprev"
            className="btn fw-bold"
            style={{
              backgroundColor: 'rgb(61, 68, 101)',
              color: 'white',
            }}
            type="button"
            onClick={toggleSection}
          >
            PREVIOUS
          </button>
          &nbsp;&nbsp;
          <button
            className="btn fw-bold"
            style={{
              backgroundColor: 'rgb(61, 68, 101)',
              color: 'white',
            }}
            type="button"
            id="customersave"
            onClick={handleNewPartyModalSubmit}
            data-dismiss="modal"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>

                  </div>

                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    
   {/* New Ietm */}
   <div className="modal fade" id="newitem">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: 'rgba(61, 68, 101)' }}>
              <h5 className="m-3" style={{ color: 'white' }}>ADD ITEM</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100" style={{ backgroundColor: 'rgba(61, 68, 101)' }}>
              <div className="card p-3">
                <form
                  className="needs-validation px-1"
                  onSubmit={handleSubmit}
                  validate
                >
                  <div className="row w-100">
                    <div className="col-md-12 mx-0">
                      <div className="row">
                      <div className="col-md-3">
                          <label1 for="itemType" style={{ color: "black" }}>
                            Type
                          </label1>
                          <select
                            name="type"
                            className="form-control border-dark bg-light"
                            id="itemType"
                            value={type}
                            onChange={(e) => {
                              setType(e.target.value);
                              itemTypeChange();
                            }}
                            required
                          >
                            <option selected disabled value="">
                              Choose...
                            </option>
                            <option value="Goods">Products</option>
                            <option value="Service">Services</option>
                          </select>
                        </div>

                        <div className="col-md-3">
                          <label1 for="itemName" style={{ color: "black" }}>
                            Name
                          </label1>
                          <input
                            type="text"
                            id="itemName"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control border-dark"
                            autocomplete="off"
                            required
                          />
                        </div>
                        
                        <div className="col-md-3" id="hsnDiv">
                          <label1 for="hsnField" style={{ color: "black" }}>
                            HSN Code
                          </label1>
                          <input
                            type="number"
                            name="hsn"
                            className="form-control border-dark"
                            placeholder="Enter a valid HSN code"
                            required
                            value={hsn}
                            onChange={(e) => setHsn(e.target.value)}
                            id="hsnField"
                            onInput={validateHSN}
                          />
                          <div id="hsnError" style={{ color: "red" }}></div>
                        </div>

                        <div
                          className="col-md-3"
                          id="sacDiv"
                          style={{ display: "none" }}
                        >
                          <label1 for="sacField" style={{ color: "black" }}>
                            SAC Code
                          </label1>
                          <input
                            type="number"
                            name="sac"
                            className="form-control border-dark"
                            placeholder="Enter a valid SAC code"
                            required
                            value={sac}
                            onChange={(e) => setSac(e.target.value)}
                            id="sacField"
                            onInput={validateSAC}
                          />
                          <div id="sacError" style={{ color: "red" }}></div>
                        </div>

                        <div className="col-md-3">
                          <label1 for="itemUnit" style={{ color: "black" }}>
                            Unit
                          </label1>
                          <div className="d-flex align-items-center">
                            <select
                              className="form-control border-dark bg-light"
                              name="unit"
                              id="itemUnit"
                              value={unit}
                              onChange={(e) => setUnit(e.target.value)}
                              required
                            >
                              <option value="" hidden>Select a unit</option>
                              <option value="NOS">NOS</option>
                              <option value="BOX">BOX</option>
                              <option value="PACKET">PACKET</option>
                              {units &&
                                units.map((i) => (
                                  <option
                                    value={i.name}
                                    className="text-uppercase"
                                  >
                                    {i.name}
                                  </option>
                                ))}
                            </select>
                            <button
                              type="button"
                              className="btn btn-outline-secondary ml-1"
                              data-toggle="modal"
                              data-dismiss="modal"
                              data-target="#createNewUnit"
                              style={{
                                backgroundColor: 'rgb(60, 61, 101)',
                                height: '34px',
                                textAlign: 'center',
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>

                      </div>
                     
                      <p></p>
                      <hr className="p-0 m-0" />
                      <div style={{ backgroundColor: 'rgba(130, 144, 199)' }} className="p-3 pb-0 mb-0">
                        <div>
                          <div className="row pb-0 mb-0">
                            <div id="prc_" className="col text-center pb-2">
                              <a
                                className="fw-bolder fs-4"
                                onClick={() => setPriceTempVisible(true)}
                                style={{ cursor: "pointer", color: priceTempVisible ? "white" : "black" }}
                              >
                                PRICING
                              </a>
                            </div>
                            <div id="stk_" className="col text-center pb-2">
                              <a
                                className="fw-bolder fs-4"
                                onClick={() => setPriceTempVisible(false)}
                                style={{ cursor: "pointer", color: !priceTempVisible ? "white" : "black" }}
                              >
                                STOCKS
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      {priceTempVisible ? (
                      <div id="pricing_template">
                      <div className="row mt-3">
                        <div className="col-md-3 mt-3">
                          <label1 style={{ color: "black" }}>
                           
                          </label1>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check mt-1">
                            <input
                              className="form-check-input"
                              name="taxref"
                              type="radio"
                              id="inclusive"
                              value="taxable"
                              onChange={(e) => setTaxRef(e.target.value)}
                              onClick={showdiv}
                              required
                            />
                            <label1 style={{ color: "black" }} for="inclusive">
                              Taxable
                            </label1>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check mt-1">
                            <input
                              className="form-check-input"
                              name="taxref"
                              type="radio"
                              value="non taxable"
                              onChange={(e) => setTaxRef(e.target.value)}
                              id="check"
                              onClick={hidediv}
                            />
                            <label1 style={{ color: "black" }} for="check">
                              Non Taxable
                            </label1>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        id="taxableDiv"
                        style={{ display: "none" }}
                      >
                        <div className="col-md-6 mt-3">
                          <label1 for="intraStateTax" style={{ color: "black" }}>
                              GST
                          </label1>
                          <select
                            name="intra_st"
                            className="form-control fw- border-secondary"
                            style={{ color: 'black', backgroundColor: 'white' }}
                            id="intraStateTax"
                            value={intraStateTax}
                            onChange={(e) => setIntraStateTax(e.target.value)}
                          >
                            <option value="GST0[0%]">GST0[0%]</option>
                            <option value="GST3[3%]">GST3[3%]</option>
                            <option value="GST5[5%]">GST5[5%]</option>
                            <option value="GST12[12%]">GST12[12%]</option>
                            <option value="GST18[18%]">GST18[18%]</option>
                            <option value="GST28[28%]">GST28[28%]</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label1 for="interStateTax" style={{ color: "black" }}>
                            IGST
                          </label1>
                          <select
                            name="inter_st"
                            className="form-control fw- border-secondary"
                            style={{ color: 'black', backgroundColor: 'white' }}
                            id="interStateTax"
                            value={interStateTax}
                            onChange={(e) => setInterStateTax(e.target.value)}
                          >
                            <option value="IGST0[0%]">IGST0[0%]</option>
                            <option value="IGST3[3%]">IGST3[3%]</option>
                            <option value="IGST5[5%]">IGST5[5%]</option>
                            <option value="IGST12[12%]">IGST12[12%]</option>
                            <option value="IGST18[18%]">IGST18[18%]</option>
                            <option value="IGST28[28%]">IGST28[28%]</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-6 mt-3">
                          <label1 style={{ color: "black" }}>Sales Price</label1>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <input
                                type="text"
                                name="salesprice"
                                className="form-control border-dark bg-light"
                                id="saleprice"
                                value={salesPrice}
                                onChange={(e) => setSalesPrice(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label1 style={{ color: "black" }}>
                            Purchase Price
                          </label1>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <input
                                type="number"
                                name="pcost"
                                className="form-control border-dark bg-light"
                                id="purprice"
                                value={purchasePrice}
                                onChange={(e) =>
                                  setPurchasePrice(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <button
                        className="text-start mt-5 btn mt-5"
                        style={{ backgroundColor: "rgb(61, 68, 101)", color: "white" }}
                        type="button"
                        onClick={toggleSection1}
                      >
                        NEXT
                      </button>
                      </div>
                      ) : (

                      <div id="stock_template">
                      {/* Stock template content */}
                      <div className="row">
                      <div className="col-sm-12 col-md-1"></div>
                      <div className="col-sm-12 col-md-10 pb-4">
                        <br />
                        <br />
                        <div className="d-flex">
                          <div className="w-50 me-3">
                            <label1 className="tb" htmlFor="stock" style={{ color: 'black' }}>
                              OPENING STOCK
                            </label1>
                            <br />
                            <input
                              style={{ color: 'black', backgroundColor: 'white' }}
                              id="stock"
                              className="form-control fw-normal border-secondary"
                              type="number"
                              name="stock"
                              defaultValue="0"
                              value={openigstock}
                              onChange={(e) => setOpeningStock(e.target.value)}
                            />
                          </div>
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          <div id="at_price_div" className="w-50">
                            <label1 className="tb" htmlFor="itmprice" style={{ color: 'black' }}>
                              AT PRICE
                            </label1>
                            <br />
                            <input
                              style={{ color: 'black', backgroundColor: 'white' }}
                              id="itmprice"
                              className="form-control fw-normal border-secondary"
                              type="number"
                              name="itmprice"
                              defaultValue="0"
                              value={stockUnitRate}
                              onChange={(e) => setStockUnitRate(e.target.value)}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="d-flex">
                          <div className="w-50 me-3">
                            <label1 className="tb" htmlFor="itmdate" style={{ color: 'black' }}>
                              DATE
                            </label1>
                            <br />
                            <input
                              style={{ color: 'black', backgroundColor: 'white' }}
                              id="itmdate"
                              className="form-control fw-normal border-secondary"
                              type="date"
                              required
                              name="itmdate" 
                              defaultValue={new Date().toISOString().split("T")[0]}
                            />
                          </div>
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          <div className="w-50">
                            <label1
                              className="tb"
                              htmlFor="minstock"
                              style={{ color: 'black' }}
                            >
                              MINIMUM STOCK TO MAINTAIN
                            </label1>
                            <br />
                            <input
                              style={{ color: 'black', backgroundColor: 'white' }}
                              id="minstock"
                              className="form-control fw-normal border-secondary"
                              type="number"
                              name="minstock"
                              defaultValue="0"
                              value={minStock}
                              onChange={(e) => setMinStock(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="text-start mt-5">
                          <button
                            id="prev_btn"
                            className="btn me-3"
                            style={{ backgroundColor: 'rgb(61, 68, 101)' }}
                            type="button"
                            onClick={toggleSection1}
                          >
                            PREVIOUS
                          </button>
                          &nbsp; &nbsp;
                          <button
                            className="btn"
                            style={{ backgroundColor: 'rgb(61, 68, 101)' }}
                            onClick={handleItemModalSubmit}
                            data-dismiss="modal"
                            type="button"
                            id="newItemSave"
                          >
                            SAVE
                          </button>
                        </div>
                      </div>
                      </div>
                      </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
   </div>


  {/* Unit Modal */}
  <div className="modal fade" id="createNewUnit">
    <div className="modal-dialog">
      <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
        <div className="modal-header">
          <h5 className="m-3" style={{ color: 'white' }}>New Item Unit</h5>
          <button
            type="button"
            className="close"
            data-toggle="modal"
            data-dismiss="modal"
            data-target="#newItem"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body w-100">
          <div className="card p-3">
            <form
              onSubmit={handleUnitModalSubmit}
              id="newUnitForm"
              className="px-1"
            >
              <div className="row mt-2 w-100">
                <div className="col-12">
                  <label1 for="name">Unit Name</label1>
                  <input
                    name="name"
                    id="unit_name"
                    value={newUnit}
                    onChange={(e) => setNewUnit(e.target.value)}
                    className="form-control text-uppercase w-100"
                  />
                </div>
              </div>
              <div className="row mt-4 w-100">
                <div className="col-12 d-flex justify-content-center">
                  <button
                    className="btn btn-outline-info text-grey"
                    type="submit"
                    data-toggle="modal"
                    data-target="#newItem"
                    onClick={handleUnitModalSubmit}
                    id="saveItemUnit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>


    </>
  )
}

export default Edit_Bill
