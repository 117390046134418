import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { Chart } from 'chart.js';
import axios from "axios";
import Cookies from "js-cookie";
import config from "../../../functions/config";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function Bill_Graph() {
  const [graphMonthlyType, setGraphMonthlyType] = useState(true);

  const [monthlyChartLabels, setMonthlyChartLabels] = useState([]);
  const [monthlyChartData, setMonthlyChartData] = useState([]);
  const [yearlyChartLabels, setYearlyChartLabels] = useState([]);
  const [yearlyChartData, setYearlyChartData] = useState([]);

  // Refs to hold chart instances
  const monthlyChartRef = useRef(null);
  const yearlyChartRef = useRef(null);

  const ID = Cookies.get("user_id");
  const navigate = useNavigate();

  const [purchases, setPurchases] = useState([]);
  const [company, setCompany] = useState({});
  const [totalPurchases, setTotalPurchases] = useState(0.0);

  const fetchPurchasesReports = () => {
    axios
      .get(`${config.base_url}/get_purchase_bill_graph/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          let sale = res.data.purchases;
          let cmp = res.data.company;
          let chartData = res.data.chart;
          setCompany(cmp);

          setPurchases([]);
          sale.map((i) => {
            setPurchases((prevState) => [...prevState, i]);
          });

          setTotalPurchases(res.data.total_purchase_amount);

          setMonthlyChartLabels(chartData.monthly_labels);
          setMonthlyChartData(chartData.monthly_purchases);
          setYearlyChartLabels(chartData.yearly_labels);
          setYearlyChartData(chartData.yearly_purchases);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchPurchasesReports();
  }, []);

  useEffect(() => {
    // Cleanup existing chart instances before creating new ones
    if (monthlyChartRef.current) {
      monthlyChartRef.current.destroy();
    }
    if (yearlyChartRef.current) {
      yearlyChartRef.current.destroy();
    }

    // Initialize Sales & Purchase Chart
    const ctx2 = document.getElementById("monthlyChart").getContext("2d");
    monthlyChartRef.current = new Chart(ctx2, {
      type: "bar",
      data: {
        labels: monthlyChartLabels,
        datasets: [
          {
            label: "Monthly Purchases",
            data: monthlyChartData,
            backgroundColor: "rgb(61,68,101)",
            fill: true,
          },
        ],
      },
      options: {
        responsive: true,
      },
    });

    // Initialize Stock In Hand Reports Chart
    const ctx1 = document.getElementById("yearlyChart").getContext("2d");
    yearlyChartRef.current = new Chart(ctx1, {
      type: "line",
      data: {
        labels: yearlyChartLabels,
        datasets: [
          {
            label: "Yearly Purchases",
            data: yearlyChartData,
            backgroundColor: "rgb(61,68,101)",
          },
        ],
      },
      options: {
        responsive: true,
      },
    });

    // Cleanup function to destroy the charts when the component unmounts or data changes
    return () => {
      if (monthlyChartRef.current) {
        monthlyChartRef.current.destroy();
      }
      if (yearlyChartRef.current) {
        yearlyChartRef.current.destroy();
      }
    };
  }, [
    monthlyChartLabels,
    yearlyChartLabels,
    monthlyChartData,
    yearlyChartData,
  ]);
  return (
    <>
    <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper d-flex">
    <Sidebar />
    <div className="main-panel px-3 py-2" style={{ background: "rgb(245, 245, 245)" }}> 
      <div className="content-wrapper">
        <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>

        <style>
        {`
        
        `}
      </style>

        <div
          id="salesReportsGraph"
          className="mx-auto w-75"
        >
          <div className="chartSectionHead">
            <div className="d-flex justify-content-end">
              <div className="ml-auto">
                <Link
                  to="/bill"
                  style={{
                    fontSize: "x-large",
                    color: "black",
                    cursor: "pointer",
                  }}
                  title="Close"
                >
                  <i
                    className="fa fa-times-circle fs-6 tb close_pad mt-4 "
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
            <form id="chartToggleForm" style={{ display: 'flex', alignItems: 'center' }}>
              <label1 style={{ marginRight: '10px' }}>
                <input
                  type="radio"
                  name="chartType"
                  value="monthly"
                  checked={graphMonthlyType}
                  onChange={() =>
                    setGraphMonthlyType((prev) => !prev)
                  }
                />
                Monthly
              </label1>
              <label1 className="ms-3" style={{ marginRight: '10px' }}>
                <input
                  type="radio"
                  name="chartType"
                  value="yearly"
                  onChange={() =>
                    setGraphMonthlyType((prev) => !prev)
                  }
                />
                Yearly
              </label1>
            </form>
            
            <br></br>
          </div>

          <div className="chartSection">
            <div
              id="monthlyChartContainer"
              style={{
                display: graphMonthlyType ? "block" : "none",
              }}
            >
              <canvas id="monthlyChart" />
            </div>
            <div
              id="yearlyChartContainer"
              style={{
                display: !graphMonthlyType ? "block" : "none",
              }}
            >
              <canvas id="yearlyChart" />
            </div>
          </div>
        </div>

        </div>
          </div>
        </div>
        
      </div>
    </div>
  </>
  )
}

export default Bill_Graph
