import React from 'react';
import ChartComponent from './ChartComponent';

const BarGraph = ({ chartData, chartOptions }) => {
  return (
    <div style={{ marginBottom: '30px' }}>

      <ChartComponent
        chartType="bar"
        chartData={chartData}
        chartOptions={chartOptions}
      />
    </div>
  );
};

export default BarGraph;
