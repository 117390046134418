import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
} from "recharts";
import axios from "axios";
import Cookies from "js-cookie";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/AdminStyles.css";
import "./styles/Layout.css";
import config from "../../functions/config";

function PurchaseReportGraph() {
  const [chartData, setChartData] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const [panelWidth, setPanelWidth] = useState(
    window.innerWidth > 768 ? "90%" : "100%"
  );
  const userId = Cookies.get("user_id");

  useEffect(() => {
    const fetchPurchaseReportGraph = async () => {
      if (!userId) {
        console.error("User ID not found in cookies");
        return;
      }

      try {
        const response = await axios.get(
          `${config.base_url}/api/purchase-reportGraph/`,
          {
            params: { userId },
          }
        );

        setChartData(response.data);
      } catch (error) {
        console.error("Error fetching purchase report graph:", error);
      }
    };

    fetchPurchaseReportGraph();
  }, [userId]);

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
  };

  const getChartData = () => {
    if (!chartData) return [];
    if (selectedPeriod === "monthly") {
      return chartData.monthly_sales.map((value, index) => ({
        label: chartData.monthly_labels[index],
        value,
      }));
    }
    return chartData.yearly_sales.map((value, index) => ({
      label: chartData.yearly_labels[index],
      value,
    }));
  };

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#f5f5f5", width: panelWidth }}
        >
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="card radius-15">
                <div className="card-body">
                  <div className="card-title text-center">
                    <h3
                      style={{
                        fontSize: window.innerWidth > 768 ? "30px" : "20px",
                        fontWeight: "bold",
                      }}
                    >
                     
                    </h3>
                  </div>
                  <hr />
                  <form id="chartToggleForm">
  <div
    style={{
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: "60px",
      left: "30px",
      zIndex: 1,
    }}
  >
    <label
      htmlFor="monthly"
      style={{ marginRight: "10px", marginBottom: "0", fontWeight: "bold" }}
    >
      
    </label>
    <input
      type="radio"
      id="monthly"
      name="chartType"
      value="monthly"
      checked={selectedPeriod === "monthly"}
      onChange={handlePeriodChange}
      style={{ marginRight: "2px" }}
    />
    Monthly
    <label
      htmlFor="yearly"
      style={{ marginLeft: "20px", marginBottom: "0", fontWeight: "bold" }}
    >
      
    </label>
    <input
      type="radio"
      id="yearly"
      name="chartType"
      value="yearly"
      checked={selectedPeriod === "yearly"}
      onChange={handlePeriodChange}
      style={{ marginLeft: "10px" }}
    />
    
    Yearly
  </div>
  
</form>



                  {/* Display Chart */}
                  {chartData ? (
                    <div className="chart-container">
                      <h4 className="text-center">
                        {selectedPeriod === "monthly"
                          ? "Monthly Sales Report"
                          : "Yearly Sales Report"}
                      </h4>
                      {selectedPeriod === "monthly" ? (
                        <BarChart
                          width={1200} // Increased width
                          height={700}
                          data={getChartData()}
                          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="label" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar
                            dataKey="value"
                            fill="#3d4465"
                            barSize={50}
                          />
                        </BarChart>
                      ) : (
                        <AreaChart
                        width={1200} // Full width
                        height={700}
                        data={getChartData()}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="label" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area
                          type="monotone"
                          dataKey="value"
                          stroke="#8884d8"
                          fill="rgba(36, 48, 122, 1)"


                        />
                      </AreaChart>
                      
                      )}
                    </div>
                  ) : (
                    <p className="text-center">Loading chart data...</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseReportGraph;
