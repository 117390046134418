import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";


function Bill_History() {
  const { billId } = useParams();
  const [history, setHistory] = useState([]);
  const [bill, setbill] = useState({});

  const fetchbillHistory = () => {
    axios
      .get(`${config.base_url}/fetch_PurchaseBillHistory/${billId}/`)
      .then((res) => {
        console.log("bill HIST=", res);
        if (res.data.status) {
          var sls = res.data.Bill;
          var hist = res.data.history;
          setbill(sls);
          setHistory([]);
          hist.map((i) => {
            setHistory((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchbillHistory();
  }, []);
  return (
    <>
    <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper d-flex">
    <Sidebar />
    <div className="main-panel px-3 py-2" style={{ background: "rgb(130, 144, 199)" }}> 
      <div className="content-wrapper">
        <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>

        <style>
        {`
         .tablediv{
        margin-left: 1vh;
        margin-right: 1vh;
        margin-top: 1vh;
    }

    h4{
        text-align: left; 
        margin-top:2vh; 
        margin-bottom: 2vh;
        margin-left: 1vh;
    }
    td,tr,th,table{
        border:2px 2px 2px 2px black;
    }
     ::-webkit-scrollbar{
        display: none
    }

    @media(min-width:768px){
        .history-div{
        background: linear-gradient(#3d4465 0, #3d4465 116px, white 0px, white 100px);
    }
    }

    @media(max-width:768px){
        .history-div{
        background: linear-gradient(#3d4465 0, #3d4465 189px, white 0px, white 100px);
    }
    }

    .button-hover{
        background-color: white;
        color: #3d4465;
    }
    .button-hover:hover{
        background-color:#4d5fbc ;
        color: white;
    }
        `}
      </style>


      <div className="body-wrapper">
      <br />
      <br />
      <div className="p-3 text-center" style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="card col-md-9 history-div" style={{ backgroundColor: 'white', width: '50rem' }}>
          <div className="row">
            <div className="col-md-4">
              <h4
                className="fs-3"
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: '5%',
                  fontSize: 'large',
                }}
              >
                Transaction History
              </h4>
            </div>
            <div className="col-6"></div>
            <div className="col my-3">
              <Link to="/bill" className="btn button-hover">
                <i className="fa fa-arrow-left"></i> Back
              </Link>
            </div>
          </div>
          <div className="tablediv table-responsive" style={{ height: '28rem' }}>
            <table className="table" id="billtable" style={{ textAlign: 'center' }}>
              <thead style={{ backgroundColor: 'rgb(61, 68, 101)' }}>
                <tr style={{ width: '35%' }}>
                  <th className="text-white" style={{ backgroundColor: '#3d4465' }}>#</th>
                  <th className="text-white" style={{ backgroundColor: '#3d4465' }}>DATE</th>
                  <th className="text-white" style={{ backgroundColor: '#3d4465' }}>BILL NUMBER</th>
                  <th className="text-white" style={{ backgroundColor: '#3d4465' }}>NAME</th>
                  <th className="text-white" style={{ backgroundColor: '#3d4465' }}>AMOUNT</th>
                  <th className="text-white" style={{ backgroundColor: '#3d4465' }}>BY</th>
                  <th className="text-white" style={{ backgroundColor: '#3d4465' }}>ACTION</th>
                </tr>
              </thead>
              <tbody className="table-bordered">
              {history &&
                history.map((h, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ color: 'black' }}>{h.date}</td>
                    <td style={{ color: 'black' }}>{h.billno}</td>
                    <td style={{ color: 'black' }}>{h.partyname}</td>
                    <td style={{ color: 'black' }}>{h.amount}</td>
                    <td style={{ color: 'black' }}>{h.name}</td>
                    <td style={{ color: h.action === 'Created' ? 'red' : '#154c79' }}>{h.action}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>




        </div>
          </div>
        </div>
        
      </div>
    </div>
    </>
  )
}

export default Bill_History
