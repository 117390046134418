import React, { useState ,useRef,useEffect} from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint,faFilePdf,faShare } from '@fortawesome/free-solid-svg-icons';


function ViewBill() {
  const ID = Cookies.get("user_id");
  const [activeTab, setActiveTab] = useState('templates');
  const [templateVisible, setTemplateVisible] = useState(true);

  const template1Ref = useRef(null);
  const template2Ref = useRef(null);
  const template3Ref = useRef(null);
  const templateButton1Ref = useRef(null);
  const templateButton2Ref = useRef(null);
  const templateButton3Ref = useRef(null);

  const handleTabSwitch = (tab) => {
    if (tab === 'templates') {
      setActiveTab('templates');
      setTemplateVisible(true);
    } else {
      setActiveTab('slip');
      setTemplateVisible(false);
    }
  };
  
 
  // Handle template switching
  const toggleTemplate = (templateId, buttonId) => {
    // Hide all templates
    const templates = document.querySelectorAll('.printTemplates');
    templates.forEach(template => {
      template.style.display = 'none';
    });
    
    // Show selected template
    const selectedTemplate = document.getElementById(templateId);
    if (selectedTemplate) {
      selectedTemplate.style.display = 'block';
    }

    // Remove active class from all buttons
    const buttons = document.querySelectorAll('.templateToggleButtons');
    buttons.forEach(button => {
      button.classList.remove('active');
    });

    // Add active class to selected button
    const selectedButton = document.getElementById(buttonId);
    if (selectedButton) {
      selectedButton.classList.add('active');
    }

    scrollToTop();
  };


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  const { billId } = useParams();
  const [pbill, setPbill] = useState('');
  const [staff, setStaff] = useState('');
  const [dis, setDiss] = useState('');
  const [pitm, setPitm] = useState('');

  const [otherDetails, setOtherDetails] = useState({});

  const fetchBillDetails = () => {
    axios
      .get(`${config.base_url}/fetch_PurchaseBillDetails/${billId}/`)
      .then((res) => {
        console.log("BILL DATA=", res);
        if (res.data.status) {
          var bill = res.data.bill;
          var itms = res.data.items;
          var other = res.data.otherDetails;

          setOtherDetails(other);
          setPitm([]);
          itms.map((i) => {
            setPitm((prevState) => [...prevState, i]);
          });
        
          setPbill(bill);
         
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchBillDetails();
  }, []);


  
  function slipPdf() {
    var BillNo = `${pbill.billno}`;
    var element = document.getElementById("slipdiv");
    var opt = {
      margin: 1,
      filename: "Bill_Slip_" + BillNo + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }


  function BillPdf() {
    var BillNo = `${pbill.billno}`;
    var element = document.getElementById("template");
    var opt = {
        margin: 1,
        filename: "PurchaseBill" + BillNo + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
}



  function printSheet() {
    var divToPrint = document.getElementById("template");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }


  function printSlip() {
    var divToPrint = document.getElementById("slip_container");
    var printWindow = window.open("", "", "height=700,width=1000");
    var styles = `
    body {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
    }
    .slip h5 {
      font-family: serif;
    }
    p {
      font-size: 1.2em;
    }
    .address {
      display: flex;
      flex-direction: column;
    }
    .address p,
    .slip-footer p {
      font-size: 1rem;
      margin: 0;
    }
    .slip-container {
      width: 105mm;
      margin: 0 auto; /* Center the slip in the page */
      padding: 2rem;
      border: 1px dotted black;
      box-shadow: rgba(60, 64, 67, 0.5) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.35) 0px 2px 6px 2px;
    }
    .divider {
      margin: 1rem 0;
      border-bottom: 3px dotted black;
    }
    .trns-id p,
    .datetime p,
    .createdby p {
      font-size: 0.85rem;
      margin: 0;
    }
    .equal-length-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 2vh;
      margin-right: 2vh;
    }

    .equal-length-item {
      flex: 1;
      text-align: center;
    }
    `;

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write("<style>");
    printWindow.document.write(styles);
    printWindow.document.write("</style>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  

  function updateDateTime() {
    var currentDate = new Date();
    var day = currentDate.getDate();
    var month = currentDate.getMonth() + 1;
    var year = currentDate.getFullYear();
    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();
    var seconds = currentDate.getSeconds();
    var formattedDay = day < 10 ? `0${day}` : day;
    var formattedMonth = month < 10 ? `0${month}` : month;
    var formattedHours = hours < 10 ? `0${hours}` : hours;
    var formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    var formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    var formattedDateTime = `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    try {
      document.getElementById("dateTimeDisplay").textContent =
        formattedDateTime;
    } catch (err) {
      console.log(err);
    }
  }
  setInterval(updateDateTime, 1000);


  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;


  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var emailsString = emailIds.trim();

    var emails = emailsString.split(",").map(function (email) {
      return email.trim();
    });

    var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    var invalidEmails = [];
    if (emailsString === "") {
      alert("Enter valid email addresses.");
    } else {
      for (var i = 0; i < emails.length; i++) {
        var currentEmail = emails[i];

        if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
          invalidEmails.push(currentEmail);
        }
      }

      if (invalidEmails.length > 0) {
        alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
      } else {
        var em = {
          bill_id: billId,
          Id: ID,
          email_ids: emailIds,
          email_message: emailMessage,
        };
        axios
          .post(`${config.base_url}/share_PurchaseBillToEmail/`, em)
          .then((res) => {
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Shared via mail.",
              });
              setEmailIds("");
              setEmailMessage("");
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (
              err.response &&
              err.response.data &&
              !err.response.data.status
            ) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  }
  


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const toggleDropdown = () => {
    const dropdown = document.getElementById('myDropdown');
    if (dropdown) {
      dropdown.style.display = dropdown.style.display === 'block' ? 'none' : 'block';
    }
  };

  

  

  
  return (
    <>
    <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper d-flex">
    <Sidebar />
    <div className="main-panel px-3 py-2" style={{ background: "rgb(130, 144, 199)" }}> 
      <div className="content-wrapper">
        <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>

        <style>
        {`
          body {
            background-color: #8290c7;
          }

          .page {
            background: var(--white);
            background-color: white;
            display: block;
            margin: 0 auto;
            position: relative;
            box-shadow: var(--pageShadow);
            box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.397);
          }

          .page[size="A4"] {
            width: 21cm;
            height: 29.7cm;
            overflow: hidden;
          }

          .top-content {
            padding-bottom: 15px;
          }

          .store-user {
            padding-bottom: 25px;
          }

          .store-user p {
            margin: 0;
            font-weight: 600;
            color: #000000;
          }

          .store-user .address {
            font-weight: 400;
          }

          .store-user h5 {
            color: var(--themeColor);
            font-family: "Rajdhani", sans-serif;
          }

          thead {
            color: var(--white);
            background: var(--themeColor);
          }

          .table td,
          .table th {
            text-align: center;
            vertical-align: middle;
          }

          tr th:first-child,
          tr td:first-child {
            text-align: left;
          }

          .balance-info .table td,
          .balance-info .table th {
            padding: 0;
            border: 0;
          }

          .balance-info tr td:first-child {
            font-weight: 600;
          }

          tfoot {
            border-top: 2px solid var(--darkWhite);
          }

          tfoot td {
            font-weight: 600;
          }

          .print-only {
            height: auto;
            width: auto;
            overflow-y: auto;
            border: none;
          }

          @media print {
            body {
              visibility: hidden !important; /* hide everything in body when in print mode*/
            }

            #printslip {
              margin-left: 2vh;
              margin-right: 2vh;
            }

            .printContainer {
              visibility: visible !important; /* Override the rule above to only show the printables*/
              position: absolute;
              z-index: 99999;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              font-size: large;
            }
          }

          .vl {
            border-left: 6px solid gray;
          }

          .equal-length-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%; /* Make the container fill the height of the column */
            margin-left: 2vh;
            margin-right: 2vh;
          }

          .equal-length-item {
            flex: 1;
            text-align: center;
          }

          #templates,
          #slip {
            cursor: pointer;
          }

          #myDropdown {
            display: none;
            position: absolute;
            margin-left: 200px;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            z-index: 1;
          }

          #myDropdown a {
            display: block;
            padding: 12px 16px;
            text-decoration: none;
            color: #333;
          }

          #myDropdown a:hover {
            background-color: #ddd;
          }

          ::-webkit-scrollbar {
            display: none;
          }

          @media (min-width: 768px) {
            .details-div {
              background: linear-gradient(
                #3d4465 0%,
                #3d4465 178px,
                white 17px,
                white 100%
              );
            }
            
          }
          @media (max-width: 768px) {
            .details-div {
              background: linear-gradient(
                #3d4465 0%,
                #3d4465 170px,
                white 17px,
                white 100%
              );
            }
          }

          .selected-column {
            background-color: white;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            color: #3d4465;
            text-decoration: none;
          }
          #templateToggle {
            display: flex;
          }

          .button-hover {
            background-color: white;
            color: #3d4465;
          }
          .button-hover:hover {
            background-color: #4d5fbc;
            color: white;
          }
        `}
      </style>


      <div className="content-body">
      <div className="container-fluid">
        <div className="row p-0 m-0">
          <div className="col-md-12 p-0 m-0">
            <div className="pb-0 mb-0 p-2" style={{ height: '100%', width: '100%' }}>
              <div className="page-content">
                <div className="card radius-15 details-div">
                  <div className="card-body">
                    <div className="row mt-2">
                      <div className="col-md-8" style={{ position: 'relative', bottom: '10px' }}>
                        <h2 className="mb-4 text-white">PURCHASE BILL DETAILS</h2>
                      </div>

                      <div
                        className="col-md"
                        style={{ color: '#3d4465', position: 'relative', bottom: '10px' }}
                      >
                      
                      {activeTab === 'templates' && templateVisible && (
                        <button
                          className="btn button-hover ml-2" 
                          onMouseEnter={(e) => (e.target.style.color = 'white')}
                          onMouseLeave={(e) => (e.target.style.color = '#343957')}
                          id="printBtn"
                          onClick={printSheet}
                        >
                          Print&nbsp;
                          <span><FontAwesomeIcon icon={faPrint} /></span>
                        </button>
                      )}

                      {activeTab === 'slip' && !templateVisible && (
                        <button
                          className="btn button-hover ml-2"
                          onMouseEnter={(e) => (e.target.style.color = 'white')}
                          onMouseLeave={(e) => (e.target.style.color = '#343957')}
                          id="slipPrintBtn"
                          onClick={printSlip}
                        >
                          Print&nbsp;
                          <span><FontAwesomeIcon icon={faPrint} /></span>
                        </button>
                      )}


                      {activeTab === 'templates' && templateVisible && (
                        <button
                          className="btn button-hover ml-2"
                          onMouseEnter={(e) => (e.target.style.color = 'white')}
                          onMouseLeave={(e) => (e.target.style.color = '#343957')}
                          id="pdfBtn"
                          onClick={BillPdf}
                        >
                          Pdf&nbsp;
                          <span><FontAwesomeIcon icon={faFilePdf} /></span>
                        </button>
                      )}

                      {activeTab === 'slip' && !templateVisible && (
                        <button
                          className="ml-2 button-hover btn  "
                          onMouseEnter={(e) => (e.target.style.color = 'white')}
                          onMouseLeave={(e) => (e.target.style.color = '#343957')}
                          id="slipPdfBtn"
                          onClick={slipPdf}
                        >
                          Pdf&nbsp;
                          <span><FontAwesomeIcon icon={faFilePdf} /></span>
                        </button>
                      )}


                        {/* <button
                          className="btn ml-1 button-hover"
                          onClick={toggleDropdown}
                        >
                          Share <i className="fa-solid fa fa-share-alt"></i>
                        </button>
                        <div className="dropdown-content" id="myDropdown">
                          <ul style={{ marginBottom: '-1px' }}>
                          <a >whatsapp</a>
                          </ul>
                          <ul style={{ marginBottom: '-1px' }}>
                            <a >Email</a>
                          </ul>
                        </div> */}

                   
                    <li class="nav-item dropdown d-none d-lg-inline-block">
                      <button
                        class="btn ml-2 button-hover nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{
                          height: '37px',
                          lineHeight: '1',
                          padding: '4px 8px',
                          marginTop: '19px',
                          marginBottom: '-1px'
                        }}
                      >
                        Share&nbsp;
                        <span><FontAwesomeIcon icon={faShare} /></span>
                      </button>
                      <div
                        class="dropdown-menu show_reg_options"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a href={shareUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            WhatsApp</a>
                        </li>
                        <li>
                          <a data-toggle="modal"
                            data-target="#emailModal" 
                          >
                            Email</a>
                        </li>
                      </div>
                    </li>
                        
  
                        <Link
                          className="ml-4 text-white"
                          to="/bill" 
                          style={{ textAlign: 'right', fontSize: 'large', color: 'black' }}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </Link>
                      </div>
                    </div>
                    <br />
                    <div
                      className="row pb-0 mb-0 mt-1"
                      style={{
                        marginTop: '-2vh',
                        color: 'white',
                        position: 'relative',
                        bottom: '28px',
                        height: '100px',
                      }}
                    >
                      <div
                        id="templates"
                        className={`col text-center pb-1 pt-3 tab ${activeTab === 'templates' ? 'selected-column' : ''}`}
                        onClick={() => handleTabSwitch('templates')}
                      >
                        <a className="fw-bolder fs-5" style={{ color: 'black', fontSize: '1.5rem', fontWeight: 'bold' }}>TEMPLATE</a>
                      </div>
                      <div
                        id="slip"
                        className={`col text-center pb-1 pt-3 slip-div ${activeTab === 'slip' ? 'selected-column' : ''}`}
                        onClick={() => handleTabSwitch('slip')}
                      >
                        <a className="fw-bolder fs-5" style={{ color: 'black', fontSize: '1.5rem', fontWeight: 'bold' }}>BILL SLIP</a>
                      </div>
                    </div>
                    
                    <div
                      id="templatediv"
                      style={{ display: templateVisible ? 'block' : 'none' }}
                    >
                    <div id="template" className="print-only">
                    {/* Template display area */}
                    
                    <div
                      ref={template1Ref}
                      id="template1"
                      className="printTemplates"
                      style={{  width: '70%',margin: '0 auto', }}
                      
                    >
                    <div className="my-5 page" style={{ size: 'A4' }}>
                      <div className="p-4" id="printdiv1">
                        <section >
                          <div className="text-center">
                            <h2
                              style={{ backgroundColor: 'rgb(61, 68, 101)' }}
                              className="text-white p-3"
                            >
                              PURCHASE BILL
                            </h2>
                          </div>
                        </section>
                        <br />
                        <section className="top-content">
                          <div className="top-left">
                            <div className="position-relative" style={{ textAlign: 'left' }}>
                              <p>Bill No: &nbsp; {pbill.billno}</p>
                              <p>
                                Bill Date:
                                <span>{new Date(pbill.billdate).toLocaleDateString()}</span>
                              </p>
                            </div>
                          </div>
                        </section>

                        <section className="store-user mt-2">
                          <div className="col-12">
                            <div className="row pb-3">
                              <div className="col-7">
                                <p><b>FROM</b>,</p>
                                <h5
                                  className="text"
                                  style={{
                                    marginLeft: '4vh',
                                    color: 'rgb(61, 68, 101)',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {otherDetails.Company_name}
                                </h5>
                                <p
                                  className="address"
                                  style={{ marginTop: '-1vh', marginLeft: '4vh' }}
                                >
                                  {otherDetails.Address}
                                  <br />
                                  {otherDetails.City}, {otherDetails.State}
                                  <br />
                                  {otherDetails.Pincode}
                                  <br />
                                </p>
                              </div>
                            </div>
                            <div className="row pb-3">
                              <div className="col-7">
                                <p><b>TO</b>,</p>
                                <h5
                                  className="text"
                                  style={{
                                    marginLeft: '4vh',
                                    color: 'rgb(61, 68, 101)',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {otherDetails.PartyName}
                                </h5>
                                <p
                                  className="address col-9"
                                  style={{ marginTop: '-1vh', marginLeft: '4vh' }}
                                >
                                  {otherDetails.PartyAddress}
                                </p>
                              </div>
                            </div>
                          </div>
                        </section>

                        <section className="product-area mt-4">
                          <table
                            className="table table-hover table-bordered border-dark"
                            id="table1"
                          >
                            <thead
                              style={{
                                backgroundColor: 'rgb(61, 68, 101)',
                                color: 'white',
                              }}
                            >
                              <tr>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    color: 'black',
                                  }}
                                >
                                  Items
                                </td>
                                <td style={{ fontWeight: 'bold', color: 'black' }}>HSN/SAC</td>
                                <td style={{ fontWeight: 'bold', color: 'black' }}>Quantity</td>
                                <td style={{ fontWeight: 'bold', color: 'black' }}>Price</td>
                                <td style={{ fontWeight: 'bold', color: 'black' }}>Tax</td>
                                <td style={{ fontWeight: 'bold', color: 'black' }}>Discount</td>
                                <td style={{ fontWeight: 'bold', color: 'black' }}>Total</td>
                              </tr>
                            </thead>
                            <tbody>
                             
                                {pitm &&
                                  pitm.map((i) => (
                                <tr >
                                  <td style={{ color: 'black', textAlign: 'center' }}>{i.name}</td>
                                  <td style={{ color: 'black' }}>{i.item_type == "Goods" ? i.hsn : i.sac}</td>
                                  <td style={{ color: 'black' }}>{i.quantity}</td>
                                  <td style={{ color: 'black' }}>{i.Purchase_price}</td>
                                  {pbill.supplyplace === 'State' ? (
                                    <td className="text-center" style={{ color: 'black' }}>{i.item_gst}</td>
                                  ) : (
                                    <td className="text-center" style={{ color: 'black' }}>{i.item_igst}</td>
                                  )}
                                  <td style={{ color: 'black' }}>{i.discount}</td>
                                  <td style={{ color: 'black' }}>{i.total}</td>
                                </tr>
                               ))} 
                            </tbody>
                          </table>
                          <br />
                          <br />
                        </section>

                        <section className="balance-info">
                          <div className="row">
                            <div className="col-md-8"></div>
                            <div className="col-md-4">
                              <table className="table table-borderless">
                                <tbody>
                                  <tr>
                                    <td style={{ color: '#000' }}>Sub Total</td>
                                    <td style={{ color: '#000' }}>: </td>
                                    <td className="text-right" style={{ color: '#000' }}>
                                      {pbill.subtotal}
                                    </td>
                                  </tr>
                                  {pbill.supplyplace === 'State' ? (
                                    <>
                                      <tr>
                                        <td style={{ color: '#000' }}>CGST</td>
                                        <td style={{ color: '#000' }}>:</td>
                                        <td className="text-right" style={{ color: '#000' }}>
                                          {pbill.cgst}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ color: '#000' }}>SGST</td>
                                        <td style={{ color: '#000' }}>:</td>
                                        <td className="text-right" style={{ color: '#000' }}>
                                          {pbill.sgst}
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <tr>
                                      <td style={{ color: '#000' }}>IGST</td>
                                      <td style={{ color: '#000' }}>:</td>
                                      <td className="text-right" style={{ color: '#000' }}>
                                        {pbill.igst}
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td style={{ color: '#000' }}>Tax Amount</td>
                                    <td style={{ color: '#000' }}>:</td>
                                    <td className="text-right" style={{ color: '#000' }}>
                                      {pbill.taxamount}
                                    </td>
                                  </tr>
                                  {pbill.adjust !== '0.00' && pbill.adjust !== '0' && (
                                    <tr>
                                      <td style={{ color: '#000' }}>Adjustment</td>
                                      <td style={{ color: '#000' }}>:</td>
                                      <td className="text-right" style={{ color: '#000' }}>
                                        {pbill.adjust}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              <hr style={{ backgroundColor: '#000000' }} />
                              <table className="table table-borderless">
                                <tbody>
                                  <tr>
                                    <th style={{ color: '#000' }}>
                                      Grand Total &nbsp;&nbsp;&nbsp;&nbsp;
                                    </th>
                                    <th style={{ color: '#000' }}>:</th>
                                    <th style={{ color: '#000', textAlign: 'right' }}>
                                      {pbill.grandtotal}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div> 
                    </div>


                    <div
                      ref={template2Ref}
                      id="template2"
                      className="printTemplates"
                      style={{ display: 'none', width: '70%',margin: '0 auto', }}
                    >
                  <div className="my-5 page" size="A4">
                  <div id="printdiv2">
                    <div
                      className="row px-5 py-4"
                      style={{ backgroundColor: 'rgb(61, 68, 101)', width: '100%',margin: '0 auto', }}
                    >
                      <div className="col-md-4"></div>
                      <div className="col-md-4 d-flex justify-content-center">
                        <center className="h3 text-white">
                          <b>PURCHASE BILL</b>
                        </center>
                      </div>
                      <div className="col-md-4 d-flex justify-content-end">
                        <div className="text-white">
                          <p className="mb-0 mt-2" style={{ color: 'white' }}>
                            Bill No: <b>{pbill.billno}</b>
                          </p>
                          <p className="mb-0 mt-2" style={{ color: 'white' }}>
                            Bill Date: <b>{new Date(pbill.billdate).toLocaleDateString()}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="px-5 py-1">
                      <section className="store-user">
                        <br />
                        <br />
                        <div className="col-12">
                          <div className="row pb-2 mt-3">
                            <div className="col-4 pl-0">
                              <label1
                                className="text-white w-100 p-1 mb-1"
                                style={{
                                  backgroundColor: 'rgb(61, 68, 101)',
                                  borderTopRightRadius: '4vh',
                                  borderBottomRightRadius: '4vh',
                                }}
                              >
                                <b>BILLING ADDRESS</b>
                              </label1>
                              <h5 className="text-secondary" style={{ fontWeight: 'bold' }}>
                                {otherDetails.Company_name}
                              </h5>
                              <p
                                className="address"
                                style={{ fontWeight: 'bold', color: '#000' }}
                              >
                                {otherDetails.Address}
                                <br />
                                {otherDetails.City}
                                <br />
                                {otherDetails.State} - {otherDetails.Pincode}
                              </p>
                            </div>
                            <div className="col-4"></div>
                            <div className="col-4 pr-0">
                              <label1
                                className="text-white w-100 p-1 mb-1"
                                style={{
                                  borderTopRightRadius: '4vh',
                                  borderBottomRightRadius: '4vh',
                                  backgroundColor: 'rgb(61, 68, 101)',
                                }}
                              >
                                <b>SHIPPING ADDRESS</b>
                              </label1>
                              <h5 className="text-secondary" style={{ fontWeight: 'bold' }}>
                                {otherDetails.PartyName}
                              </h5>
                              <p
                                className="address"
                                style={{ fontWeight: 'bold', color: '#000' }}
                              >
                                {otherDetails.PartyAddress}
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="product-area mt-2">
                        <table className="table table-hover table-bordered" id="table2">
                          <thead style={{ backgroundColor: '#615dff' }}>
                            <tr className="template3tablehead">
                              <th className="text-center" style={{ color: 'black' }}>Items</th>
                              <th className="text-center" style={{ color: 'black' }}>HSN/SAC</th>
                              <th className="text-center" style={{ color: 'black' }}>Quantity</th>
                              <th className="text-center" style={{ color: 'black' }}>Rate</th>
                              <th className="text-center" style={{ color: 'black' }}>Tax</th>
                              <th className="text-center" style={{ color: 'black' }}>Discount</th>
                              <th className="text-center" style={{ color: 'black' }}>Total</th>
                            </tr>
                          </thead>
                          <tbody style={{ border: '1px solid #615dff' }}>
                              {pitm &&
                                  pitm.map((i) => (
                              <tr key={i.id}>
                                <td className="text-center" style={{ color: 'black' }}>{i.name}</td>
                                <td className="text-center" style={{ color: 'black' }}>
                                  {i.item_type == "Goods" ? i.hsn : i.sac}
                                </td>
                                <td className="text-center" style={{ color: 'black' }}>{i.quantity}</td>
                                <td className="text-center" style={{ color: 'black' }}>{i.Purchase_price}</td>
                                {pbill.supplyplace === 'State' ? (
                                  <td className="text-center" style={{ color: 'black' }}>{i.item_gst}</td>
                                ) : (
                                  <td className="text-center" style={{ color: 'black' }}>{i.item_igst}</td>
                                )}
                                <td className="text-center" style={{ color: 'black' }}>{i.discount}</td>
                                <td className="text-center" style={{ color: 'black' }}>{i.total}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </section>

                      <section className="balance-info">
                        <div className="row mt-3">
                          <div className="col-4"></div>
                          <div className="col-4"></div>
                          <div className="col-4">
                            <br />
                            <br />
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <td style={{ color: '#000' }}>Sub Total</td>
                                  <td style={{ color: '#000' }}>:</td>
                                  <td className="text-right" style={{ color: '#000' }}>
                                    {pbill.subtotal}
                                  </td>
                                </tr>

                                {pbill.supplyplace !== 'State' ? (
                                  <tr>
                                    <td style={{ color: '#000' }}>IGST</td>
                                    <td style={{ color: '#000' }}>:</td>
                                    <td className="text-right" style={{ color: '#000' }}>
                                      {pbill.igst}
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    <tr>
                                      <td style={{ color: '#000' }}>CGST</td>
                                      <td style={{ color: '#000' }}>:</td>
                                      <td className="text-right" style={{ color: '#000' }}>
                                        {pbill.cgst}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ color: '#000' }}>SGST</td>
                                      <td style={{ color: '#000' }}>:</td>
                                      <td className="text-right" style={{ color: '#000' }}>
                                        {pbill.sgst}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                <tr>
                                  <td style={{ color: '#000' }}>Tax Amount</td>
                                  <td style={{ color: '#000' }}>:</td>
                                  <td className="text-right" style={{ color: '#000' }}>
                                    {pbill.taxamount}
                                  </td>
                                </tr>

                                {pbill.adjust !== '0.00' && pbill.adjust !== '0' && (
                                  <tr>
                                    <td style={{ color: '#000' }}>Adjustment</td>
                                    <td style={{ color: '#000' }}>:</td>
                                    <td className="text-right" style={{ color: '#000' }}>
                                      {pbill.adjust}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <hr style={{ backgroundColor: '#000000' }} />
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <th style={{ color: '#000' }}>
                                    Grand Total &nbsp;&nbsp;&nbsp;&nbsp;
                                  </th>
                                  <th style={{ color: '#000' }}>:</th>
                                  <th style={{ color: '#000', textAlign: 'right' }}>
                                    {pbill.grandtotal}
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                </div>

                <div ref={template3Ref} id="template3" className="printTemplates" 
                style={{ display: 'none', width: '70%',margin: '0 auto', }}>
                  <div className="my-5 page" size="A4">
                    <div id="printdiv3">
                      <div className="row">
                        <div
                          className=""
                          style={{
                            backgroundImage: 'linear-gradient(rgb(61, 68, 101), rgb(59, 68, 101))',
                            color: 'white',width: '96%',margin: '0 auto',
                          }}
                        >
                          <br />
                          <p style={{ fontSize: '4vh', textAlign: 'center',color: 'white' }}>PURCHASE BILL</p>
                          <p style={{ textAlign: 'center',color: 'white' }}>
                            {otherDetails.Company_name} <br />
                            {otherDetails.Address} <br />
                            {otherDetails.City}, {otherDetails.State}
                            <br />
                            {otherDetails.Email}
                            <br />
                          </p>
                        </div>

                        <div className="row col-12">
                          <div className="col-md-1"></div>
                          <div className="col-5">
                            <br />
                            <br />
                            <br />
                            <p style={{ color: 'black' }}>
                              <span style={{ fontWeight: 'bold' }}>To: </span>
                              <br />
                              {otherDetails.PartyName}
                              <br />
                              {otherDetails.PartyAddress}
                            </p>
                          </div>
                          <div className="col-md-2"></div>
                          <div className="col-4">
                            <br />
                            <br />
                            <br />
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <td style={{ color: '#000', fontWeight: 'bold' }}>Bill No :</td>
                                  <td style={{ color: '#000', textAlign: 'left' }}>{pbill.billno}</td>
                                </tr>
                                <tr>
                                  <td style={{ color: '#000', fontWeight: 'bold' }}>Bill Date :</td>
                                  <td style={{ color: '#000', textAlign: 'left' }}>{new Date(pbill.billdate).toLocaleDateString()}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                          <br />
                          <table
                            className="table table-hover table-bordered template3table"
                            id="table3"
                            style={{ border: '1px solid black' }}
                          >
                            <thead>
                              <tr className="template3tablehead" style={{ backgroundColor: '#3dd9eb' }}>
                                <th className="text-center" style={{ color: 'black' }}>Items</th>
                                <th className="text-center" style={{ color: 'black' }}>HSN/SAC</th>
                                <th className="text-center" style={{ color: 'black' }}>Quantity</th>
                                <th className="text-center" style={{ color: 'black' }}>Rate</th>
                                <th className="text-center" style={{ color: 'black' }}>Tax</th>
                                <th className="text-center" style={{ color: 'black' }}>Discount</th>
                                <th className="text-center" style={{ color: 'black' }}>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pitm &&
                                  pitm.map((i) => (
                                <tr key={i.id}>
                                  <td className="text-center" style={{ color: 'black' }}>{i.name}</td>
                                  <td className="text-center" style={{ color: 'black' }}>
                                    {i.item_type == "Goods" ? i.hsn : i.sac}
                                  </td>
                                  <td className="text-center" style={{ color: 'black' }}>{i.quantity}</td>
                                  <td className="text-center" style={{ color: 'black' }}>{i.Purchase_price}</td>
                                  <td className="text-center" style={{ color: 'black' }}>
                                    {pbill.supplyplace === 'State' ? i.item_gst : i.item_igst}
                                  </td>
                                  <td className="text-center" style={{ color: 'black' }}>{i.discount}</td>
                                  <td className="text-center" style={{ color: 'black' }}>{i.total}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <section className="balance-info">
                        <div className="row">
                          <div className="col-md-7"></div>
                          <div className="col-md-4">
                            <br />
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <td style={{ color: '#000' }}>Sub Total</td>
                                  <td style={{ color: '#000' }}>:</td>
                                  <td className="text-right" style={{ color: '#000' }}>{pbill.subtotal}</td>
                                </tr>
                                {pbill.supplyplace !== 'State' ? (
                                  <tr>
                                    <td style={{ color: '#000' }}>IGST</td>
                                    <td style={{ color: '#000' }}>:</td>
                                    <td className="text-right" style={{ color: '#000' }}>{pbill.igst}</td>
                                  </tr>
                                ) : (
                                  <>
                                    <tr>
                                      <td style={{ color: '#000' }}>CGST</td>
                                      <td style={{ color: '#000' }}>:</td>
                                      <td className="text-right" style={{ color: '#000' }}>{pbill.cgst}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ color: '#000' }}>SGST</td>
                                      <td style={{ color: '#000' }}>:</td>
                                      <td className="text-right" style={{ color: '#000' }}>{pbill.sgst}</td>
                                    </tr>
                                  </>
                                )}
                                <tr>
                                  <td style={{ color: '#000' }}>Tax Amount</td>
                                  <td style={{ color: '#000' }}>:</td>
                                  <td className="text-right" style={{ color: '#000' }}>{pbill.taxamount}</td>
                                </tr>
                                {pbill.adjust !== '0.00' && pbill.adjust !== '0' && (
                                  <tr>
                                    <td style={{ color: '#000' }}>Adjustment</td>
                                    <td style={{ color: '#000' }}>:</td>
                                    <td className="text-right" style={{ color: '#000' }}>{pbill.adjust}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <hr style={{ backgroundColor: '#000000' }} />
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <th style={{ color: '#000' }}>Grand Total</th>
                                  <th style={{ color: '#000' }}>:</th>
                                  <th style={{ color: '#000', textAlign: 'right' }}>{pbill.grandtotal}</th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                </div>
                
               



                    <div
                      id="templateToggle"
                      className="templateToggleSegment mt-3 mb-0 w-100 justify-content-center"
                    >
                      <button
                        ref={templateButton1Ref}
                        id="template1Button"
                        onClick={() => toggleTemplate('template1', 'template1Button')}
                        className="btn btn-sm m-2 active templateToggleButtons"
                        style={{ backgroundColor: 'rgb(61, 68, 101)', color: 'white' }}
                      >
                        1
                      </button>
                      <button
                        ref={templateButton2Ref}
                        id="template2Button"
                        onClick={() => toggleTemplate('template2', 'template2Button')}
                        className="btn btn-sm m-2 templateToggleButtons"
                        style={{ backgroundColor: 'rgb(61, 68, 101)', color: 'white' }}
                      >
                        2
                      </button>
                      <button
                        ref={templateButton3Ref}
                        id="template3Button"
                        onClick={() => toggleTemplate('template3', 'template3Button')}
                        className="btn btn-sm m-2 templateToggleButtons"
                        style={{ backgroundColor: 'rgb(61, 68, 101)', color: 'white' }}
                      >
                        3
                      </button>
                    </div>
                  </div>


                    <div
                      id="slipdiv"
                      style={{ display: templateVisible ? 'none' : 'block' }}
                    >
                      <div className="my-5 page" style={{ width: "fit-content" }} id="slip_container">
                        <div
                          className="row ml-1"
                          style={{ marginTop: "20%", width: "98%" }}
                          id="printslip"
                        >    
                          <div className="col-md-3"></div>

                          <div
                            className="card bg-white"
                            style={{
                              border: "1px dotted black",
                              boxShadow: "2px 2px 10px 3px rgba(0, 0, 0, 0.397)",
                              width: "100%",margin: '0 auto'
                            }}
                          >
                            <div className="mt-2" style={{ textAlign: "center" }}>
                              <div
                                className="text-center"
                                style={{ fontSize: "3vh", color: "black" }}
                              >
                                <b>{otherDetails.Company_name}</b>
                              </div>
                              <span style={{ fontSize: "x-small", color: "black" }}>
                                {otherDetails.Address} <br />
                                {otherDetails.City}, {pbill.State}{" "}
                                {otherDetails.Pincode} <br />
                                {otherDetails.Email} <br />
                                {otherDetails.Contact}
                              </span>
                            </div>
                            <div className="ml-4 mt-2" style={{ color: "black" }}>
                              <div className="row">
                                <div className="col-md-6 text-left">Bill No:</div>
                                <div className="col-md-5 text-right">{pbill.billno}</div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 text-left">Party Name:</div>
                                <div className="col-md-5 text-right">
                                  {otherDetails.PartyName}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 text-left">Bill Date :</div>
                                <div className="col-md-5 text-right">
                                  {new Date(pbill.billdate).toLocaleDateString("en-GB")}
                                </div>
                              </div>
                            </div>
                            <div>
                              <br />
                              <div
                                className="equal-length-container"
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                <div
                                  className="equal-length-item"
                                  style={{ textAlign: "center" }}
                                >
                                  Item
                                  <hr
                                    style={{
                                      borderBottom: "1px solid black",
                                      marginTop: "1vh",
                                      width: "95%",
                                    }}
                                  />
                                </div>
                                <div
                                  className="equal-length-item ml-2"
                                  style={{ textAlign: "center" }}
                                >
                                  HSN
                                  <hr
                                    style={{
                                      borderBottom: "1px solid black",
                                      marginTop: "1vh",
                                      width: "63%",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </div>
                                <div
                                  className="equal-length-item"
                                  style={{ textAlign: "center" }}
                                >
                                  Qty
                                  <hr
                                    style={{
                                      borderBottom: "1px solid black",
                                      marginTop: "1vh",
                                      width: "60%",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </div>
                                <div
                                  className="equal-length-item"
                                  style={{ textAlign: "center" }}
                                >
                                  Price
                                  <hr
                                    style={{
                                      borderBottom: "1px solid black",
                                      marginTop: "1vh",
                                      width: "65%",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </div>
                                <div
                                  className="equal-length-item"
                                  style={{ textAlign: "center" }}
                                >
                                  Tax
                                  <hr
                                    style={{
                                      borderBottom: "1px solid black",
                                      marginTop: "1vh",
                                      width: "60%",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </div>
                                <div
                                  className="equal-length-item"
                                  style={{ textAlign: "center" }}
                                >
                                  Discount
                                  <hr
                                    style={{
                                      borderBottom: "1px solid black",
                                      marginTop: "1vh",
                                      width: "60%",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </div>
                                <div
                                  className="equal-length-item"
                                  style={{ textAlign: "center" }}
                                >
                                  Total
                                  <hr
                                    style={{
                                      borderBottom: "1px solid black",
                                      marginTop: "1vh",
                                      width: "65%",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              {pitm &&
                                  pitm.map((i) => (
                                <div
                                  key={i.id}
                                  className="equal-length-container"
                                  style={{
                                    color: "black",
                                    fontSize: "small",
                                    wordWrap: "break-word",
                                    marginBottom: "1vh",
                                  }}
                                >
                                  <div
                                    className="equal-length-item"
                                    style={{ textAlign: "center", marginLeft: "2px" }}
                                  >
                                    {i.name}
                                  </div>
                                  <div
                                    className="equal-length-item"
                                    style={{ textAlign: "right" }}
                                  >
                                     {i.item_type == "Goods" ? i.hsn : i.sac}
                                  </div>
                                  <div
                                    className="equal-length-item"
                                    style={{ textAlign: "center" }}
                                  >
                                    {i.quantity}
                                  </div>
                                  <div
                                    className="equal-length-item"
                                    style={{ textAlign: "center" }}
                                  >
                                    {i.Purchase_price}
                                  </div>
                                  
                                  <div
                                    className="equal-length-item"
                                    style={{ textAlign: "center" }}
                                  >
                                    {pbill.supplyplace === 'State' ? i.item_gst : i.item_igst}
                                  </div>

                                  <div
                                    className="equal-length-item"
                                    style={{ textAlign: "center" }}
                                  >
                                    {i.discount}
                                  </div>
                                 
                                  <div
                                    className="equal-length-item"
                                    style={{ textAlign: "center" }}
                                  >
                                    {i.total}
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="mt-3">
                              <br />
                              {/* {dis !== 0 && (
                                <div className="row ml-1">
                                  <div className="col-md-5 text-left" style={{ color: "black" }}>
                                    Discount
                                  </div>
                                  <div
                                    className="col-md-6 text-right ml-2"
                                    style={{ color: "black" }}
                                  >
                                    {pbill.discount}
                                  </div>
                                </div>
                              )} */}
                              <div className="row ml-1">
                                <div className="col-md-5 text-left" style={{ color: "black" }}>
                                  Subtotal
                                </div>
                                <div className="col-md-6 text-right ml-2" style={{ color: "black" }}>
                                  {pbill.subtotal}
                                </div>
                              </div>
                              {pbill.supplyplace !== "State" ? (
                                <div className="row ml-1">
                                  <div className="col-md-5 text-left" style={{ color: "black" }}>
                                    IGST
                                  </div>
                                  <div
                                    className="col-md-6 text-right ml-2"
                                    style={{ color: "black" }}
                                  >
                                    {pbill.igst}
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="row ml-1">
                                    <div className="col-md-5 text-left" style={{ color: "black" }}>
                                      CGST
                                    </div>
                                    <div
                                      className="col-md-6 text-right ml-2"
                                      style={{ color: "black" }}
                                    >
                                      {pbill.cgst}
                                    </div>
                                  </div>
                                  <div className="row ml-1">
                                    <div className="col-md-5 text-left" style={{ color: "black" }}>
                                      SGST
                                    </div>
                                    <div
                                      className="col-md-6 text-right ml-2"
                                      style={{ color: "black" }}
                                    >
                                      {pbill.sgst}
                                    </div>
                                  </div>
                                </>
                              )}
                              {pbill.adjust !== "0.00" && pbill.adjust !== "0" && (
                                <div className="row ml-1">
                                  <div className="col-md-5 text-left" style={{ color: "black" }}>
                                    Adjust
                                  </div>
                                  <div
                                    className="col-md-6 text-right ml-2"
                                    style={{ color: "black" }}
                                  >
                                    {pbill.adjust}
                                  </div>
                                </div>
                              )}
                              <hr className="ml-2 mr-2" style={{color: 'black', borderBottom: '1px solid black'}} />
                              <div className="mb-2">
                              <div className="row">
                                <div
                                  className="col-md-5 text-left ml-2"
                                  style={{
                                    fontSize: "large",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Total
                                </div>
                                <div
                                  className="col-md-6 text-right ml-2"
                                  style={{
                                    fontSize: "large",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {pbill.grandtotal}
                                </div>
                              </div>
                            </div>

                            <div className="ml-2 mt-2">
                              <p>
                                Created by :  {otherDetails.createdBy}<br />
                                Printed by  {otherDetails.createdBy} On{" "}
                                <span id="dateTimeDisplay"></span>
                              </p>
                            </div>

                            </div>
                          </div>
                        </div>
                      </div>

                     
                      
                    </div>


                    



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



        </div>
          </div>
        </div>
        
      </div>
    </div>

   

    {/* EmailModal */}
    <div id="emailModal" className="modal" style={{ height: '125%' }}>
    <div className="modal-dialog">
      <div className="modal-content">
        <span  type="button" className="close" data-dismiss="modal" id="fileModalDismiss" aria-label="Close">&times;</span>
        <div className="modal-header" style={{ background: 'rgb(61, 68, 101)' }}>
          <h5
            className="m-3 text-uppercase"
            style={{ color: 'white' }}
            id="shareBillEmailLabel"
          >
            Share Purchase Bill Via Email
          </h5>
        </div>
        <div className="modal-body" style={{ background: 'rgb(61, 68, 101)' }}>
        <form
              onSubmit={handleShareEmail}
              method="post"
              className="needs-validation"
              id="share_to_email_form"
              autoComplete="off"
            >
          <div className="card p-3 m-3">
              <input type="hidden" name="csrfmiddlewaretoken" />
              <div className="form-group">
                <label1 htmlFor="emailIds">Email IDs</label1>
                <textarea
                  className="form-control text-dark"
                  name="email_ids"
                  id="emailIds"
                  rows="3"
                  value={emailIds}
                  onChange={(e) => setEmailIds(e.target.value)}
                  placeholder="Multiple emails can be added by separating with a comma(,)."
                  required
                ></textarea>
              </div>
              <div className="form-group mt-2">
                <label1 htmlFor="item_unitname">Message(optional)</label1>
                <textarea
                  name="email_message"
                  id="email_message"
                  className="text-dark form-control"
                  cols=""
                  rows="2"
                  value={emailMessage}
                  onChange={(e) => setEmailMessage(e.target.value)}
                  placeholder="This message will be sent along with Bill details."
                ></textarea>
              </div>

          </div>
          <div
            className="modal-footer d-flex justify-content-center"
            style={{ borderTop: '2px solid #ffff' }}
          >
            <input
              type="submit"
              id="share_with_email"
              className="submitShareEmailBtn rounded-pill text-grey w-40 my-4 mx-3"
              value="SEND MAIL"
            />
          </div>
           </form>
        </div>
      </div>
    </div>
  </div>




 

  </>
  )
}

export default ViewBill




