import React from 'react';
import ChartComponent from './ChartComponent';

const AreaGraph = ({ chartData, chartOptions }) => {
  return (
    <div>

      <ChartComponent
        chartType="line"
        chartData={chartData}
        chartOptions={chartOptions}
      />
    </div>
  );
};

export default AreaGraph;
