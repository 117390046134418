import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import axios from "axios";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import config from "../../../functions/config";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePollVertical, faFileImport, faFileExcel, faPrint } from '@fortawesome/free-solid-svg-icons';

function Bill() {
  const ID = Cookies.get("user_id");
  const navigate = useNavigate();
  const [bill, setBill] = useState([]);
  const [history, setHistory] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const fetchBill = async () => {
    try {
      const response = await axios.get(`${config.base_url}/fetch_purchasebill/${ID}/`);
      console.log("BILL RESPONSE =", response);

      if (response.data.status) {
        setBill(response.data.purchasebill || []); 
        setHistory(response.data.history || null);
        setTotalAmount(response.data.Total_amount || 0); 
      } else {
        console.error("Error fetching data: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching bill:", error);
    }
  };

  useEffect(() => {
    fetchBill();
  }, []);

  const refreshAll = () => {
    fetchBill(); // Directly re-fetch without clearing state unnecessarily
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  

  function handleDeleteBill(id) {
    Swal.fire({
      title: `Delete Bill`,
      text: "Data cannot be restored!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_purchasebill/${id}/`)
          .then((res) => {
            console.log(res);
  
            Toast.fire({
              icon: "success",
              title: "Bill Deleted.",
            });
  
            // Refresh the data immediately after deletion
            fetchBill(); // Re-fetch data to ensure the table reflects updates
          })
          .catch((err) => {
            console.error("Error deleting bill:", err);
  
            Toast.fire({
              icon: "error",
              title: "Failed to delete bill.",
            });
          });
      }
    });
  }
  
  
  function searchTable(){
    var rows = document.querySelectorAll('#PurchasebillTable tbody tr');
    var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
    calculateTotal();
  }

  function exportToExcel() {
    const Table = document.getElementById("PurchasebillTable1");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "PurchaseBill.xlsx");
  }


  function printSection(sectionId) {
    var styles = `
    .custom-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      }

      .custom-table th,
      .custom-table td {
      border: 1px solid #ddd;
      padding: 8px; 
      text-align: left; 
      }

      .custom-table th {
      background-color: #f2f2f2; 
      color: #333; 
      font-weight: bold; 
      }

      .dropdown-content {
        display: none;
      }
  
      .Demo {
        display: none;
      }
       
    `;
    var divToPrint = document.getElementById(sectionId);
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write("<style>");
    printWindow.document.write(styles);
    printWindow.document.write("</style>");
    printWindow.document.write(divToPrint.innerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }


  function toggleDropdown(index) {
    var dropdownContent = document.getElementById("dropdownContent" + index);
    var isVisible = dropdownContent.style.display === "block";
    var allDropdowns = document.querySelectorAll(".dropdown-content");
    allDropdowns.forEach(function (dropdown) {
      dropdown.style.display = "none";
    });
    dropdownContent.style.display = isVisible ? "none" : "block";
  }

  function clearDropdown(columnIndex) {
    document.getElementById("filterInput" + columnIndex).value = "";
    filterTable(columnIndex);
  }

  function filterTable(index) {
    var input;
    document.getElementById("dropdownContent" + index).style.display = "none";
    if (index === 1) {
      input = document.getElementById("filterInput" + index).value;
    } else {
      input = document
        .getElementById("filterInput" + index)
        .value.toUpperCase();
    }
    var table = document.getElementById("PurchasebillTable");
    var table2 = document.getElementById("sales_reports_table_pdf");
    var rows = table.getElementsByTagName("tr");
    var rows2 = table.getElementsByTagName("tr");
    var isEmpty = true;
    for (var i = 1; i < rows.length; i++) {
      var cells = rows[i].getElementsByTagName("td");
      var cell = cells[index];
      if (cell) {
        var textValue = cell.textContent || cell.innerText;
        if (index === 0) {
          if (input == "") {
            rows[i].style.display = "table-row";
            isEmpty = false;
          } else {
            var dateval = textValue.replace(
              /(\d{2})-(\d{2})-(\d{2})/,
              "$2-$1-$3"
            );
            var inputval = input.replace(/(\d{2})-(\d{2})-(\d{2})/, "$2-$1-$3");
            const formattedDate = formatDateString(input);
            if (dateval === formattedDate) {
              rows[i].style.display = "table-row";
              isEmpty = false;
            } else {
              rows[i].style.display = "none";
            }
          }
        } else {
          if (textValue.toUpperCase().includes(input)) {
            rows[i].style.display = "table-row";
            isEmpty = false;
          } else {
            rows[i].style.display = "none";
          }
        }
      }
    }

    for (var i = 1; i < rows2.length; i++) {
      var cells = rows2[i].getElementsByTagName("td");
      var cell = cells[index];
      if (cell) {
        var textValue = cell.textContent || cell.innerText;
        if (index === 0) {
          if (input == "") {
            rows2[i].style.display = "table-row";
            isEmpty = false;
          } else {
            var dateval = textValue.replace(
              /(\d{2})-(\d{2})-(\d{2})/,
              "$2-$1-$3"
            );
            var inputval = input.replace(/(\d{2})-(\d{2})-(\d{2})/, "$2-$1-$3");
            const formattedDate = formatDateString(input);
            if (dateval === formattedDate) {
              rows2[i].style.display = "table-row";
              isEmpty = false;
            } else {
              rows2[i].style.display = "none";
            }
          }
        } else {
          if (textValue.toUpperCase().includes(input)) {
            rows2[i].style.display = "table-row";
            isEmpty = false;
          } else {
            rows2[i].style.display = "none";
          }
        }
      }
    }
    calculateTotal();
  }


  function formatDateString(inputDate) {
    const parsedDate = new Date(inputDate);
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1;
    const year = parsedDate.getFullYear();
    const formattedDate = `${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}-${year}`;
    return formattedDate;
  }

  
  function filterDates() {
    const fromDate = document.getElementById("fromDate").value;
    const selectedDate = new Date(fromDate);
    selectedDate.setDate(selectedDate.getDate() - 1);
    const toDate = document.getElementById("toDate").value;
    const table = document.getElementById("PurchasebillTable");
    const rows = table ? table.getElementsByTagName("tr") : [];
    const emptyMessage = document.getElementById("emptyMessage");
    let isEmpty = true;
  
    for (let i = 1; i < rows.length; i++) {
      const currentRow = rows[i];
      const dateCell = currentRow?.getElementsByTagName("td")[0];
  
      if (!dateCell) {
        continue; // Skip if the row or date cell is invalid
      }
  
      const currentDate = dateCell.innerText;
      const date = new Date(
        currentDate.replace(/(\d{2})-(\d{2})-(\d{2})/, "$2/$1/$3")
      );
  
      if ((fromDate && date >= selectedDate) || !fromDate) {
        if ((toDate && date <= new Date(toDate)) || !toDate) {
          currentRow.style.display = "table-row";
          isEmpty = false;
        } else {
          currentRow.style.display = "none";
        }
      } else {
        currentRow.style.display = "none";
      }
    }
    if (emptyMessage) {
      emptyMessage.style.display = isEmpty ? "block" : "none";
    }
    calculateTotal();
  }
  

  function formatDateToDDMMYYYY(dateString) {
    if (!dateString) return ""; // Handle empty or null dates
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }
  

  

  useEffect(() => {
    calculateTotal();
  }, []);

  const calculateTotal = () => {
    const table = document.getElementById("PurchasebillTable");
    const rows = table?.getElementsByTagName("tr") || [];
    let ttotal = 0;

    for (let i = 1; i < rows.length; i++) {
      if (rows[i].style.display !== "none") {
        const tcells = rows[i].getElementsByTagName("td")[3];
        const ttextValue = tcells?.textContent || tcells?.innerText || "0";
        ttotal += parseFloat(ttextValue);
      }
    }

    setTotalAmount(parseFloat(ttotal));
  };




  const handleDownload = async () => {
    try {
      // Make API request
      const response = await axios.get(`${config.base_url}/download-bill-sample/`);

      // Extract file data from response
      const { file_name, file_content } = response.data;

      // Decode Base64 string to binary
      const binaryData = atob(file_content);
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }

      // Create a Blob and trigger download
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = file_name;
      link.click();
    } catch (error) {
      console.error("Error downloading the file:", error);
      alert("Failed to download the file. Please try again.");
    }
  };


  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("excel_file", file);

    try {
      const response = await axios.post(
        `${config.base_url}/import-bill/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true, // Include session cookies if needed
        }
      );
      alert(response.data.message || "File imported successfully.");
    } catch (error) {
      console.error(error.response?.data || error.message);
      alert("Failed to import file. Please check the console for more details.");
    }
  };

  return (
       <>
      <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
      <Sidebar />
      <div className="main-panel px-3 py-2" style={{ background: "rgb(130, 144, 199)" }}> 
        <div className="content-wrapper">
          <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>



          <style>
        {`
          .button-hover {
            background-color: white;
            color: #3d4465;
          }
          .button-hover:hover {
            background-color: #4d5fbc;
            color: white;
          }

          .tg {
            color: rgb(0, 140, 7);
          }

          .tr {
            color: rgb(218, 0, 0);
          }

          .tj {
            color: #e4a11b;
          }

          .th {
            color: rgb(12, 79, 234);
          }


          .dropdown-content {
            z-index: 1;
            display: none;
            position: absolute;
            /* top: 40px; */
            /* left: 12px; */
            background-color: #b4b4b450;
            backdrop-filter: blur(5px);
            padding: 10px;
            border: 1px solid #3498db;
            width: fit-content;
            border-radius: 5px;
            box-shadow: 3px 5px 5px 5px rgba(0, 0, 0, 0.201);
          }

          .dropdown-content input {
            width: 100%;
            padding: 8px;
            margin-bottom: 10px;
            background-color: white;
            border-radius: 5px;
            border: 1px solid rgb(168, 168, 168);
          }

          .dropdown-content button {
            padding: 8px 15px;
            background-color: rgb(0, 154, 108);
            color: #fff;
            border: none;
            cursor: pointer;
            border-radius: 3px;
          }

          .custom-dropdown .dropdown-content .cls {
            position: absolute;
            /* right: -7px; */
            /* top: -7px; */
            padding: 3px 5px;
            color: white;
            text-shadow: 0px 0px 2px black;
            border-radius: 50%;
            font-size: 15px;
            background-color: rgba(255, 0, 0, 0.424);
          }
          .custom-dropdown .dropdown-content .title {
            background-color: rgb(255, 255, 255);
            font-weight: 500;
            color: rgb(0, 0, 0);
            text-shadow: 0px 0px 20px rgb(255, 255, 255);
            padding: 5px;
            border-radius: 5px;
          }
        `}
      </style>
      
    {bill.length === 0 ? (
      <div className="content-body">
      <div className="container-fluid">
        <div className="p-5 text-center" style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="card"
            style={{
              backgroundColor: "white",
              width: "90%",
              boxShadow: "5px 5px 10px 5px rgba(0, 0, 0, 0.249)",
            }}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <img
                  style={{
                    height: "300px", 
                    width: "100%", 
                    marginTop: "35px",
                  }}
                  src={`${process.env.PUBLIC_URL}/static/assets/img/b1.jpg`}
                  className="card-img"
                  alt="no img"
                />
              </div>
              <div className="col-sm-12 col-md-12 ps-4 pe-4">
                <div
                  className="p-1 rounded"
                  style={{ border: "1px solid rgba(0, 0, 0, 0)", borderBottom: "none" }}
                >
                  <p style={{ marginBottom: "4rem" }}></p>
                  <p></p>
                  <h1
                    style={{
                      fontWeight: 900,
                      color: "rgb(61, 68, 101)",
                      fontSize: "1.5rem",
                    }}
                  >
                    BILLING SOFTWARE
                  </h1>

                  <p style={{ marginBottom: "1.5rem" }}></p>
                  <p className="card-text" style={{ color: "black", fontWeight: "bold" }}>
                    Welcome to Billing software. We are supporting to purchase an Item. So first bill
                    to registration. Please click the button.
                  </p>
                  <p
                    style={{ color: "rgb(0, 89, 10)", fontWeight: "light" }}
                    className="card-text"
                  >
                    You can manage all you purchase bills here.
                  </p>
                  <p className="mt-4"></p>
                  <Link
                    className="btn btn-lg"
                    style={{ backgroundColor: "rgb(61, 68, 101)", fontWeight: "bold" }}
                    to="/add_purchasebill"
                  >
                    Add Your First Purchase Bill
                  </Link>
                  <p style={{ marginBottom: "4rem" }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    ) : (

    <main>
    <div className="card p-0 bs" style={{ width: "100%", backgroundColor: "#3d4465" }}>
      <div className="row" style={{ padding: "20px" }}>
        <div className="container" style={{ width: "100%" }}>
          <div className="row">

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="fromDate" style={{ color: "white", marginBottom: "1vh" }}>
                From Date:
              </label>
              <br></br><br></br><br></br>
              <input
                type="date"
                id="fromDate"
                className="form-control ms-1 form-control-sm"
                onChange={filterDates}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="toDate" style={{ color: "white", marginBottom: "1vh" }}>
                To Date:
              </label>
              <br></br><br></br><br></br>
              <input
                type="date"
                id="toDate"
                className="form-control form-control-sm"
                onChange={filterDates}
              />
            </div>
          </div>

            
            <div className="col-md-6 text-right">
            <br></br><br></br>
              <Link to="/purchasebill_graph">
                <button className="btn mt-3 button-hover ml-1" type="button">
                  <span
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontWeight: "bold",
                    }}
                  >
                    Graph&nbsp;
                  </span>
                  <span><FontAwesomeIcon icon={faSquarePollVertical} /></span>
                </button>
              </Link>
              <button
                className="btn mt-3 ml-1 button-hover"
                type="button"
                data-toggle="modal"
                data-target="#attachFileModal"       
              >
                <span
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontWeight: "bold",
                  }}
                >
                  Import&nbsp;
                </span>
                <span><FontAwesomeIcon icon={faFileImport} /></span>
              </button>
              <button
                className="btn mt-3 button-hover ml-1"
                type="button"
                id="exportBtn"
                onClick={exportToExcel}
              >
                <span
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontWeight: "bold",
                  }}
                >
                  Excel&nbsp;
                </span>
                <span><FontAwesomeIcon icon={faFileExcel} /></span>
              </button>
              <button
                className="btn mt-3 button-hover ml-1"
                type="button"
                onClick={() => printSection("report")}
              >
                <span
                  style={{
                    color: "black",
                    fontSize: "small",
                    fontWeight: "bold",
                  }}
                >
                  Print&nbsp;
                </span>
                <span><FontAwesomeIcon icon={faPrint} /></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br></br>


    <div className="card p-0 bs" style={{ width: '100%', background: 'linear-gradient(#3d4465 0%, #3d4465 178.5px, white 17px, white 100%)' }}>
            <div className="row m-0 p-0 pt-3">
                <div className="col-sm-12 col-lg-3">
                    <h4 className="tb fw-bolder pt-55" style={{ color: 'white' }}>
                        PURCHASE BILLS
                    </h4>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <input
                        style={{ paddingInlineStart: '2rem', position: 'relative' }}
                        className="form-control w-100 text-dark pt-1 pb-1 pe-1 ml-3"
                        type="text"
                        id="search"
                        placeholder="Search.."
                        autoComplete="off"
                        onKeyUp={searchTable}
                    />
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-4 button-div1 d-flex justify-content-center">
                <Link className="btn button-hover" to="/add_purchasebill">
                    <i className="fa fa-plus border-dark" aria-hidden="true"></i>
                    &nbsp;Add Purchase Bill
                </Link>
                </div>
            </div>
            <br />

            <div className="row" id="report">
                <div className="col-md-12 mt-1">
                    <div className="mt-3" style={{ width: '100%' }} id="tablediv">
                        <table className="table table-responsive" id="PurchasebillTable" style={{ textAlign: 'center', minHeight: '20vh' }}>
                            <thead>
                            <tr>
                                <th
                                    className="text-white"
                                    style={{
                                        backgroundColor: 'rgb(61, 68, 101)',
                                        paddingTop: '15px',
                                        width: '20vw',
                                    }}
                                >
                                    DATE
                                    <a onClick={() => toggleDropdown(0)}>
                                      &nbsp;
                                      <i
                                        className="fa fa-filter"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <div
                                      className="dropdown-content"
                                      id="dropdownContent0"
                                    >
                                      <label1
                                        htmlFor="filterInput0"
                                        style={{
                                          color: "black",
                                          textAlign: "left",
                                        }}
                                      >
                                        Equal To
                                      </label1>
                                      <input type="date" id="filterInput0" />
                                      <div className="button-row">
                                        <a
                                          className="btn"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => clearDropdown(0)}
                                        >
                                          Clear
                                        </a>
                                        <a
                                          className="btn ms-1"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => filterTable(0)}
                                        >
                                          Apply
                                        </a>
                                      </div>
                                    </div>
                                </th>
                                <th
                                    className="text-white"
                                    style={{
                                        backgroundColor: 'rgb(61, 68, 101)',
                                        paddingTop: '15px',
                                        width: '15vw',
                                    }}
                                >
                                    BILL NO
                                    <a onClick={() => toggleDropdown(1)}>
                                      &nbsp;
                                      <i
                                        className="fa fa-filter"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <div
                                      className="dropdown-content"
                                      id="dropdownContent1"
                                    >
                                      <label1
                                        htmlFor="filterInput1"
                                        style={{
                                          color: "black",
                                          textAlign: "left",
                                        }}
                                      >
                                        Equal To
                                      </label1>
                                      <input type="text" id="filterInput1" />
                                      <div className="button-row">
                                        <a
                                          className="btn"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => clearDropdown(1)}
                                        >
                                          Clear
                                        </a>
                                        &nbsp;
                                        <a
                                          className="btn ms-1"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => filterTable(1)}
                                        >
                                          Apply
                                        </a>
                                      </div>
                                    </div>
                                </th>
                                <th
                                    className="text-white"
                                    style={{
                                        backgroundColor: 'rgb(61, 68, 101)',
                                        paddingTop: '15px',
                                        width: '15vw',
                                    }}
                                >
                                    PARTY NAME
                                    <a onClick={() => toggleDropdown(2)}>
                                      &nbsp;
                                      <i
                                        className="fa fa-filter"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <div
                                      className="dropdown-content"
                                      id="dropdownContent2"
                                    >
                                      <label1
                                        htmlFor="filterInput2"
                                        style={{
                                          color: "black",
                                          textAlign: "left",
                                        }}
                                      >
                                        Equal To
                                      </label1>
                                      <input type="text" id="filterInput2" />
                                      <div className="button-row">
                                        <a
                                          className="btn"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => clearDropdown(2)}
                                        >
                                          Clear
                                        </a>
                                        &nbsp;
                                        <a
                                          className="btn ms-1"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => filterTable(2)}
                                        >
                                          Apply
                                        </a>
                                      </div>
                                    </div>
                                </th>
                                <th
                                    className="text-white"
                                    style={{
                                        backgroundColor: 'rgb(61, 68, 101)',
                                        paddingTop: '15px',
                                        width: '15vw',
                                    }}
                                >
                                    AMOUNT
                                    <a onClick={() => toggleDropdown(3)}>
                                      &nbsp;
                                      <i
                                        className="fa fa-filter"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <div
                                      className="dropdown-content"
                                      id="dropdownContent3"
                                    >
                                      <label1
                                        htmlFor="filterInput3"
                                        style={{
                                          color: "black",
                                          textAlign: "left",
                                        }}
                                      >
                                        Equal To
                                      </label1>
                                      <input type="text" id="filterInput3" />
                                      <div className="button-row">
                                        <a
                                          className="btn"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => clearDropdown(3)}
                                        >
                                          Clear
                                        </a>
                                        &nbsp;
                                        <a
                                          className="btn ms-1"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => filterTable(3)}
                                        >
                                          Apply
                                        </a>
                                      </div>
                                    </div>
                                </th>
                                <th
                                    className="text-white"
                                    style={{
                                        backgroundColor: 'rgb(61, 68, 101)',
                                        paddingTop: '15px',
                                        width: '15vw',
                                    }}
                                >
                                    ACTION
                                    <a onClick={() => toggleDropdown(4)}>
                                      &nbsp;
                                      <i
                                        className="fa fa-filter"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <div
                                      className="dropdown-content"
                                      id="dropdownContent4"
                                    >
                                      <label1
                                        htmlFor="filterInput4"
                                        style={{
                                          color: "black",
                                          textAlign: "left",
                                        }}
                                      >
                                        Equal To
                                      </label1>
                                      <input type="text" id="filterInput4" />
                                      <div className="button-row">
                                        <a
                                          className="btn"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => clearDropdown(4)}
                                        >
                                          Clear
                                        </a>
                                        &nbsp;
                                        <a
                                          className="btn ms-1"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => filterTable(4)}
                                        >
                                          Apply
                                        </a>
                                      </div>
                                    </div>
                                </th>
                                <th
                                    className="text-white"
                                    style={{
                                        backgroundColor: 'rgb(61, 68, 101)',
                                        paddingTop: '15px',
                                        width: '15vw',
                                    }}
                                >
                                    BY
                                    <a onClick={() => toggleDropdown(5)}>
                                      &nbsp;
                                      <i
                                        className="fa fa-filter"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <div
                                      className="dropdown-content"
                                      id="dropdownContent5"
                                    >
                                      <label1
                                        htmlFor="filterInput5"
                                        style={{
                                          color: "black",
                                          textAlign: "left",
                                        }}
                                      >
                                        Equal To
                                      </label1>
                                      <input type="text" id="filterInput5" />
                                      <div className="button-row">
                                        <a
                                          className="btn"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => clearDropdown(5)}
                                        >
                                          Clear
                                        </a>
                                        &nbsp;
                                        <a
                                          className="btn ms-1"
                                          style={{ backgroundColor: "rgb(61, 68, 101)" }}
                                          onClick={() => filterTable(5)}
                                        >
                                          Apply
                                        </a>
                                      </div>
                                    </div>
                                </th>


                                <th className="Demo text-white"
                                    style={{
                                        backgroundColor: 'rgb(61, 68, 101)',
                                        paddingTop: '15px',
                                        width: '6vw',
                                    }}
                                ></th>

                            </tr>
                            </thead>
                            <tbody>
                                
                            {bill &&bill.map((i,index)=>(
                              <tr
                                key={index} className="clickable-row"
                              >
                                {/* <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.bill_date }</td> */}
                                <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{formatDateToDDMMYYYY(i.bill_date)}</td>
                                <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.bill_no}</td>
                                <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.party_name}</td>
                                <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.grandtotal?.toFixed(2) || "0.00"}</td>
                                <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.action}</td>
                                <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.created_by}</td>
                              
                                <td className="Demo pt-3 m-0 extra">
                                  <div className="btn-group">
                                    <button
                                      className="btn dropdown-toggle"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <span className="fa fa-ellipsis-v text-dark ps-"></span>
                                    </button>
                                    <ul className="dropdown-menu">
                                      <li>
                                        <Link
                                          className="dropdown-item fw-bold tb hide_table_text_v"
                                          to={`/ViewBill/${i.id}`}
                                        >
                                          <span className="fa fa-eye pe-2 tg"></span> View
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          className="dropdown-item fw-bold tb hide_table_text_v"
                                          to={`/edit_purchasebill/${i.id}`}
                                        >
                                          <span className="fa fa-edit pe-2 tj"></span> Edit
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          className="dropdown-item fw-bold tb hide_table_text_v"
                                          to={`/history_purchasebill/${i.id}`}
                                        >
                                          <span className="fa fa-history pe-2 th"></span> History
                                        </Link>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item fw-bold tb hide_table_text_d"
                                          onClick={() =>
                                            handleDeleteBill(`${i.id}`)
                                          }
                                        >
                                          <span
                                            className="fa-solid fa-trash"
                                            style={{
                                              paddingInlineStart: '0.1rem',
                                              paddingInlineEnd: '0.7rem',
                                              color: 'red',
                                            }}
                                          ></span>
                                          Delete
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            ))}
                            </tbody>
                            <br></br><br></br><br></br><br></br>
                        </table>

                        <div className="ml-3 d-flex justify-content-start align-items-center">
                          <h3 style={{ fontWeight: "bold" }} id="totalval">Total Amount: {totalAmount.toFixed(2)}</h3>
                          <h4 id="totalval" className="ml-2" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </main>
  )}


            </div>
          </div>
        </div>
      </div>
    </div>

   
    {/* <!-- Attach File Modal --> */}
    <div className="modal fade" id="attachFileModal">
    <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-body rounded-1" style={{ backgroundColor: '#3d4465' }}>
            <div className="d-flex justify-content-between">
              <h3 className="m-3 text-white">IMPORT BILL</h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                id="fileModalDismiss"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="file_instructions">
              <div className="alert bg-light text-dark" role="alert">
                <span className="fw-bolder">IMPORTANT:</span>
                <p>
                  File should have 2 sheets named 'bill' & 'items' and column
                  name & order should be in the given format (Uppercase).
                </p>
                <p className="fw-bolder">
                  Bill sheet - SLNO*, DATE, NAME*, STATE OF SUPPLY*, SUB TOTAL*, IGST*, CGST*, SGST*, TAX AMOUNT*,
                  ADJUSTMENT, GRAND TOTAL*
                </p>
                <p className="fw-bolder">
                  Items sheet - BILL NO**, NAME*, HSN*, QUANTITY*, PRICE*, TAX
                  PERCENTAGE*, DISCOUNT, TOTAL*
                </p>
                <p>* All required columns should be filled.</p>
                <p>** <b>BILL NO</b> is <b>SLNO</b> of bill</p>
                <p>
                  State of supply should be '<b>State</b>' or '<b>Other State</b>'.
                  State - CGST* & SGST*, Other State - IGST*
                </p>
                <p>Date format should be 'YYYY-MM-DD' or 'DD-MM-YYYY'.</p>
              </div>
            </div>
            <div className="sample_file mb-2">
              <a
                onClick={handleDownload}
                className="fw-bolder btn btn-sm btn-info bg-gradient"
              >
                Download Sample File
              </a>
            </div>
            <form onSubmit={handleSubmit} className="form">
            <div className="form-group">
              <label1 htmlFor="excel_file" className="form-label">
                File
              </label1>
              <input
                id="excel_file"
                className="form-control"
                type="file"
                onChange={handleFileChange}
                required
              />
            </div>
            <div className="d-flex justify-content-center mt-2">
              <button
                id="submit_import_excel"
                className="btn btn-info bg-gradient"
                type="submit"
              >
                IMPORT
              </button>
            </div>
          </form>
          </div>
        </div>
      </div>
      </div>

    
      {/* Excel */}
      <table className="table table-responsive" id="PurchasebillTable1" style={{ display: "none" }}>
          <thead>
          <tr>
              <th>DATE</th>
              <th>BILL NO</th>
              <th>PARTY NAME</th>
              <th>AMOUNT</th>
              <th>ACTION</th>
              <th>BY</th>
          </tr>
          </thead>
          <tbody>
          {bill &&bill.map((i,index)=>(
            <tr
              key={index} className="clickable-row"
            >
              <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{formatDateToDDMMYYYY(i.bill_date)}</td>
              <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.bill_no}</td>
              <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.party_name}</td>
              <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.grandtotal?.toFixed(2) || "0.00"}</td>
              <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.action}</td>
              <td onClick={() => navigate(`/ViewBill/${i.id}/`)} style={{ cursor: "pointer" }}>{i.created_by}</td>
            </tr>
          ))}  
          </tbody>
      </table>

    </>
  )
}

export default Bill

