import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import config from '../../../functions/config';
import axios from 'axios';
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from 'react-router-dom';
import './AddItem.css'
import { toast } from 'react-toastify';


export default function UpdateItem() {
    const [isTaxable, setIsTaxable] = useState(false);
    const [activeTab, setActiveTab] = useState('pricing');

    const[itemName,setItemName] = useState('')
    const[hsn,setHsn] = useState('')
    const[sac,setSac] = useState('')
    const[itemType,setItemType] = useState('')
    const[unit,setUnit] = useState('')
    const[gst,setGst] = useState('')
    const[igst,setIgst] = useState('')
    const[salesPrice,setSalesPrice] = useState(0)
    const[nonTaxableSalePrice,setnonTaxableSalePrice] = useState(0)  
    const[nonTaxablePurchasePrice,setnonTaxablePurchasePrice] = useState(0) 
    const[purchasePrice,setPurchasePrice] = useState(0)
    const[atPrice,setAtprice] = useState(0)
    const[stockInHand,setStockInHand] = useState(0)
    const[minStockMaintain,setminStockMaintain] = useState(0)
    const[date,setDate] = useState('')

    const {id} = useParams()

    const [allItems,setAllItems] = useState([])
    const [addunit,setAddUnit] = useState('')
    const [allUnit,setAllUnit] = useState([])
    const [count,setCount] = useState(0)
    const [valid,setValid] = useState(false)
    const [errors, setErrors] = useState({
        itemName: "",
        hsn: "",
        sac: ""
    });


    const user = Cookies.get("role");
    var is_company = false;
    if (user === "Company") {
      is_company = true;
    }
    const ID = Cookies.get("user_id");
    
    const [loginName, setLoginName] = useState("");
    const [loginImage, setLoginImage] = useState("");
  
    const getLogDetails = () => {
      axios
        .get(`${config.base_url}/user/${ID}/`)
        .then((res) => {
          if (res.data.status) {
            const details = res.data.data;
            var logImg = null;
            if (details.image) {
              logImg = `${config.base_url}${details.image}`;
            }
            setLoginImage(logImg);
            setLoginName(details.name);
          }
        })
        .catch((err) => {
          console.log("ERROR==", err);
        });
    };
  
    useEffect(() => {
      getLogDetails();
    }, []);
    

    useEffect(() => {
        async function fetchdata() {
          try {
            
            const response = await axios.get(
              `${config.base_url}all_items`,
              {
                headers: {
                  "Content-Type": "application/json",
                }
              }
            );
            if (response.data.status === 200) {
              console.log(response);
              setAllItems(response.data.data.filter(item=>item.user == ID));
            } else {
              console.log(response);
            }
          } catch (error) {
            console.log(error);
          }
        }
        fetchdata();
      }, [config.base_url,count]);
   
    useEffect(()=>{
        async function fetchdata(){
          try{
            const res = await axios.get(`${config.base_url}single_items/${id}`,{
              headers:{
                'Content-Type':'application/json',
              }, 
            });
            if(res.data.status===200){
              
              console.log(res)
              setItemType(res.data.data.item_type)
              setItemName(res.data.data.item_name)
              setHsn(res.data.data.item_hsn)
              setSac(res.data.data.item_sac)
              setUnit(res.data.data.item_unit)
              setSalesPrice(res.data.data.item_sale_price)
              setPurchasePrice(res.data.data.item_purchase_price)
              setnonTaxableSalePrice(res.data.data.item_sale_price)
              setnonTaxablePurchasePrice(res.data.data.item_purchase_price)
              setGst(res.data.data.item_gst)
              setIgst(res.data.data.item_igst)
              setStockInHand(res.data.data.item_stock_in_hand)
              setAtprice(res.data.data.item_at_price)
              setDate(res.data.data.item_date)
              setminStockMaintain(res.data.data.item_min_stock_maintain)
              
              
            } 
            else{
              console.log(res)
            }
            
          } catch (err) {
            console.log("error2",err)
          }
        }
        fetchdata();
      },[config.base_url,id,count])

      useEffect(() => {
        async function fetchdata() {
          try {
            
            const response = await axios.get(
              `${config.base_url}all_unit`,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (response.data.status === 200) {
              console.log(response);
              setAllUnit(response.data.data);
            } else {
              console.log(response);
            }
          } catch (error) {
            console.log(error);
          }
        }
        fetchdata();
      }, [config.base_url,count]);

    // validations
    const validateItemName = (value) => {
        if (!value.trim()) return "Item name is required.";
        if (allItems.find(item=>item.item_name===value)) {
            setValid(true)
            return (
            <p style={{width:'65%'}} className='text-danger'>Item Name already exist. Choose another name</p>
            );
        } 
        setValid(false)
        return (
            <p className='text-success'>Valid</p>
        ); // Returning string "Valid" for successful validation
    };
    
    const validateHsn = (value) => {
        if (!value.trim()) return "HSN is required.";
        if (!/^\d{6,}$/.test(value)) return "HSN must be 6 digits.";
        if (allItems.find(item=>item.item_hsn==value)){
            setValid(true)
            return "hsn already exist"
        } 
        setValid(false)
        return (
            <p className='text-success'>Valid</p>
        ); // Return an empty string if validation is successful
    };
    
    const validateSac = (value) => {
        if (!value.trim()) return "SAC is required.";
        if (!/^\d{6,}$/.test(value)) return "SAC must be 6 digits.";
        if (allItems.find(item=>item.item_sac==value)){
            setValid(true)
            return "SAC already exist"
        } 
        setValid(false)
        return (
            <p className='text-success'>Valid</p>
        );;
    };
    
    const handleItemNameChange = (value) => {
        setItemName(value);
        setErrors((prev) => ({ ...prev, itemName: validateItemName(value) }));
    };
    
    const handleHsnChange = (value) => {
        setHsn(value);
        setErrors((prev) => ({ ...prev, hsn: validateHsn(value) }));
    };
    
    const handleSacChange = (value) => {
        setSac(value);
        setErrors((prev) => ({ ...prev, sac: validateSac(value) }));
    };
    

   
   
    const user_id = ID

    const handleAddUnit = async (e, id) => {
      e.preventDefault();
      const data = {
        unit_name: addunit,
        user: user_id,
      };
      try {
        const response = await axios.post(`${config.base_url}add_unit`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response);
        if (response.data.status === 200) {
          setCount(id); // To refresh units list if needed
          setUnit(addunit); // Automatically select the new unit
          setAddUnit(""); // Clear the input field
          toast.success("Unit Added Successfully!", {
            autoClose: 1500,
            position: "top-right",
          });
        } else {
          console.log("error1");
          toast.error("Fill the required Fields", {
            autoClose: 1500,
            position: "top-right",
          });
        }
      } catch (err) {
        console.log("error2", err);
        toast.error("Error", {
          autoClose: 2000,
          position: "top-right",
        });
      }
    };
    
   

    const handleSave = async(e)=>{
        if(valid===false){
            e.preventDefault();
            
            
        const data={
            item_name: itemName,
            item_hsn: hsn,
            item_sac:sac,
            done_by_name:user_id,
            item_type: itemType,
            item_unit: unit,
            item_gst: gst,
            item_igst: igst,
            item_sale_price: isTaxable ? salesPrice : nonTaxableSalePrice, // Use conditional value
            item_purchase_price: isTaxable ? purchasePrice : nonTaxablePurchasePrice, // Use conditional value
            item_stock_in_hand: stockInHand,
            item_at_price: atPrice,
            item_min_stock_maintain: minStockMaintain,
            item_date: date,
            company_id:user_id,
            staff_id:user_id
        }
        try {
            const response = await axios.put(`${config.base_url}update_item/${id}`,data,{
              headers:{
                'Content-Type':'multipart/form-data',
              }
            });
            console.log(response)
            if(response.data.status===200){
              setCount(id)
              navigate('/item-list')
              console.log(response)
                
              toast.success("Item Updated  Successfully!",{
                autoClose:1000,
                position:'top-right',
              });
            }
            else{
              console.log("error1")
              toast.error("Fill the required Fields",{
                autoClose:1500,
                position: "top-right",
              })
            }
            
          } catch (err) {
            console.log("error2",err)
            toast.error("Item cannot be added",{
              autoClose:1000,
              position: "top-right",
            })
          }
        }else{
            console.log("error3")
            toast.error("Item not added",{
              autoClose:1000,
              position: "top-right",
            })

        }
        
    }

   


    const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("COMPANY NAME");
  

 
  const [panelWidth, setPanelWidth] = useState(window.innerWidth > 768 ? "90%" : "100%");

  useEffect(() => {
    const handleResize = () => setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        
        <div
        className="main-panel px-3 py-2"
        style={{ background: "#a9a9a961", width: panelWidth }}
        >
          
          <section  className='add-main-section '>
                
           <div className='rounded shadow-lg d-flex justify-content-between'>
                <h2 className='text-white'>EDIT ITEM</h2>
                <Link to='/item-list'><button style={{color:'#3d4465'}} className='btn fw-bold btn-light'><i className='fa-solid fa-beat fa-arrow-left'></i> Back</button></Link>
           </div>

           <div  className='div-2 bg-white p-4 mt-4'>
            {/* input fields */}
            <form className='input-field'>
                    <div>
                        <p style={{color:'#3d4465'}} className=''>Item Type :</p>
                        <select value={itemType}  onChange={(e)=>setItemType(e.target.value)} name="item-type" id=""  className='item-input text-black border-1 border-black bg-light form-control text-black '>
                            
                        <option value="" disabled selected>Select Type</option>
                            <option value="Goods">Goods</option>
                            <option value="Service">Service</option>
                           
                        </select>
                    </div>
                    <div>
                    <p style={{color:'#3d4465'}} className=''>Item Name :</p>
                    <input value={itemName} 
                    onChange={(e) => handleItemNameChange(e.target.value)}  type="text" placeholder='Item Name' className='item-input form-control text-black  border-1 border-black' />
                    {errors.itemName && <small className="text-danger">{errors.itemName}</small>}
                    </div>
                    {itemType === "" && (
                            <div>
                                <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                <input 
                                    value={hsn} 
                                    onChange={(e) => handleHsnChange(e.target.value)}  
                                    type="text" 
                                    placeholder='Item HSN' 
                                    className='item-input form-control  text-black border-1 border-black'
                                />
                                {errors.hsn && <small className="text-danger">{errors.hsn}</small>}
                                
                            </div>
                        )}
                    {itemType === "Goods" && (
                            <div>
                                <p style={{color:'#3d4465'}} className=''>Item HSN :</p>
                                <input 
                                    value={hsn} 
                                    onChange={(e) => handleHsnChange(e.target.value)} 
                                    type="text" 
                                    placeholder='Item HSN' 
                                    className='item-input form-control text-black border-1 border-black'
                                />
                                {errors.hsn && <small className="text-danger">{errors.hsn}</small>}
                            </div>
                        )}
                        {itemType === "Service" && (
                            <div>
                                <p style={{color:'#3d4465'}} className=''>Item SAC :</p>
                                <input 
                                    value={sac} 
                                    onChange={(e)=>handleSacChange(e.target.value)}
                                    type="text" 
                                    placeholder='Item SAC' 
                                    className='item-input form-control text-black border-1 border-black'
                                />
                                {errors.sac && <small className="text-danger">{errors.sac}</small>}
                            </div>
                        )}
                    <div className=''>
                    <p style={{color:'#3d4465'}} className='unit'>Select Unit :</p>
                        <div className='unit d-flex flex-wrap gap-2'>
                            <select value={unit} onChange={(e)=>setUnit(e.target.value)}  name="item-type" id="" className='item-input text-black border-1 border-black bg-light form-control '>
                                <option value="" disabled selected>Select Unit</option>
                                <option value="Packages" >Packages</option>
                                <option value="Numbers" >Numbers</option>
                                <option value="Box" >Box</option>
                                {allUnit.map((itm)=>(
                                    <option key={itm.id} value={itm.unit_name}>{itm.unit_name}</option>
                                ))
                                    }
                                
                            </select>
                            <button style={{backgroundColor:'#3d4465'}} type='button' data-bs-toggle="modal" data-bs-target="#exampleModal" className="addbutton btn">
                        
                          <i
                            className="text-white mdi mdi-plus menu-icon mb-0"
                          ></i>
                      </button>
                        </div>
                        {/* modal for adding unit */}
                      <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div
                             style={{ backgroundColor: "white" }}
                            class="modal-header"
                          >
                            <h1
                            
                            style={{color:'#3d4465'}}
                            className="modal-title  fw-bold fs-5"
                            id="exampleModalLabel"
                            >
                              Add Unit
                            </h1>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <div>
                                <p className='text-black'>New unit :</p>
                                <input type="text" value={addunit} onChange={(e)=>setAddUnit(e.target.value)} className='form-control' />
                            </div>
                          </div>
                          <div class="modal-footer">
                            
                            <button  data-bs-dismiss="modal" onClick={(e) => handleAddUnit(e)} type="button" style={{backgroundColor:'#3d4465'}}
                className='text-white btn'>
                              ADD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
            </form>
            {/* Pricing and stocks */}
            <div>
      {/* Pricing and Stocks Tabs */}
      <div style={{ backgroundColor: '#8290c6' }} className="p-s d-flex flex-wrap gap-4 p-3 mt-5">
    <div
        className="pricing m-auto position-relative"
        onClick={() => setActiveTab('pricing')}
        style={{ cursor: 'pointer' }}
    >
        <h1
            className="fw-bold"
            style={{
                color: activeTab === 'pricing' ? '#181e3d' : '#fff', // Black when active, white otherwise
                fontSize:'24px'
            }}
        >
            PRICING
        </h1>
        {activeTab === 'pricing' && (
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    marginLeft:'-230px',
                    width: '70vh',
                    height: '2px',
                    backgroundColor: '#181e3d',
                }}
            ></div>
        )}
    </div>

    <div
        className="stock m-auto position-relative"
        onClick={() => setActiveTab('stocks')}
        style={{ cursor: 'pointer' }}
    >
        <h1
            className="fw-bold"
            style={{
                color: activeTab === 'stocks' ? '#181e3d' : '#fff',
                fontSize:'24px'
            }}
        >
            STOCKS
        </h1>
        {activeTab === 'stocks' && (
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    marginLeft:'-182px',
                    width: '70vh',
                    height: '2px',
                    backgroundColor: '#181e3d',
                }}
            ></div>
        )}
    </div>
</div>


      {/* Content Based on Active Tab */}
      {activeTab === 'pricing' ? (
        <form onSubmit={handleSave} className='pricing-field p-4'>
          {/* Radio Buttons */}
          <div className='d-flex flex-wrap gap-4'>
            <div className="form-check d-flex  align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                checked={isTaxable}
                onChange={() => setIsTaxable(true)}
              />
              <p className="form-check-label text-black" htmlFor="flexRadioDefault1">
                Taxable
              </p>
            </div>
            <div className="form-check d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked={!isTaxable}
                onChange={() => setIsTaxable(false)}
              />
              <p className="form-check-label text-black" htmlFor="flexRadioDefault2">
                Non Taxable
              </p>
            </div>
          </div>

          {/* Conditional Fields */}
          {isTaxable ? (
            <form onSubmit={handleSave} className='fields-taxable mt-5'>
              <div className=' taxable '>
                <div className='w-50'>
                  <p style={{fontSize:'16px'}} className='text-black '>GST</p>
                  <select value={gst} onChange={(e)=>setGst(e.target.value)} className='form-control text-black  border-1 border-black non-t-i'>
                  <option value="GST0[0%]">GST0[0%]</option>
                  <option value="GST3[3%]">GST3[3%]</option>
                  <option value="GST5[5%]">GST5[5%]</option>
                  <option value="GST12[12%]">GST12[12%]</option>
                  <option value="GST18[18%]">GST18[18%]</option>
                  <option value="GST28[28%]">GST28[28%]</option>
                  </select>
                </div>
                <div className='w-50'>
                  <p style={{fontSize:'16px'}} className='text-black '>IGST</p>
                  <select value={igst} onChange={(e)=>setIgst(e.target.value)} className='form-control text-black border-1 border-black non-t-i'>
                  <option value="IGST0[0%]">IGST0[0%]</option>
                  <option value="IGST3[3%]">IGST3[3%]</option>
                  <option value="IGST5[5%]">IGST5[5%]</option>
                  <option value="IGST12[12%]">IGST12[12%]</option>
                  <option value="IGST18[18%]">IGST18[18%]</option>
                  <option value="IGST28[28%]">IGST28[28%]</option>
                  </select>
                </div>
              </div>

              <form onSubmit={handleSave} className=' taxable mt-4'>
                <div className='w-50'>
                  <p style={{fontSize:'16px'}} className='text-black '>SALES PRICE</p>
                  <input value={salesPrice} onChange={(e)=>setSalesPrice(e.target.value)} type="text" className='form-control text-black border-1 border-black non-t-i' />
                </div>
                <div className='w-50'>
                  <p style={{fontSize:'16px'}} className='text-black '>PURCHASE PRICE</p>
                  <input value={purchasePrice} onChange={(e)=>setPurchasePrice(e.target.value)} type="text" className='form-control text-black border-1 border-black non-t-i' />
                </div>
              </form>
              <button
              style={{backgroundColor:'#3d4465'}}
                className='text-white btn mt-5'
                onClick={() => setActiveTab('stocks')}
              >
                <span className="icon">Next</span>
              </button>
            </form>
          ) : (
            <form className='fields-non-taxable mt-5'>
              <div className='d-flex non-t gap-3'>
                <div className='w-50'>
                  <p style={{fontSize:'16px'}} className='text-black  sale-p'>SALES PRICE</p>
                  <input value={nonTaxableSalePrice} onChange={(e)=>setnonTaxableSalePrice(e.target.value)} type="text" className='form-control text-black border-1 border-black non-t-i' placeholder="Enter Sales Price" />
                </div>
                <div className='w-50 '>
                  <p style={{fontSize:'16px'}} className='text-black  sale-p'>PURCHASE PRICE</p>
                  <input value={nonTaxablePurchasePrice} onChange={(e)=>setnonTaxablePurchasePrice(e.target.value)} type="text" className='form-control text-black border-1 border-black non-t-i' placeholder="Enter Purchase Price" />
                </div>
              </div>
              <button
                 style={{backgroundColor:'#3d4465'}}
                className='text-white btn mt-5'
                onClick={() => setActiveTab('stocks')}
              >
                Next
              </button>
            </form>
          )}
        </form>
      ) : (
        <form onSubmit={handleSave} className='stocks-field p-4'>
          <div className=' s-input'>
                <div className='w-50'>
                  <p style={{fontSize:'16px'}} className='text-black '>STOCK IN HAND</p>
                  <input value={stockInHand}onChange={(e)=>setStockInHand(e.target.value)} type="text" className='form-control text-black border-1 border-black'  />
                </div>
                <div className='w-50'>
                  <p style={{fontSize:'16px'}} className='text-black '>AT PRICE</p>
                  <input value={atPrice} onChange={(e)=>setAtprice(e.target.value)} type="text" className='form-control text-black border-1 border-black'  />
                </div>
            </div>
            <div className='mt-3 s-input'>
            <div className='w-50'>
                  <p style={{fontSize:'16px'}} className='text-black '>DATE</p>
                  <input onChange={(e)=>setDate(e.target.value)} type="date" value={date}
                    className='form-control text-black border-1 border-black'  />
            </div>
            <div className='w-50'>
                  <p style={{fontSize:'16px'}} className='text-black '>MINIMUM STOCK TO MAINTAIN</p>
                  <input value={minStockMaintain} onChange={(e)=>setminStockMaintain(e.target.value)} type="text" className='form-control text-black border-1 border-black'  />
                </div>
            </div>
          <div className='d-flex justify-content-center flex-wrap gap-3 mt-5'>
          <button
                style={{backgroundColor:'#3d4465'}}
                className='text-white btn'
                onClick={() => setActiveTab('pricing')}
              >
                Previous
              </button>

              <button
              type='submit'
               style={{backgroundColor:'#3d4465'}}
                className='text-white btn'
                onClick={(e) => handleSave(e)}
              >
               Update
              </button>
          </div>
        </form>
      )}
    </div>

           </div>


            
            
        
          </section>

        </div>

      </div>
    </div>
  )
}