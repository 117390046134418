import React, { useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  Filler
} from 'chart.js';

// Register the necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ChartComponent = ({ chartType, chartData, chartOptions }) => {
  return (
    <div style={{ width: '100%', height: '400px' }}>
      {chartType === 'bar' ? (
        <Bar data={chartData} options={chartOptions} />
      ) : chartType === 'line' ? (
        <Line data={chartData} options={chartOptions} />
      ) : null}
    </div>
  );
};

export default ChartComponent;
