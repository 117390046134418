import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import config from "../../functions/config";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/SalesReport.css";

export default function CompanySalesReport() {
  const navigate = useNavigate();
  const [loginName, setLoginName] = useState("loginName");
  const [invoice, setInvoice] = useState([]);
  const [credit, setCredit] = useState([]);
  const [netAmount, setNetAmount] = useState(0);

  const tableStyles = {
    width: "100%",
    marginBottom: "1rem",
    color: "#212529",
    borderCollapse: "collapse",
    border: "1px solid #dee2e6",
    padding: "0.75rem",
    verticalAlign: "top",
    textAlign: "left",
    backgroundColor: "#f2f2f2", // background color for table rows
  };
  // Inline styles for table header cells
  const thStyles = {
    backgroundColor: "#f8f9fa", // Light background for headers
    fontWeight: "bold",
    padding: "10px",
  };
  // Inline styles for table td cells
  const tdStyles = {
    padding: "10px",
  };

  // Screen width
  const [panelWidth, setPanelWidth] = useState(
    window.innerWidth > 768 ? "90%" : "100%"
  );
  useEffect(() => {
    const handleResize = () =>
      setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Essentials
  const user = Cookies.get("role");
  console.log(user);
  var is_company = false;
  var is_staff = false;
  if (user === "Company") {
    is_company = true;
  }
  if (user === "Staff") {
    is_company = true;
  }
  const ID = Cookies.get("user_id");

  // Link to show Graph
  const handleGraph = () => {
    navigate("/company_salesgraph");
  };

  // Invoice
  const getInvoice = () => {
    axios
      .get(`${config.base_url}salesreport/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const details = res.data.salesinvoice;
          const cname = res.data.company;

          setInvoice(details);
          setLoginName(cname);
          console.log(res);
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
      });
  };

  // Creditnote
  const getCredit = () => {
    axios
      .get(`${config.base_url}creditnote/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const details = res.data.salesinvoice;

          setCredit(details);
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
      });
  };

  //To call essential functions
  useEffect(() => {
    getInvoice();
    getCredit();
  }, []);

  // Filter Section
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [share, setShare] = useState(false);

  
  // Function to handle PDF export
  const generatePDF = () => {
    const doc = new jsPDF("p", "mm", "a4");

    // Title Section
    doc.setFontSize(16);
    doc.text(loginName || "Company", doc.internal.pageSize.width / 2, 10, {
      align: "center",
    });
    doc.setFontSize(14);
    doc.text("Sales Report", doc.internal.pageSize.width / 2, 20, {
      align: "center",
    });
    doc.setFontSize(12);

    // Conditional Rendering for Date Range
    let dateRange = "";
    if (start && end) {
      dateRange = `${start} To ${end}`;
    } else if (start) {
      dateRange = `From ${start} `;
    } else if (end) {
      dateRange = `Till ${end}`;
    }

    // Display the date range if available
    if (dateRange) {
      doc.setFontSize(12);
      doc.text(dateRange, doc.internal.pageSize.width / 2, 30, {
        align: "center",
      });
    }

    // Table Headers
    const headers = ["Date", "Invoice No", "Party Name", "Type", "Amount"];
    const tableData = [];

    // Add invoice data
    filteredInvoices.forEach((item) => {
      if (item.date) {
        // Check if date is valid
        tableData.push([
          item.date,
          item.invoice_no,
          item.party_name,
          "Invoice",
          item.grandtotal,
        ]);
      }
    });

    // Add credit note data
    filteredCreditnote.forEach((item) => {
      if (item.date) {
        // Check if date is valid
        tableData.push([
          item.date,
          item.invoiceno,
          item.party.party_name,
          "Creditnote",
          item.grandtotal,
        ]);
      }
    });

    // Generate the table with autoTable
    doc.autoTable({
      head: [headers],
      body: tableData,
      startY: 40,
      theme: "striped",
      margin: { top: 10, left: 10, right: 10 },
      tableWidth: doc.internal.pageSize.width - 20, // Ensure full page width
    });

    // Add Total Section
    doc.setFontSize(12);
    doc.text(`Total: ${netAmount}`, 10, doc.lastAutoTable.finalY + 10); // Use finalY for positioning
    doc.save("sales_report.pdf");
  };

  // Function to handle Excel export
  const exportToExcel = () => {
    // Prepare headers
    const headers = ["Date", "Invoice No", "Party Name", "Type", "Amount"];

    // Prepare table data for invoices
    const tableData = [];

    // Add invoice data
    filteredInvoices.forEach((item) => {
      if (item.date) {
        // Only include rows with valid date
        tableData.push([
          item.date,
          item.invoice_no,
          item.party_name,
          "Invoice",
          item.grandtotal,
        ]);
      }
    });

    // Add credit note data
    filteredCreditnote.forEach((item) => {
      if (item.date) {
        // Only include rows with valid date
        tableData.push([
          item.date,
          item.invoiceno,
          item.party.party_name,
          "Creditnote",
          item.grandtotal,
        ]);
      }
    });

    // Prepare the sheet data
    const sheet = XLSX.utils.aoa_to_sheet([headers, ...tableData]);

    // Prepare the workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Sales Report");

    // Prepare the file name based on the date range
    let fileName = "sales_report.xlsx";
    if (start && end) {
      fileName = `sales_report_${start}_to_${end}.xlsx`;
    } else if (start) {
      fileName = `sales_report_${start}.xlsx`;
    } else if (end) {
      fileName = `sales_report_${end}.xlsx`;
    }

    // Export the workbook to a file
    XLSX.writeFile(wb, fileName);
  };

  //Open email whatsapp dropdown
  const ShareModal = () => {
    setShare(!share);
  };

  // Function to handle Print
  const printDivContent = () => {
    const content = document.getElementById("sales-report-content").innerHTML;
    const printWindow = window.open("", "", "height=800,width=800");
    printWindow.document.write(
      "<html><head><title>Sales Report</title></head><body>"
    );
    printWindow.document.write(content);
    printWindow.document.write("</body></html>");
    printWindow.document.close(); // Close the document
    printWindow.print(); // Trigger the print dialog
  };

  // Function to generate WhatsApp share link
  const generateWhatsAppLink = (url, text) => {
    const message = `Check this out: ${text} - ${url}`;
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/?text=${encodedMessage}`;
  };

  // Function to create a shareable URL for WhatsApp
  const shareToWhatsApp = () => {
    const shareUrl = `${config.base_url}/company_sales`;
    const shareText = "Sales Report";
    const whatsappUrl = generateWhatsAppLink(shareUrl, shareText);
    window.open(whatsappUrl, "_blank");
  };

  // Email box opwn
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const handleClose = () => setShow(false);
  const shareToEmail = () => {
    console.log("email");
    setShow(true);
  };

  // Send Email to Backend
  const handleSendEmail = () => {
    // Handle sending email logic here
    console.log("Email Sent", { email, message });
    // Close the modal after sending the email
    const doc = new jsPDF("p", "mm", "a4");

    // Title Section
    doc.setFontSize(16);
    doc.text(loginName || "Company", doc.internal.pageSize.width / 2, 10, {
      align: "center",
    });
    doc.setFontSize(14);
    doc.text("Sales Report", doc.internal.pageSize.width / 2, 20, {
      align: "center",
    });
    doc.setFontSize(12);

    // Conditional Rendering for Date Range
    let dateRange = "";
    if (start && end) {
      dateRange = `${start} To ${end}`;
    } else if (start) {
      dateRange = `From ${start} `;
    } else if (end) {
      dateRange = `Till ${end}`;
    }

    // Display the date range if available
    if (dateRange) {
      doc.setFontSize(12);
      doc.text(dateRange, doc.internal.pageSize.width / 2, 30, {
        align: "center",
      });
    }

    // Table Headers
    const headers = ["Date", "Invoice No", "Party Name", "Type", "Amount"];
    const tableData = [];

    // Add invoice data
    filteredInvoices.forEach((item) => {
      if (item.date) {
        // Check if date is valid
        tableData.push([
          item.date,
          item.invoice_no,
          item.party_name,
          "Invoice",
          item.grandtotal,
        ]);
      }
    });

    // Add credit note data
    filteredCreditnote.forEach((item) => {
      if (item.date) {
        // Check if date is valid
        tableData.push([
          item.date,
          item.invoiceno,
          item.party.party_name,
          "Creditnote",
          item.grandtotal,
        ]);
      }
    });

    // Generate the table with autoTable
    doc.autoTable({
      head: [headers],
      body: tableData,
      startY: 40,
      theme: "striped",
      margin: { top: 10, left: 10, right: 10 },
      tableWidth: doc.internal.pageSize.width - 20, // Ensure full page width
    });

    // Add Total Section
    doc.setFontSize(12);
    doc.text(`Total: ${netAmount}`, 10, doc.lastAutoTable.finalY + 10); // Use finalY for positioning

    // Convert PDF to Base64 (or Blob)
    const pdfData = doc.output("datauristring"); // Generates a base64 string

    // Prepare the data to send to the backend
    const emailData = {
      email: email, // email input from the state
      message: message, // message input from the state
      pdf: pdfData, // base64 encoded PDF
    };
    console.log("emailData: ", emailData);
    // Send the data to the backend using Axios
    axios
      .post(`${config.base_url}/salesreportemail/`, emailData) // Ensure trailing slash
      .then((response) => {
        console.log("Email sent successfully", response);
        setShare(false);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        if (error.response) {
          // Server returned an error response (e.g., 500, 404)
          alert(`Error: ${error.response.data.error}`);
        } else {
          // Other errors (network issue, etc.)
          alert("An error occurred while sending the email. Please try again.");
        }
      });

    handleClose();
  };

  // Invoice dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
  const [isPartyOpen, setIsPartyOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isAmountOpen, setIsAmountOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [invoicenofilter, setInvoicenofilter] = useState("");
  const [party, setParty] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");

  // Handle the click event on a table header
  const handleHeaderClick = (e, header) => {
    
    console.log(header);
    setIsDateOpen(false);
    setIsInvoiceOpen(false);
    setIsPartyOpen(false);
    setIsTypeOpen(false);
    setIsAmountOpen(false);

    // Set the appropriate state to true based on the clicked header
    switch (header) {
      case "Date":
        setIsDateOpen(true);
        break;
      case "Invoice No":
        setIsInvoiceOpen(true);
        break;
      case "Party Name":
        setIsPartyOpen(true);
        break;
      case "Type":
        setIsTypeOpen(true);
        break;
      case "Amount":
        setIsAmountOpen(true);
        break;
      default:
        break;
    }
  };

  // Close Filter
  const handleFilter = (e) => {
    
    setIsDateOpen(false);
    setIsInvoiceOpen(false);
    setIsPartyOpen(false);
    setIsTypeOpen(false);
    setIsAmountOpen(false);
  };

  // Close the filter and clear the data
  const handleCloseFilter = (e) => {
    setIsDateOpen(false);
    setIsInvoiceOpen(false);
    setIsPartyOpen(false);
    setIsTypeOpen(false);
    setIsAmountOpen(false);
    setDate("");
    setInvoicenofilter("");
    setParty("");
    setType("");
    setAmount("");
  };

  // Filtered invoices based on all filters
  const filteredInvoices = invoice.filter((item) => {
    const itemDate = new Date(item.date);
    const startDate = new Date(start);
    const endDate = new Date(end);
  
    // If search term is provided, apply search filter
    if (search) {
      if (
        !(item.date.includes(search) || // Partial match for date
        item.invoice_no.toString().includes(search) || // Partial match for invoice number
        item.party_name.toLowerCase().includes(search.toLowerCase()) || // Partial match for party name (case-insensitive)
        'invoice'.includes(search.toLowerCase()) || // Partial match for type
        item.grandtotal.toString().includes(search)) // Partial match for grandtotal
      ) {
        return false; // If search doesn't match, exclude the item
      }
    }
  
    // If start and end dates are provided, filter by date range first
    if (start && end) {
      if (itemDate < startDate || itemDate > endDate) return false;
    }
  
    // Apply other filters after date range
    return (
      (date === "" || item.date === date) &&
      (invoicenofilter === "" || item.invoice_no.toString().includes(invoicenofilter)) &&
      (party === "" || item.party_name.toLowerCase().includes(party.toLowerCase())) &&
      (amount === "" || item.grandtotal.toString().includes(amount)) &&
      (type === "" || "invoice".includes(type.toLowerCase())) // Assuming type is "Invoice" for invoices
    );
  });


  // Filtered credit notes based on all filters
  const filteredCreditnote = credit.filter((item) => {
    const itemDate = new Date(item.date);
    const startDate = new Date(start);
    const endDate = new Date(end);
  
    // If search term is provided, apply search filter
    if (search) {
      if (
        !(item.date.includes(search) || // Partial match for date
        item.invoiceno.includes(search) || // Partial match for invoice number
        item.party.party_name.toLowerCase().includes(search.toLowerCase()) || // Partial match for party name (case-insensitive)
        'creditnote'.includes(search.toLowerCase()) || // Partial match for type
        item.grandtotal.toString().includes(search)) // Partial match for grandtotal
      ) {
        return false; // If search doesn't match, exclude the item
      }
    }
  
    // If start and end dates are provided, filter by date range first
    if (start && end) {
      if (itemDate < startDate || itemDate > endDate) return false;
    }
  
    // Apply other filters after date range
    return (
      (date === "" || item.date === date) &&
      (invoicenofilter === "" || item.invoiceno === invoicenofilter) &&
      (party === "" || item.party.party_name.toLowerCase().includes(party.toLowerCase())) &&
      (amount === "" || item.grandtotal.toString().includes(amount)) &&
      (type === "" || "creditnote".includes(type.toLowerCase())) // Assuming type is "Creditnote" for credit notes
    );
  });
  


  // useEffect to calculate the net amount after filtering
  useEffect(() => {

    let total = 0;

    // Add amounts from invoices
    filteredInvoices.forEach((item) => {
      total += item.grandtotal;
    });

    // Subtract amounts from credit notes
    filteredCreditnote.forEach((item) => {
      total -= item.grandtotal;
    });

    // Set the calculated net amount
    setNetAmount(total);
  }, [filteredInvoices, filteredCreditnote]); // Trigger recalculation when filters change


  // Reset non-date filters when start and end dates are set
  useEffect(() => {
    if (start && end) {
      // Reset other filters if start and end are set
      setDate('')
      setSearch('')
      setInvoicenofilter('');
      setParty('');
      setAmount('');
      setType('');
    }
  }, [start, end]); // This will run when start or end is updated

  useEffect(() => {
    if (search) {
      // Reset other filters if start and end are set
      setDate('')
      setInvoicenofilter('');
      setParty('');
      setAmount('');
      setType('');
    }
  }, [search]); // This will run when start or end is updated


  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />

        <div
          className="main-panel p-3"
          style={{ background: "#8290c7", width: panelWidth }}
        >
          <div className="content-wrapper">
            <div
              className="body-wrapper p-1 p-sm-3"
              style={{ minHeight: "100vh" }}
            >



              {/* First Section */}
              <div
                className="card radius-15 pt-4 shadow-lg"
                style={{ background: "#3d4465" }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {is_company ? loginName : "Company"}
                </h1>

                {(start || end) && (
                  <p
                    className="text-center font-semibold "
                    style={{ color: "white" }}
                  >
                    {start} To {end}
                  </p>
                )}

                <div className="row ">
                  <div className="col-12  flex justify-content-center">
                    <div className="container-fluid">
                      {/* First row with two columns: left for date inputs and right for images */}
                      <div className="row p-sm-4 ">
                        {/* Left Column: Date Inputs */}
                        <div className="col-12 col-md-6 d-flex gap-3 flex-wrap mb-4 mb-md-0">
                          <div className="flex-grow-1">
                            <p
                              className="dateslabel"
                              style={{ color: "white" }}
                            >
                              From Date
                            </p>
                            <input
                              type="date"
                              className="form-control"
                              id="startDate"
                              onChange={(e) => {
                                setStart(e.target.value);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <p
                              className="dateslabel"
                              style={{ color: "white" }}
                            >
                              To Date
                            </p>
                            <input
                              type="date"
                              className="form-control"
                              id="endDate"
                              onChange={(e) => {
                                setEnd(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        {/* Right Column: Clickable images */}
                        <div className="col-12 col-md-6">
                          <div className="d-flex flex-wrap justify-content-end buttonimages">
                            <div className="text-center">
                              <img
                                src={require("../../assets/images/icons/graph.png")}
                                alt="Image 1"
                                className="img-fluid cursor-pointer"
                                onClick={handleGraph}
                              />
                              <p>graph</p>
                            </div>

                            <div className="text-center">
                              <img
                                src={require("../../assets/images/icons/excel2.png")}
                                alt="Image 3"
                                className="img-fluid cursor-pointer"
                                onClick={exportToExcel}
                              />
                              <p>excel</p>
                            </div>

                            <div className="text-center">
                              <img
                                src={require("../../assets/images/icons/PDF_icon.png")}
                                alt="Image 2"
                                className="img-fluid cursor-pointer"
                                onClick={generatePDF}
                              />
                              <p>pdf</p>
                            </div>

                            <div className="text-center">
                              <img
                                src={require("../../assets/images/icons/share.png")}
                                alt="Image 4"
                                className="img-fluid cursor-pointer"
                                onClick={ShareModal}
                              />
                              <p>share</p>
                            </div>

                            <div className="text-center">
                              <img
                                src={require("../../assets/images/icons/printer.jpeg")}
                                alt="Image 5"
                                className="img-fluid cursor-pointer"
                                onClick={printDivContent}
                              />
                              <p>print</p>
                            </div>
                          </div>

                          {share && (
                            <div className="d-flex flex-column text-right justify-content-end align-items-end w-auto shareoptions">
                              <div className="   p-2" onClick={shareToWhatsApp}>
                                Whatsapp
                              </div>
                              <div className="  p-2" onClick={shareToEmail}>
                                Email
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              {/* Second Section */}
              <div
                className="card radius-15 mt-4 shadow-lg"
                style={{ background: "#3d4465" }}
              >
                <div className="card-body">
                  <div className="row ">
                    <div className="col">
                      <div className="container-fluid">
                        <h1
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          {" "}
                          Sales Report{" "}
                        </h1>
                        <div className="d-flex justify-content-start mb-3">
  <input type="text" placeholder="Search" className="form-control w-auto" onChange={(e)=>{setSearch(e.target.value)}} />
</div>

                        
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                </div>

                          {/* Fix table  */}
                          <div
                            className="table-container  "
                            style={{ position: "relative",background: '#ffffff' }}
                          >
                            <div className="table-responsive ">
                              <table className="table   border-bottom" style={{ backgroundColor: "#ff5733 !important" }} >
                                <thead >
                                  <tr className=" text-center text-uppercase" >
                                    <th>
                                      Date{" "}
                                      <FontAwesomeIcon
                                        icon={faFilter}
                                        onClick={(e) =>
                                          handleHeaderClick(e, "Date")
                                        }
                                      />
                                      {isDateOpen && (
                                        <div className="input-div filtercolumn">
                                          <p>equal to</p>
                                          <input
                                            type="date"
                                            onChange={(e) => {
                                              setDate(e.target.value);
                                            }}
                                            value={date}
                                          />
                                          <div className="filterbtn">
                                            <button
                                              onClick={handleCloseFilter}
                                              style={{ marginTop: "5px" }}
                                            >
                                              clear
                                            </button>
                                            <button
                                              onClick={handleFilter}
                                              style={{ marginTop: "5px" }}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </th>
                                    <th>
                                      Invoice No{" "}
                                      <FontAwesomeIcon
                                        icon={faFilter}
                                        onClick={(e) =>
                                          handleHeaderClick(e, "Invoice No")
                                        }
                                      />
                                      {isInvoiceOpen && (
                                        <div className="input-div filtercolumn">
                                          <p>equal to</p>
                                          <input
                                            type="text"
                                            onChange={(e) => {
                                              setInvoicenofilter(e.target.value);
                                            }}
                                            value={invoicenofilter}
                                          />
                                          <div className="filterbtn">
                                            <button
                                              onClick={handleCloseFilter}
                                              style={{ marginTop: "5px" }}
                                            >
                                              clear
                                            </button>
                                            <button
                                              onClick={handleFilter}
                                              style={{ marginTop: "5px" }}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </th>
                                    <th>
                                      Party Name{" "}
                                      <FontAwesomeIcon
                                        icon={faFilter}
                                        onClick={(e) =>
                                          handleHeaderClick(e, "Party Name")
                                        }
                                      />
                                      {isPartyOpen && (
                                        <div className="input-div filtercolumn">
                                          <p>equal to</p>
                                          <input
                                            type="text"
                                            onChange={(e) => {
                                              setParty(e.target.value);
                                            }}
                                            value={party}
                                          />
                                          <div className="filterbtn">
                                            <button
                                              onClick={handleCloseFilter}
                                              style={{ marginTop: "5px" }}
                                            >
                                              clear
                                            </button>
                                            <button
                                              onClick={handleFilter}
                                              style={{ marginTop: "5px" }}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </th>
                                    <th>
                                      Type{" "}
                                      <FontAwesomeIcon
                                        icon={faFilter}
                                        onClick={(e) =>
                                          handleHeaderClick(e, "Type")
                                        }
                                      />
                                      {isTypeOpen && (
                                        <div className="input-div filtercolumn">
                                          <p>equal to</p>
                                          <input
                                            type="text"
                                            onChange={(e) => {
                                              setType(e.target.value);
                                            }}
                                            value={type}
                                          />
                                          <div className="filterbtn">
                                            <button
                                              onClick={handleCloseFilter}
                                              style={{ marginTop: "5px" }}
                                            >
                                              clear
                                            </button>
                                            <button
                                              onClick={handleFilter}
                                              style={{ marginTop: "5px" }}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </th>
                                    <th>
                                      Amount{" "}
                                      <FontAwesomeIcon
                                        icon={faFilter}
                                        onClick={(e) =>
                                          handleHeaderClick(e, "Amount")
                                        }
                                      />
                                      {isAmountOpen && (
                                        <div className="input-div filtercolumn">
                                          <p>equal to</p>
                                          <input
                                            type="text"
                                            onChange={(e) => {
                                              setAmount(e.target.value);
                                            }}
                                            value={amount}
                                          />
                                          <div className="filterbtn">
                                            <button
                                              onClick={handleCloseFilter}
                                              style={{ marginTop: "5px" }}
                                            >
                                              clear
                                            </button>
                                            <button
                                              onClick={handleFilter}
                                              style={{ marginTop: "5px" }}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-light">
                                  {filteredInvoices.map((item) => (
                                    <tr key={item.id} className="text-center">
                                      <td className="nowrap">{item.date}</td>
                                      <td>{item.invoice_no}</td>
                                      <td>{item.party_name}</td>
                                      <td>Invoice</td>
                                      <td className="nowrap">
                                        {item.grandtotal}
                                      </td>
                                    </tr>
                                  ))}

                                  {filteredCreditnote.map((item) => (
                                    <tr key={item.id} className="text-center">
                                      <td className="nowrap">{item.date}</td>
                                      <td>{item.invoiceno}</td>
                                      <td>{item.party.party_name}</td>
                                      <td>Creditnote</td>
                                      <td className="nowrap">
                                        {item.grandtotal}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                           
                            <p
                              className="text-dark "
                              style={{ fontSize: "1.5rem",
                               
                                padding:'15px',
                            fontWeight: 500,
                
                               }}
                            >
                              Total Amount : {netAmount}
                            </p>
                          </div>

                        </div>
              

              {/* Content to be printed */}
              <div
                id="sales-report-content"
                className="card radius-15 mt-4 d-none"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="container-fluid">
                        <h1
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}
                        >
                          {loginName != null ? loginName : "Company"}
                        </h1>
                        <h1
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}
                        >
                          Sales Report
                        </h1>
                        {(start || end) && (
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "16px",
                              textTransform: "uppercase",
                            }}
                          >
                            {start} To {end}
                          </p>
                        )}

                        <div>
                          <div className="table-responsive">
                            <table style={tableStyles}>
                              <thead>
                                <tr>
                                  <th style={thStyles}>Date</th>
                                  <th style={thStyles}>Invoice No</th>
                                  <th style={thStyles}>Party Name</th>
                                  <th style={thStyles}>Type</th>
                                  <th style={thStyles}>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredInvoices.map(
                                  (item) =>
                                    item.date && (
                                      <tr key={item.id}>
                                        <td style={tdStyles}>{item.date}</td>
                                        <td style={tdStyles}>
                                          {item.invoice_no}
                                        </td>
                                        <td style={tdStyles}>
                                          {item.party_name}
                                        </td>
                                        <td style={tdStyles}>Invoice</td>
                                        <td style={tdStyles}>
                                          {item.grandtotal}
                                        </td>
                                      </tr>
                                    )
                                )}
                                {filteredCreditnote.map(
                                  (item) =>
                                    item.date && (
                                      <tr key={item.id}>
                                        <td style={tdStyles}>{item.date}</td>
                                        <td style={tdStyles}>
                                          {item.invoiceno}
                                        </td>
                                        <td style={tdStyles}>
                                          {item.party.party_name}
                                        </td>
                                        <td style={tdStyles}>Creditnote</td>
                                        <td style={tdStyles}>
                                          {item.grandtotal}
                                        </td>
                                      </tr>
                                    )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <p>Total: {netAmount}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Modal Structure */}
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Share Report Via Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Form inside Modal */}
                  <Form>
                    <Form.Group controlId="formEmail" className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group controlId="formMessage">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter your message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button className='bg-secondary' variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" 
                  className='bg-primary'
                  onClick={handleSendEmail}
                  >
                    Send Email
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
