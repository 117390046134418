import React, { useEffect, useState } from "react";
import { Link, useParams ,useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "../styles/parties.css"

function Party_History() {
  const ID = Cookies.get("user_id");
  const navigate = useNavigate();
  const { partyId } = useParams();
  const [history, setHistory] = useState([]);
  const [party, setParty] = useState({});

  const fetchPartyHistory = () => {
    axios
      .get(`${config.base_url}/fetch_party_history/${partyId}/`)
      .then((res) => {
        console.log("Party HIST=", res);
        if (res.data.status) {
          var party = res.data.party;
          var hist = res.data.history;
          setParty(party);
          setHistory([]);
          hist.map((i) => {
            setHistory((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchPartyHistory();
  }, []);

  function goBack() {
    navigate(-1); 
  }
  
  return (

<>
  <div className="container-scroller"  style={{ background: "rgb(130, 144, 199)" }}>
    <Navbar />
    <div className="hist container-fluid page-body-wrapper d-flex">
      <Sidebar />
      <div className="main-panel p-4 px-3 py-2" style={{ background: "#a9a9a961", overflow: "hidden", height: "100vh" }}> {/* Prevent scroll on main-panel */}
        <div className="content-wrapper"> 
          <div
            className="container col-12 col-md-10 table-div"
            style={{
              borderRadius: "8px",
              padding: "20px",
              backgroundColor: "white",
              maxHeight: "calc(100vh - 150px)", 
              overflowY: "auto",
            }}
          >
            <h2 className="mb-3">
              <span className="text-white">
                {party.party_name}'s History
              </span>
              <div style={{ float: "right" }}>
                <div className="d-flex justify-content-end p-2 " style={{ cursor: "pointer" }}>
                  <button className="btn button-hover " onClick={goBack}> <i className="fa fa-arrow-left"></i> Back</button>
                </div>
              </div>
            </h2>
            
            <div className="table-responsive">
              <table className="hist table">
                <thead>
                  <tr>
                    <th style={{ fontSize: "larger", backgroundColor: "#3d4465", color: "white", border: "none" }}>
                      ACTION
                    </th>
                    <th style={{ fontSize: "larger", backgroundColor: "#3d4465", color: "white", border: "none" }}>
                      DATE
                    </th>
                    <th style={{ fontSize: "larger", backgroundColor: "#3d4465", color: "white", border: "none" }}>
                     USER
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((item, index) => (
                    <tr key={index}>
                      <td>{item.action}</td>
                      <td>{item.date}</td>
                      <td>{item.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>



  );
}
      

export default Party_History;
