import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/SalesReport.css";
import GraphComponent from "./GraphComponent";


export default function CompanySalesGraph() {
  const navigate = useNavigate();

  const [filter, setFilter] = useState('monthly');
  
  const [panelWidth, setPanelWidth] = useState(
    window.innerWidth > 768 ? "90%" : "100%"
  );

  useEffect(() => {
    const handleResize = () =>
      setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Added

  const handleBack = () => {
    navigate('/company_sales')
  };


 

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />

        <div
          className="main-panel p-3"
          style={{  width: panelWidth }}
        >
          <div className="content-wrapper">
            <div
              className="body-wrapper p-1 p-sm-3"
              style={{ minHeight: "100vh" }}
            >
              
              <div className="d-flex justify-content-between ">
                {/* Centered radio buttons and labels */}

                <div className="d-flex justify-content-center align-items-center">
                  <input type="radio" className="align-middle" name="graph" value='monthly' checked={filter === "monthly"}  onChange={(e)=>setFilter(e.target.value)}/>
                  <label style={{ marginLeft: '5px', marginRight: '10px', verticalAlign: 'middle',position: 'relative' }}>Monthly</label>
                  <input type="radio" className="align-middle" name="graph" value='yearly' checked={filter === "yearly"} onChange={(e)=>setFilter(e.target.value)}/>
                  <label style={{ marginLeft: '5px', marginRight: '10px', verticalAlign: 'middle',position: 'relative' }}>Yearly</label>
                </div>

                <FontAwesomeIcon icon={faTimesCircle} style={{ color: '#3d4465' }} onClick={handleBack}/>
              </div>
  
              <GraphComponent graphtaken={filter}/>
            
          

             

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
