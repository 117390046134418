import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Index from "./components/index/Index";
import SignIn from "./components/SignIn/SignIn";
import CompanyReg from "./components/company/CompanyReg";
import CompanyReg2 from "./components/company/CompanyReg2";
import StaffReg from "./components/staff/StaffReg";

import CompanyPrivateRoutes from "./components/routes/CompanyPrivateRoutes";
import CompanyStaffPrivateRoutes from "./components/routes/CompanyStaffPrivateRoutes";
import CompanyHome from "./components/company/CompanyHome";
import CompanyProfile from "./components/company/CompanyProfile";
import EditCompanyProfile from "./components/company/EditCompanyProfile";
import EditStaffProfile from "./components/staff/EditStaffProfile";
import ItemList from "./components/company/Items/ItemList";
import AddItem from "./components/company/Items/AddItem";
import SingleItem from "./components/company/Items/SingleItem";
import UpdateItem from "./components/company/Items/UpdateItem";
import History from "./components/company/Items/History";

import CompanySalesReport from "./components/company/CompanySalesReport";
import CompanySalesGraph from "./components/company/CompanySalesGraph";

// import StockReport from "./components/company/Stock_report/Stock_report";

import Purchasereport from "./components/company/Purchasereport";
import PurchaseReportGraph from "./components/company/PurchaseReportgraph";

import Bill from "./components/company/bill/Bill";
import AddBill from "./components/company/bill/AddBill";
import Bill_Graph from "./components/company/bill/Bill_Graph";
import Bill_History from "./components/company/bill/Bill_History";
import ViewBill from "./components/company/bill/ViewBill";
import Edit_Bill from "./components/company/bill/Edit_Bill";

import Add_party from "./components/company/Parties/Add_party";
import View_party from "./components/company/Parties/View_party";
import Party_History from "./components/company/Parties/Party_History";
import Edit_party from "./components/company/Parties/Edit_party";
import Party_Default from "./components/company/Parties/Party_Default";

function App() {
  return (
    <>
      <ToastContainer/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />}></Route>
          <Route path="/login" element={<SignIn />}></Route>
          <Route path="/company_registration" element={<CompanyReg />}></Route>
          <Route path="/company_registration2" element={<CompanyReg2 />}></Route>
          <Route path="/staff_registration" element={<StaffReg />}></Route>

          <Route element={<CompanyPrivateRoutes />}>
            <Route path="/edit_company_profile" element={<EditCompanyProfile />}></Route>
          </Route>
          
          <Route element={<CompanyStaffPrivateRoutes />}>
            <Route path="/company_home" element={<CompanyHome />}></Route>
            <Route path="/company_profile" element={<CompanyProfile />}></Route>
            <Route path="/edit_staff_profile" element={<EditStaffProfile />}></Route>
            
            <Route path="/item-list" element={<ItemList/>} />
            <Route path="/add-item" element={<AddItem/>} />
            <Route path="/single-item/:id" element={<SingleItem/>} />
            <Route path="/update-item/:id" element={<UpdateItem/>} />
            <Route path="/history-item/:id" element={<History/>} />

            <Route path="/company_sales" element={<CompanySalesReport />}></Route>
            <Route path="/company_salesgraph" element={<CompanySalesGraph />}></Route>

            {/* <Route path="/stock_report" element={<StockReport/>} /> */}

            <Route path="/Purchasereport" element={<Purchasereport/>} />
            <Route path="/graph" element={< PurchaseReportGraph />} />

            <Route path="/bill" element={<Bill />}></Route>
            <Route path="/add_purchasebill" element={<AddBill />}></Route>
            <Route path="/purchasebill_graph" element={<Bill_Graph />}></Route>
            <Route path="/history_purchasebill/:billId" element={<Bill_History />}></Route>
            <Route path="/ViewBill/:billId" element={<ViewBill />}></Route> 
            <Route path="/edit_purchasebill/:billId" element={<Edit_Bill />}></Route> 

            <Route path="/add_party" element={<Add_party />}></Route>
            <Route path="/view_party/:partyId/" element={<View_party />}></Route>
            <Route path="/history_party/:partyId/" element={<Party_History />}></Route>
            <Route path="/edit_party/:partyId/" element={<Edit_party />}></Route>
            <Route path="/default_party" element={<Party_Default />}></Route>

            
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
