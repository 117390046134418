import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/AdminStyles.css";
import "./styles/Layout.css";
import config from "../../functions/config";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";

const Purchasereport = () => {
  const [purchaseReport, setPurchaseReport] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [panelWidth] = useState("100%");
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');  // Added state for search term
  const [notes, setNotes] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  // Fetch debit notes data from API
  useEffect(() => {
    const fetchPurchaseReport = async () => {
      const userId = Cookies.get('user_id');  // Assuming 'userId' is stored in the cookies
      
      if (!userId) {
        console.error("User ID not found in cookies");
        return;
      }

      try {
        const response = await axios.get(`${config.base_url}/api/purchase-report/`, {
          params: { userId: userId } 
        });
        setNotes(response.data);

        console.log(response.data);
        setPurchaseReport(response.data);
        setFilteredNotes(response.data); // Initially show all debit notes
      } catch (error) {
        console.error("Error fetching debit notes:", error);
      }
    };

    fetchPurchaseReport();
  }, []);

  useEffect(() => {
    let filtered = purchaseReport;
  
    // Apply fromDate filter
    if (fromDate) {
      filtered = filtered.filter(note => new Date(note.date) >= new Date(fromDate));
    }
  
    // Apply toDate filter
    if (toDate) {
      filtered = filtered.filter(note => new Date(note.date) <= new Date(toDate));
    }
  
    // Apply search term filter (case insensitive)
    if (searchTerm.trim()) {
      const lowerCaseSearch = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (note) =>
          note.number.toString().toLowerCase().includes(lowerCaseSearch) ||
          note.partyName.toLowerCase().includes(lowerCaseSearch) ||
          note.type.toLowerCase().includes(lowerCaseSearch) || // Filter by type
          note.totalAmount.toString().includes(lowerCaseSearch) // Filter by amount
      );
    }
  
    // Update filtered notes state
    setFilteredNotes(filtered);
  }, [fromDate, toDate, purchaseReport, searchTerm]); // Add all relevant dependencies
    
 

  const totalAmount = filteredNotes.reduce((total, note) => total + (note.totalAmount || 0) - (note.totalAmount1 || 0), 0);





  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");
  
    // Add CSS for hiding buttons on print
    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
      <style>
        body {
          display: flex;
          justify-content: center;
          height: 100vh;
          margin: 0;
        }
        .centered-content {
          text-align: center;
          width: 100%;
        }
         .table-bordered {
        border: 1px solid #ddd !important;
      }
      .table th, .table td {
        border: 1px solid #ddd !important;
        padding: 8px 12px;
        text-align: left;
      }
      .table th {
        background-color: #f8f9fa;
      }
      .table tbody tr:nth-child(even) {
        background-color: #f2f2f2;
      }
        /* Ensure the no-print class is respected in print view */
        @media print {
          .no-print {
            display: none !important;
          }
        }
      </style>
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  // Function to toggle the dropdown state
  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const [emailIds, setEmailIds] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);


    // Handle form submission
    // Handle form submission
const handleShareEmail = async (e) => {
  e.preventDefault(); // Prevent default form submission
  setIsLoading(true); // Indicates the process has started

  try {
    // Retrieve userId from localStorage
    const userId = Cookies.get('user_id');  // Assuming 'userId' is stored in the cookies
      
      if (!userId) {
        console.error("User ID not found in cookies");
        return;
      }

    // Axios POST request to the API endpoint
    const response = await axios.post(
      `http://127.0.0.1:8000/api/share-purchase-reports-to-email/?userId=${userId}`,
      {
        email_ids: emailIds, // List of email IDs to send the email
        email_message: emailMessage, // Message content for the email
      }
    );

    // Success response handling
    alert(response.data.message || "Email sent successfully!");

    // Clear the input fields after success
    setEmailIds("");
    setEmailMessage("");
  } catch (error) {
    console.error("Error sending email:", error); // Log the error for debugging

    // Show a user-friendly error message
    alert(
      error.response?.data?.error || "An error occurred while sending the email."
    );
  } finally {
    setIsLoading(false); // Reset the loading state
  }
};


  
  const exportToPDF = () => {
    const element = document.getElementById("reportTable");
    const options = {
      margin: [0.5, 0.5, 0.5, 0.5], // Adjusted for better spacing
      filename: "Purchase_Report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    
    if (element) {
      html2pdf().set(options).from(element).save();
    } else {
      console.error("Element with id 'reportTable' not found.");
    }
  };
  const exportToExcel = () => {
    const table = document.getElementById("reportTable");
    if (table) {
      const worksheet = XLSX.utils.table_to_sheet(table);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Purchase Report");
      XLSX.writeFile(workbook, "Purchase_Report.xlsx");
    } else {
      console.error("Element with id 'reportTable' not found.");
    }
  };
  
    return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel px-3 py-2" style={{ background: "#a9a9a961", width: panelWidth }}>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              {/* Date Range Selector */}
              <div
  className="row mb-4 align-items-center"
  style={{
    marginTop: "20px",
    backgroundColor: "#3d4465",
    height: "200px",
    width: "1200px", // Adjust the width as needed
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    borderRadius: "8px",
    marginLeft: "2px" // Corrected camelCase
  }}
  
  
>
  {/* Date Inputs */}
  <div className="col-md-3 col-12 mb-3 d-flex align-items-center">
    <label
      htmlFor="fromDate"
      className="text-white"
      style={{ width: "80px", marginTop: "-15px" }}
    >
      From Date
    </label>
    <input
      type="date"
      id="fromDate"
      name="fromDate"
      className="form-control"
      placeholder="From Date"
      style={{ width: "80%", marginTop: "35px" }}
      value={fromDate}
      onChange={(e) => setFromDate(e.target.value)}
    />
  </div>

  <div className="col-md-3 col-12 mb-3 d-flex align-items-center">
    <label
      htmlFor="toDate"
      className="text-white"
      style={{ width: "80px", marginTop: "-15px" }}
    >
      To Date
    </label>
    <input
      type="date"
      id="toDate"
      name="toDate"
      className="form-control"
      placeholder="To Date"
      style={{ width: "80%", marginTop: "35px" }}
      value={toDate}
      onChange={(e) => setToDate(e.target.value)}
    />
  </div>

  {/* Buttons */}
  {/* Buttons */}
<div className="col-md-6 d-flex justify-content-end flex-wrap gap-2">
<button className="btn btn-sm">
  <Link to="/graph" className="d-flex flex-column align-items-center">
    <img 
      src="https://multiuserindia.infoxtechnologies.com/static/images/graph.png" 
      alt="Graph" 
      style={{ width: '20px', marginBottom: '5px' }} 
    /> 
    <div style={{ color: 'white' }}>Graph</div>
  </Link>
</button>

  <button className="btn btn-sm " onClick={exportToExcel}>
    <img 
      src="https://multiuserindia.infoxtechnologies.com/static/images/excel2.png" 
      alt="Excel" 
      style={{ width: '20px', marginRight: '5px' }} 
    /> 
    <div style={{ color: 'white' }}>Export</div>
  </button>

  <button className="btn btn-sm " onClick={exportToPDF}>
    <img 
      src="https://multiuserindia.infoxtechnologies.com/static/images/PDF_icon.png" 
      alt="PDF" 
      style={{ width: '20px', marginRight: '5px' }} 
    /> 
    <div  style={{ color: 'white' }}>PDF</div>
  </button>

 {/* Share Button with Dropdown */}
<div className="dropdown">
  <button
    className="btn btn-sm"
    type="button"
    onClick={toggleDropdown}
    aria-expanded={isDropdownOpen ? 'true' : 'false'}
    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} // Flexbox to center and stack elements
  >
  
    <img 
      src="https://multiuserindia.infoxtechnologies.com/static/images/share.png" 
      alt="Share" 
      style={{ width: '20px', marginTop: '5px' }}  // Adjusted spacing between text and image
    />
      <span  style={{ color: 'white' }}>Share</span> {/* Text above the image */}
  </button>

  {isDropdownOpen && (
    <ul className="dropdown-menu show">
      <li>
        <a
          className="dropdown-item"
          href="https://wa.me/?text=Check%20this%20out"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-whatsapp"></i> WhatsApp
        </a>
      </li>
      <li>
        <a
          className="dropdown-item"
          data-toggle="modal"
          data-target="#shareToEmail"
          href="#"
          rel="noopener noreferrer"
        >
          <i className="fa fa-envelope"></i> Gmail
        </a>
      </li>
    </ul>
  )}
</div>

  <button className="btn btn-sm " onClick={printSection}>
    <img 
      src="https://multiuserindia.infoxtechnologies.com/static/images/printer.jpeg" 
      alt="Print" 
      style={{ width: '20px', marginRight: '5px' }} 
    /> 
    <div style={{ color: 'white' }}  >Print</div>
  </button>
</div>

</div>


              {/* Heading */}
              <div 
  id="printReport" 
  className="container-fluid d-flex flex-column justify-content-between align-items-start mb-4" 
  style={{ 
    marginTop: "20px", 
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
    padding: "20px", 
    borderRadius: "8px", 
    backgroundColor: "#3d4465", 
    width: "100%" // Adjust the width as needed
  }}
>
                <h4 className="text-white">Purchase Report</h4>
                <div className="d-flex justify-content-between w-100 mb-3 no-print">
  <input 
    type="text" 
    className="form-control" 
    placeholder="Search " 
    style={{ maxWidth: "300px" }} 
    value={searchTerm} 
    onChange={(e) => setSearchTerm(e.target.value)}  // Update search term as user types
  />
</div>

                {/* Table wrapped with overflow */}
                <div id="reportTable" className="table-responsive" style={{ maxHeight: "400px", backgroundColor: "white", padding: "15px", borderRadius: "8px" }}>
  <table className="table">
    <thead style={{ backgroundColor: "#3d4465", color: "white", position: "sticky" }}>
      <tr>
        <th>Serial No</th>
        <th>Date</th>
        <th>Number</th>
        <th>Party Name</th>
        <th>Type</th>
        <th>Total Amount</th>
      </tr>
    </thead>
    <tbody style={{ backgroundColor: "white" }}>
      {filteredNotes.map((note, index) => (
        <tr key={note.id}>
          <td>{index + 1}</td>
          <td>{note.date}</td>
          <td>{note.number}</td>
          <td>{note.partyName}</td>
          <td>{note.type}</td>
          <td>
                {note.totalAmount
                  ? note.totalAmount.toFixed(2)
                  : note.totalAmount1
                  ? note.totalAmount1.toFixed(2)
                  : "0.00"}
              </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
                <div className="total-amount" style={{ marginTop: "20px", backgroundColor: "#f8f9fa", padding: "15px", borderRadius: "8px", textAlign: "right" }}>
                  <strong>Total Amount: </strong> {totalAmount.toFixed(2)}
                </div>
              </div>
            </div>


            {/* <!-- Share To Email Modal --> */}
<div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#394d70" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purchasereport;
