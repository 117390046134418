import React, { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import axios from 'axios';
import BarGraph from './BarGraph';
import AreaGraph from './AreaGraph';
import config from "../../functions/config";

const GraphComponent = ( {graphtaken} ) => {
  const [invoice, setInvoice] = useState([]);
    

  const user = Cookies.get("role");
  var is_company = false;
  if (user === "Company") {
    is_company = true;
  }
  const ID = Cookies.get("user_id");

  const getInvoice = () => {
    axios
      .get(`${config.base_url}/salesreport/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const details = res.data.salesinvoice;

          setInvoice(details);
          console.log(details)
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
      });
  };

  useEffect(() => {
    getInvoice();

  }, []);
  console.log(invoice);

  // Step 1: Process data to get years and grand totals
  const processDatabar = (invoice) => {
    // Initialize an empty array for grand totals (12 months)
    const grandTotals1 = new Array(12).fill(0); // For 12 months (1 - 12)
  
    // Loop over the invoice items
    invoice.forEach(item => {
      const month = new Date(item.date).getMonth(); // Get month (0 - 11)
      
      // Add the grand total to the appropriate month (0-based index, so no need to subtract 1)
      grandTotals1[month] += item.grandtotal;
    });
  
    // Return the months from January (1) to December (12) and their corresponding grand totals
    return {
      months: ['January', 'February', 'March', 'April', 'May', 'June', 
               'July', 'August', 'September', 'October', 'November', 'December'],
      grandTotals1
    };
  };

  const processDataarea = (invoice) => {
    // Initialize an empty array for grand totals
    const grandTotals2 = new Array(5).fill(0); // For 2020 to 2024 (6 years)
    
    // Loop over the invoice items
    invoice.forEach(item => {
      const year = new Date(item.date).getFullYear();

      // Only consider years from 2020 to 2024
      if (year >= 2020 && year <= 2024) {
        const yearIndex = year - 2020; // Get the index for the year (e.g., 2020 -> index 0)
        grandTotals2[yearIndex] += item.grandtotal;
      }
    });

    // Return the years from 2020 to 2025 and their corresponding grand totals
    return {
      years: [2020, 2021, 2022, 2023, 2024],
      grandTotals2
    };
  };




  const { months, grandTotals1 } = processDatabar(invoice);
  const { years, grandTotals2 } = processDataarea(invoice);
  console.log(months)
  console.log(grandTotals1)
  console.log(years)
  console.log(grandTotals2)
  
  // Step 2: Prepare chart data
  const chartData1 = {
    labels: months,
    datasets: [
      {
        label: 'Monthly Sale',
        data: grandTotals1,
        backgroundColor: '#3d4465',  // Bar color
        borderColor: '#3d4465',      // Line color
        borderWidth: 1,
        fill: true,                   // Fill area for the area graph
        tension: 0.4,                 // Smoothing for the line graph
      }
    ]
  };

  const chartData2 = {
    labels: years,
    datasets: [
      {
        label: 'Yearly Sale',
        data: grandTotals2,
        backgroundColor: '#3d4465',  // Bar color
        borderColor: '#3d4465',      // Line color
        borderWidth: 1,
        fill: true,                   // Fill area for the area graph
        tension: 0.4,                 // Smoothing for the area graph
      }
    ]
  };

  // Chart.js options for responsiveness
  const chartOptions1 = {
    responsive: true,
    maintainAspectRatio: false,  // Make it fully responsive
    plugins: {
      
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    scales: {
      x: {
        
      },
      y: {
        
        ticks: {
          beginAtZero: true
        }
      }
    }
  };
  const chartOptions2 = {
    responsive: true,
    maintainAspectRatio: false,  // Make it fully responsive
    plugins: {
      
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    scales: {
      x: {
        
      },
      y: {
        
        ticks: {
          beginAtZero: true
        }
      }
    }
  };

  return (
    <div style={{ width: '100%', height: '350px', paddingTop:'20px' }}>

  
      {/* Bar Graph */}
      { graphtaken === 'monthly' && 
      <BarGraph chartData={chartData1} chartOptions={chartOptions1} />
      }

      {/* Area Graph (Line Graph) */}
      { graphtaken === 'yearly' && 
      <AreaGraph chartData={chartData2} chartOptions={chartOptions2} />
      }
    </div>
  );
};

export default GraphComponent;
