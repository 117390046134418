import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import config from "../../../functions/config";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ItemList.css";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import billingimage from './image/billingsystemimg.jpg'
import logo from './image/logo3.png'
import { Search } from "brace";


export default function ItemList() {
  const [isReduceStock, setIsReduceStock] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [itemInput, setItemInput] = useState("");
  const [count, setCount] = useState(0);
  const [reduceQuantity, setReduceQuantity] = useState(0);
  const [addQuantity, setAddQuantity] = useState(0);
  const [stockInHand, setStockInHand] = useState(0);
  const [date, setDate] = useState("");
  const [trans, setTrans] = useState([]);
  const [transInput, setTransInput] = useState("");
  var adjustedQuantity = 0;


  const ID = Cookies.get("user_id");

  const [loginName, setLoginName] = useState("");
  const [loginImage, setLoginImage] = useState("");

  const getLogDetails = () => {
    axios
      .get(`${config.base_url}/user/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          const details = res.data.data;
          var logImg = null;
          if (details.image) {
            logImg = `${config.base_url}${details.image}`;
          }
          setLoginImage(logImg);
          setLoginName(details.name);
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
      });
  };

  useEffect(() => {
    getLogDetails();
  }, []);


  const [currentTrans,setCurrentTrans] = useState([])
  
  //   transaction
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(`${config.base_url}trans`, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            search: transInput,
          },
        });
  
        if (res.data.status === 200) {
          console.log(res);
          setTrans(res.data.data.filter(item => item.user == ID));
  
          // Handling current transaction
          const filteredData = res.data.data.filter(item => item.user == ID);
          if (filteredData.length > 0) {
            setCurrentTrans(filteredData.filter(
              item => item.item == filteredData[filteredData.length - 1].item
            ));
          } else {
            setCurrentTrans([]);
          }
  
        } else {
          console.log(res);
          // Clear the data when no matching results are found
          setTrans([]);
          setCurrentTrans([]);
        }
      } catch (err) {
        console.log("error", err);
        setTrans([]);
        setCurrentTrans([]);
      }
    }
    fetchData();
  }, [config.base_url, count, transInput]);
  

  
  
  const handleSave = async (e, id) => {
    e.preventDefault();
    
    console.log(id);
    const data = {
      item_stock_in_hand: adjustedQuantity,
      item_date: date,
      is_reduce_stock: isReduceStock,
      add_quantity: addQuantity,
      reduce_quantity: reduceQuantity,
      done_by_name: ID,
    };
    try {
      const response = await axios.put(
        `${config.base_url}update_item/${id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data", 
          },
        }
      );
      if (response.data.status === 200) {
        setCount(count+1);
        setAddQuantity(0);
      setReduceQuantity(0);
        console.log("Item updated:", response.data);
        toast.success("Stock Updated", {
          autoClose: 1000,
          position: "top-right",
        });
      } else {
        console.error("Error:", response.data);
        toast.error("Fill the required fields", {
          autoClose: 1500,
          position: "top-right",
        });
      }
    } catch (err) {
      console.error("Error during update:", err);
      toast.error("Item could not be updated", {
        autoClose: 1000,
        position: "top-right",
      });
    }
  };

  const handleReduceQuantityChange = (e) => {
    setReduceQuantity(parseInt(e.target.value) || ""); // Ensure numeric input
  };

  const handleAddQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10) || ""; // Parse the input as an integer
    setAddQuantity(value);
  };

  useEffect(() => {
    async function fetchdata() {
      try {
        const response = await axios.get(`${config.base_url}all_items`, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            search_items: itemInput,
          },
        });
        if (response.data.status === 200) {
          console.log(response);
          setAllItems(response.data.data.filter(item=>item.user == ID));
          setStockInHand(response.data.data.item_stock_in_hand);
          setDate(response.data.data.item_date);
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchdata();
  }, [config.base_url, itemInput, count]);

  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("COMPANY NAME");

  const user = Cookies.get("role");
  var is_company = false;
  if (user === "Company") {
    is_company = true;
  }
 

  const [panelWidth, setPanelWidth] = useState(
    window.innerWidth > 768 ? "90%" : "100%"
  );

  useEffect(() => {
    const handleResize = () =>
      setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //   delete item
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${config.base_url}delete_items/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === 200) {
        setCount(id);
        console.log(response);
        toast.success("Item Deleted Successfully !", {
          autoClose: 1000,
          position: "top-right",
        });
      } else {
        console.log("error1");
        toast.error("Error", {
          autoClose: 2000,
          position: "top-right",
        });
      }
    } catch (err) {
      console.log("error2", err);
      toast.error("Error", {
        autoClose: 2000,
        position: "top-right",
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits for the month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits for the day
    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  };

//   pdf
const generatePDF = () => {
    const input = document.querySelector(".t"); // Select the table or div you want to export
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("Item Transactions.pdf");
    });
  };

//   print
const printTableContent = (elementId) => {
  const printContents = document.getElementById(elementId).innerHTML;
  const printWindow = window.open("", "_blank"); // Open a new window

  printWindow.document.write(`
    <html>
      <head>
        <title>Print Table</title>
        <style>
          /* Add custom styles for printing */
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: center;
          }
          th {
            background-color: #3a4561;
            color: white;
          }
        </style>
      </head>
      <body>
        ${printContents}
      </body>
    </html>
  `);
  printWindow.document.close();
  printWindow.print();
  printWindow.close();
};


// excel
const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      trans.map((itm, index) => ({
        "#": index + 1,
        DATE: formatDate(itm.trans_created_date),
        TYPE: itm.trans_type,
        "TYPE NUMBER": itm.trans_type_number || "N/A",
        QUANTITY: itm.trans_qty || 0,
        "BALANCE QTY": itm.trans_adjusted_qty,
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");
    XLSX.writeFile(wb, "Item Transactions.xlsx");
  };



  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />

        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961" }}
        >
          <div className="main-section ">
            {/* Items */}
            {allItems.length === 0 && itemInput==="" ? (
              <div className="no-items rounded-lg ">
                <div  className="image ">
                  <img  style={{height:'100%'}} src={billingimage} alt="" />
                </div>
                <div  className="create-item p-4">
                  <div className="text-center mt-3">
                    <img src={logo} alt="" />
                    <h1 style={{fontSize:'24px'}} className="fw-bold mt-3">MultiUser Billing Softaware</h1>
                    <p className="text-black ">Welcome to MultiUser Billing Software</p>
                    <p style={{color:'#00590A'}} className="fw-semibold">Please create your first item</p>
                    <Link style={{backgroundColor:'#3d4465'}} className="text-white btn" to={'/add-item'}>
                          Create Item
                     </Link>
                    </div>
                </div>
              </div>
            ) : (
              <div className="itm-main-1">
                <div className="itm-main p-5 rounded-top-3">
                  <h1 className="text-white">ITEMS</h1>
                  <div className="mt-4 d-flex  gap-3">
                    <input
                      type="text"
                      value={itemInput}
                      onChange={(e) => setItemInput(e.target.value)}
                      className=" p-2 rounded-start-2 "
                      placeholder="search"
                    />

                    <Link className="addbutton btn" to={"/add-item"}>
                      <i className="add-icon mdi mdi-plus  fw-bold menu-icon "></i>
                    </Link>
                  </div>
                </div>
                <div className=" rounded-bottom-3   bg-white">
                  <div className="bg-white t-bg rounded-bottom-3 p-4 d-flex flex-column">
                    <div
                      className="item-t"
                      style={{
                        flex: 1,
                        overflowY: "auto",
                      }}
                    >
                      <table className="table w-100 p-3 m-auto">
                        <thead className="text-center">
                          <tr className="text-center">
                            <th
                              style={{
                                backgroundColor: "#3a4561",
                                color: "white",
                              }}
                              scope="col"
                            >
                              ITEM
                            </th>
                            <th
                              style={{
                                backgroundColor: "#3a4561",
                                color: "white",
                              }}
                              scope="col"
                            >
                              QUANTITY
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-center p-3">
                          { allItems.map((itm) => {
                            return (
                              <tr key={itm.id}>
                                <td
                                  className="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate(`/single-item/${itm.id}`);
                                  }}
                                >
                                  {itm.item_name}
                                </td>
                                <td
                                  style={{ paddingLeft: "60px" }}
                                  className="menu-container text-center d-flex justify-content-between align-items-center"
                                >
                                  {itm.item_stock_in_hand}
                                  <div className="dropdown">
                                    <i
                                      className="fa-solid fa-ellipsis-vertical"
                                      style={{ cursor: "pointer" }}
                                      data-bs-toggle="dropdown"
                                    ></i>
                                    <ul className="dropdown-menu">
                                      <li
                                        onClick={() => {
                                          navigate(`/update-item/${itm.id}`);
                                        }}
                                      >
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa-solid text-black fw-semibold text-warning fa-edit"
                                        ></i>{" "}
                                        EDIT
                                      </li>

                                      <li
                                        onClick={() => {
                                          navigate(`/history-item/${itm.id}`);
                                        }}
                                      >
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa-solid text-black fw-semibold text-primary fa-history"
                                        ></i>{" "}
                                        HISTORY
                                      </li>

                                      <li
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDelete(itm.id);
                                        }}
                                      >
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa-solid text-black fw-semibold text-danger fa-trash"
                                        ></i>{" "}
                                        DELETE
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Recently added Item details */}

            <div className="d-flex flex-column">
              {allItems.length === 0 ? "" : (
                (() => {
                  const recentItem = allItems[allItems.length - 1];
                  adjustedQuantity = isReduceStock
                    ? recentItem.item_stock_in_hand - reduceQuantity
                    : recentItem.item_stock_in_hand + addQuantity;

                  return (
                    <div className="single-item">
                      <div className="item-details rounded-top-3">
                        <div className="d-flex flex-wrap justify-content-between">
                          <h3 className="text-white fw-bold">
                            {recentItem.item_name}
                            <i class="fa-solid ms-2 fa-share"></i>
                          </h3>
                          
                          <button
                          style={{color:'#3d4465'}}
                            type="submit"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            className="addbutton btn"
                          >
                            <i className="icon fa-solid fa-sliders"></i> <span className="icon">ADJUSTMENT ITEM</span>
                            
                          </button>
                          {/* modal */}
                          <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div
                                  style={{ backgroundColor: "#3d4465" }}
                                  className="modal-header"
                                >
                                  <h1
                                    className="modal-title text-white fw-bold fs-5"
                                    id="exampleModalLabel"
                                  >
                                    STOCK ADJUSTMENT
                                  </h1>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <div className="d-flex modal-body-1 justify-content-between">
                                    <div>
                                      <p className="fw-semibold text-dark fs-6">
                                        Item Name
                                      </p>
                                      <p
                                        style={{
                                          color: "#3d4465",
                                          marginTop: "-5px",
                                        }}
                                        className="fw-bold px-2 fs-3"
                                      >
                                        {recentItem.item_name}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="fw-semibold text-dark fs-6">
                                        Current Quantity
                                      </p>
                                      <input
                                        style={{
                                          width: "110px",
                                          marginTop: "-5px",
                                        }}
                                        type="number"
                                        className="form-control px-2 text-black"
                                        value={recentItem.item_stock_in_hand}
                                        readOnly
                                      />
                                    </div>
                                  </div>

                                  <div className="modal-body-2 px-3">
                                    <div className="d-flex gap-3">
                                      <p
                                        className={`text-primary fs-5 ${
                                          !isReduceStock ? "fw-bold fs-4" : ""
                                        }`}
                                        onClick={() => {
                                          setIsReduceStock(false);
                                          setReduceQuantity(0);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Add Stock
                                      </p>
                                      <p
                                        className={`text-primary fs-5 ${
                                          isReduceStock ? "fw-bold fs-4" : ""
                                        }`}
                                        onClick={() => {
                                          setIsReduceStock(true);
                                          setAddQuantity(0);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Reduce Stock
                                      </p>
                                    </div>
                                    <div className="d-flex gap-3">
                                      <div>
                                        <p className="text-center fw-semibold text-dark">
                                          Date
                                        </p>
                                        <input
                                          type="date"
                                          value={recentItem.item_date}
                                          onChange={(e) =>
                                            setDate(e.target.value)
                                          }
                                          className="rounded-lg px-3 border border-secondary-emphasis"
                                        />
                                      </div>
                                      <div>
                                        <p className="text-center fw-semibold text-dark">
                                          {isReduceStock
                                            ? "Reduce Quantity"
                                            : "Add Quantity"}
                                        </p>
                                        {!isReduceStock ? (
                                          <input
                                            type="number"
                                            name="add quantity"
                                            value={addQuantity}
                                            onChange={handleAddQuantityChange}
                                            className="rounded-lg px-3 border border-secondary-emphasis"
                                          />
                                        ) : (
                                          <input
                                            type="number"
                                            name="reduce quantity"
                                            value={reduceQuantity}
                                            onChange={
                                              handleReduceQuantityChange
                                            }
                                            className="rounded-lg px-3 border border-secondary-emphasis"
                                          />
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-center fw-semibold text-dark">
                                          Adjust Quantity
                                        </p>
                                        <input
                                          type="number"
                                          value={adjustedQuantity}
                                          onChange={(e) =>
                                            setStockInHand(e.target.value)
                                          }
                                          className="rounded-lg px-3 border border-secondary-emphasis"
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    style={{ backgroundColor: "#3d4465" }}
                                    type="button"
                                    className="btn text-white "
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    data-bs-dismiss="modal"
                                    style={{ backgroundColor: "#3d4465" }}
                                    onClick={(e) =>
                                      handleSave(e, recentItem.id)
                                    }
                                    type="button"
                                    className="btn  text-white "
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sale-details d-flex rounded-bottom-3 justify-content-between bg-white">
                        <div>
                          <h5>Sale Price: ₹{recentItem.item_sale_price}</h5>
                          <h5>
                            Stock Quantity: {recentItem.item_stock_in_hand}
                          </h5>
                        </div>
                        <div>
                          <h5>
                            Purchase Price: ₹{recentItem.item_purchase_price}
                          </h5>
                          <h5>
                            Stock Value: ₹
                            {recentItem.item_purchase_price *
                              recentItem.item_at_price}
                          </h5>
                        </div>
                      </div>
                    </div>
                  );
                })()
              )}

              {/* transaction */}

              {allItems.length === 0 && transInput===""  ? "" : (
                <div>
                  <div className="item-details-t rounded-top-3">
                    <h3 className="text-white mb-4 fw-bold">TRANSACTIONS</h3>
                    <div className="d-flex flex-wrap-reverse gap-2 justify-content-between">
                      <div className="tr-input w-50">
                        <input
                          type="text"
                          value={transInput}
                          onChange={(e) => setTransInput(e.target.value)}
                          placeholder="Search"
                          className="px-3  rounded-lg "
                        />
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <button style={{color:'#3d4465'}} className="addbutton btn" onClick={generatePDF}>
                          <span className="icon">PDF</span>  <i  className="icon fa-solid fa-file-pdf "></i>
                        </button>
                        <button
                          style={{ color: "#3d4465" }}
                          className="addbutton btn"
                          onClick={() => printTableContent("transactions-table")}
                        >
                          <span className="icon">PRINT</span> <i className="icon fa-solid fa-print"></i>
                        </button>
                        <button style={{color:'#3d4465'}} className="addbutton btn" onClick={exportToExcel}>
                          <span className="icon">EXCEL</span> <i className="icon fa-solid fa-file-excel "></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className=" rounded-bottom-3   bg-white">
                  <div id="transactions-table" className="bg-white rounded-bottom-3 p-5">
  <div style={{overflowX:'auto'}} className="t">
    
      <table className="table m-auto">
        <thead className="text-center ">
          <tr style={{fontSize:'15px'}}  className="">
            <th
              style={{
                backgroundColor: "#3a4561",
                color: "white",
              }}
              scope="col"
            >
              #
            </th>
            <th
              style={{
                backgroundColor: "#3a4561",
                color: "white",
              }}
              scope="col"
            >
              DATE
            </th>
            <th
              style={{
                backgroundColor: "#3a4561",
                color: "white",
              }}
              scope="col"
            >
              TYPE
            </th>
            <th
              style={{
                backgroundColor: "#3a4561",
                color: "white",
              }}
              scope="col"
            >
              TYPE NUMBER
            </th>
            <th
              style={{
                backgroundColor: "#3a4561",
                color: "white",
              }}
              scope="col"
            >
              QUANTITY
            </th>
            <th
              style={{
                backgroundColor: "#3a4561",
                color: "white",
              }}
              scope="col"
            >
              BALANCE QTY
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
    {currentTrans.map((itm, index) => (
      <tr key={itm.id}>
        <th scope="row">{index + 1}</th>
        <td>{formatDate(itm.trans_created_date)}</td>
        <td>{itm.trans_type}</td>
        <td>{itm.trans_type_number || "N/A"}</td>
        <td>{itm.trans_qty || 0}</td>
        <td>{itm.trans_adjusted_qty}</td>
      </tr>
    ))}
  </tbody>

      </table>
  </div>
</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
