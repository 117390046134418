import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import config from "../../../functions/config";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ItemList.css";
import { toast } from "react-toastify";

export default function History() {


 const [history,setHistory] = useState([])
const {id} = useParams()
  const [companyName, setCompanyName] = useState("COMPANY NAME");

  const user = Cookies.get("role");
  var is_company = false;
  if (user === "Company") {
    is_company = true;
  }
  const ID = Cookies.get("user_id");

  const [loginName, setLoginName] = useState("");
  const [loginImage, setLoginImage] = useState("");

  const getLogDetails = () => {
    axios
      .get(`${config.base_url}/user/${ID}/`)
      .then((res) => {
        if (res.data.status) {
            console.log(res)
          const details = res.data.data;
          var logImg = null;
          if (details.image) {
            logImg = `${config.base_url}${details.image}`;
          }
          setLoginImage(logImg);
          setLoginName(details.username);
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
      });
  };

  useEffect(() => {
    getLogDetails();
  }, []);

  const [panelWidth, setPanelWidth] = useState(
    window.innerWidth > 768 ? "90%" : "100%"
  );

  useEffect(() => {
    const handleResize = () =>
      setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



//   single History


useEffect(()=>{
    async function fetchdata(){
      try{
        const res = await axios.get(`${config.base_url}tran_history/${id}`,{
          headers:{
            'Content-Type':'application/json',
          }, 
        });
        if(res.data.status===200){
          
          console.log(res)
          setHistory(res.data.data)
        } 
        else{
          console.log(res)
        }
        
      } catch (err) {
        console.log("error2",err)
      }
    }
    fetchdata();
  },[config.base_url,id])


  

  return (
    <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper d-flex">
      <Sidebar />

      <div
        className="main-panel px-3 py-2"
        style={{ background: "#a9a9a961" }}
      >
          <div>
                <div className="item-details-t-h rounded-top-3">
                <div className='rounded  d-flex justify-content-between'>
                <h2 className='text-white'>HISTORY</h2>
                <Link to='/item-list'><button style={{color:'#3d4465'}} className='btn fw-bold btn-light'><i className='fa-solid fa-beat fa-arrow-left'></i> Back</button></Link>
           </div>
                </div>
                <div id="transactions-table" className=" rounded-bottom-3   bg-white">
                  <div className="t-bg bg-white rounded-bottom-3  p-5 ">
                    <div style={{overflowX:'auto'}} className="t ">
                        <table className="table w-100  p-3  m-auto">
                          <thead className="text-center ">
                            <tr className="">
                              <th
                                style={{
                                  backgroundColor: "#3a4561",
                                  color: "white",
                                }}
                                scope="col"
                              >
                                ACTION
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#3a4561",
                                  color: "white",
                                }}
                                scope="col"
                              >
                                DATE
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#3a4561",
                                  color: "white",
                                }}
                                scope="col"
                              >
                                DONE BY
                              </th>
                              
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {history.map((itm)=>{
                                return(
                                    <tr key={itm.id}>
                                    <td>{itm.action}</td>
                                    <td>{itm.date}</td>
                                    <td>{itm.done_by_name}</td>
                                  </tr>
                                )
                            })
                               }
                          </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
}